import { useState, useEffect } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';

export default function useToken() {
  const [token, setToken] = useState(null);

  useEffect(() => {

    fetchToken();
  }, []);

  const fetchToken = async () => {
      
    const storedToken = localStorage.getItem('token');
    const expirationTime = localStorage.getItem('tokenExpiration');

    if (storedToken && new Date().getTime() < expirationTime) {
      setToken(storedToken);
    } else {
      
      const session = await fetchAuthSession();
      const accessToken = session.tokens?.accessToken?.toString();
      const expiresIn = session.tokens?.accessToken?.payload?.exp * 1000;

      localStorage.setItem('token', accessToken);
      localStorage.setItem('tokenExpiration', expiresIn);

      setToken(accessToken);
    }
  };
  
  const saveToken = userToken => {
    localStorage.setItem('token', userToken);
    setToken(userToken);
  };

  return { token, setToken: saveToken };
}
