import React, { useState, useRef, useEffect, useContext } from "react";
import { baseUrl } from '../../config.js'
import { Link, NavLink, Outlet, useLocation } from 'react-router-dom';

import './NavBarCuenta.css';

import useToken from "../../useToken.js";

import Flecha from '../../img/Flecha.png';
import defaultAlmacen from '../../img/defaultAlmacen.png';
import Finanzas from '../../img/Finanzas.png';

const NavBar = ({})=>{

    const {token} = useToken();
    const location = useLocation();
    const [subMenu, setSubMenu] = useState({});
    
    useEffect(()=>{
        console.log(location.pathname)
        if(location.pathname == '/host' || location.pathname == '/host/' || location.pathname == '/host/dashboard' || location.pathname == '/host/dashboard/'){
            setSubMenu({...subMenu, rentas: true})
        }
        else if(location.pathname.split("/")[3] == "rentas"){
            setSubMenu({...subMenu, rentas: true})
        }
        else if(location.pathname.split("/")[3] == "finanzas"){
            setSubMenu({...subMenu, finanzas: true})
        }
    }, [location])

    return(
        <div className="dashCuenta">
            <nav>
                <div>
                    <button onClick={()=>{
                        setSubMenu({...subMenu, rentas: !subMenu?.rentas})
                    }}>
                        <div className={"iconoCuenta"+(location.pathname.split("/")[2] == "rentas" ? ' active' : '')}>
                            <img src={defaultAlmacen}/>
                        </div>
                        <div>Rentas</div>
                        <img src={Flecha} className={"flecha"+(subMenu?.rentas ? " arriba" : " abajo")}/>
                    </button>
                    {subMenu?.rentas &&
                        <div className="sub">
                            <NavLink to='/host/dashboard/rentas/espacios' className={location.pathname == '/host' || location.pathname == '/host/' || location.pathname == '/host/dashboard' || location.pathname == '/host/dashboard/' ? 'active' : ''}>
                                <div className="linea"></div>
                                <div>Espacios</div>
                            </NavLink>
                            <NavLink to='/host/dashboard/rentas/reservaciones'>
                                <div className="linea"></div>
                                <div>Reservaciones</div>
                            </NavLink>
                            <NavLink to='/host/dashboard/rentas/almacenes'>
                                <div className="linea"></div>
                                <div>Almacenes</div>
                            </NavLink>
                            <NavLink to='/host/dashboard/rentas/publicaciones'>
                                <div className="linea"></div>
                                <div>Publicaciones</div>
                            </NavLink>
                            <NavLink to='/host/dashboard/rentas/ubicaciones'>
                                <div className="linea"></div>
                                <div>Ubicaciones</div>
                            </NavLink>
                        </div>
                    }
                    <button onClick={()=>{
                        setSubMenu({...subMenu, finanzas: !subMenu?.finanzas})
                    }}>
                        <div className={"iconoCuenta"+(location.pathname.split("/")[2] == "finanzas" ? ' active' : '')}>
                            <img src={Finanzas}/>
                        </div>
                        <div>Finanzas</div>
                        <img src={Flecha} className={"flecha"+(subMenu?.finanzas ? " arriba" : " abajo")}/>
                    </button>
                    {subMenu?.finanzas &&
                        <div className="sub">
                        <NavLink to='/host/dashboard/finanzas/pagos'>
                            <div className="linea"></div>
                            <div>Pagos</div>
                        </NavLink>
                        <NavLink to='/host/dashboard/finanzas/disputas'>
                            <div className="linea"></div>
                            <div>Disputas</div>
                        </NavLink>
                        <NavLink to='/host/dashboard/finanzas/transferencias'>
                            <div className="linea"></div>
                            <div>Transferencias</div>
                        </NavLink>
                    </div>
                    }
                    <NavLink to='/host/dashboard/metricas'>
                        <div className="iconoCuenta">
                            <img src={defaultAlmacen}/>
                        </div>
                        <div>Metricas</div>
                    </NavLink>
                    <NavLink to='/host/dashboard/radar'>
                        <div className="iconoCuenta">
                            <img src={defaultAlmacen}/>
                        </div>
                        <div>Radar</div>
                    </NavLink>
                    <button onClick={()=>{
                        setSubMenu({...subMenu, configuracion: !subMenu?.configuracion})
                    }}>
                        <div className={"iconoCuenta"+(location.pathname.split("/")[2] == "configuracion" ? ' active' : '')}>
                            <img src={defaultAlmacen}/>
                        </div>
                        <div>Configuración</div>
                        <img src={Flecha} className={"flecha"+(subMenu?.configuracion ? " arriba" : " abajo")}/>
                    </button>
                    {subMenu?.configuracion &&
                        <div className="sub">
                            <NavLink to='/host/dashboard/configuracion/misdatos'>
                                <div className="linea"></div>
                                <div>Mis datos</div>
                            </NavLink>
                        </div>
                    }
                </div>
            </nav>
            <Outlet/>
        </div>
    )
}

export default NavBar;