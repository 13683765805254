import React, { useEffect, useState, useRef, useContext } from "react";
import { baseUrl } from '../../config.js'
import { BuscadorContext } from "../Buscador/BuscadorContext.js";
import { Imagenes, nombres, idsRC, idServiciosRC } from '../../almacen.js';

import flecha from '../../img/Flecha.png';

import Favoritos from '../../img/Favoritos.png';
import Popular from '../../img/temp/Popular.png';
import Congelador from '../../img/temp/Congelador.png';
import Puertos from '../../img/temp/Puertos.png';
import Filtros from '../../img/Filtros.png';
import RangoPrecios from '../../img/Precio.png';
import Cerrar from '../../img/Cerrar.png';

import PriceUp from '../../img/PriceUp.png';
import PriceDown from '../../img/PriceDown.png';
import EspacioUp from '../../img/EspacioUp.png';
import EspacioDown from '../../img/EspacioDown.png';
import TimeUp from '../../img/TimeUp.png';
import TimeDown from '../../img/TimeDown.png';
import Checked from '../../img/Checked.png'

import './Etiquetas.css';

const Etiquetas = ({ setModalFiltro, impuestos, setImpuestos })=>{

    const {filtros, totalFavs, setTotalFavs} = useContext(BuscadorContext);
    const [totalFiltros, setTotalFiltros] = useState(0);
    const [etiquetas, setEtiquetas] = useState(Array(20).fill({}));
    const [etiqueta, setEtiqueta] = useState('');
    const [scroll, setScroll] = useState({x1:0, x2:0});
    const [selected, setSelected] = useState(0);
    const order = {
        "Best" : {
            "nombre" : "Mejores",
            "imagen" : Popular
        },
        "Recent" : {
            "nombre" : "Más Recientes",
            "imagen" : TimeDown,
        },
        "Oldest" : {
            "nombre" : "Más Antiguos",
            "imagen" : TimeUp,
        },
        "MoreAvailability" : {
            "nombre" : "Más Espacios",
            "imagen" : EspacioDown,
        },
        "LessAvailability" : {
            "nombre" : "Menos Espacios",
            "imagen" : EspacioUp,
        },
        "PriceDown" : {
            "nombre" : "Mayor Costo",
            "imagen" : PriceDown,
        },
        "PriceUp" : {
            "nombre" : "Menor Costo",
            "imagen" : PriceUp,
        },
    };

    const divEtiquetas = useRef();
    const wrapperEtiquetas = useRef();
    
    useEffect(()=>{
        setTimeout(()=>{
            setEtiquetas([
                {
                    nombre:"Congelador",
                    imagen: Congelador,
                },
                {
                    nombre:"Cercanos a Puertos",
                    imagen: Puertos,
                }
            ])
        }, 1500)
    }, [])
    useEffect(()=>{
        const total = (filtros?.services?.length + filtros?.types?.length) || 0;
        setTotalFiltros(total);
    },[filtros])
    
    useEffect(()=>{
        const x2 = divEtiquetas.current.scrollWidth - (divEtiquetas.current.offsetWidth + divEtiquetas.current.scrollLeft);
        setScroll({...scroll, x2: x2});
    },[etiquetas])

    useEffect(()=>{
        const handleScroll = event => {
            const x1 = divEtiquetas.current.scrollLeft;
            const x2 = divEtiquetas.current.scrollWidth - (divEtiquetas.current.offsetWidth + divEtiquetas.current.scrollLeft);
            setScroll({x1, x2});
        };
    
        divEtiquetas.current.addEventListener('scroll', handleScroll);
    
        return () => {
            if(divEtiquetas.current)
                divEtiquetas.current.removeEventListener('scroll', handleScroll);
        };
    }, [])

    useEffect(()=>{
        if(scroll.x1 > 0)
            wrapperEtiquetas.current.classList.add('izq')
        else
            wrapperEtiquetas.current.classList.remove('izq')
            if(scroll.x2 > 0)
                wrapperEtiquetas.current.classList.add('der')
            else
                wrapperEtiquetas.current.classList.remove('der')
    }, [scroll])

    const mover = (dir)=>{
        const pixeles = Math.ceil(divEtiquetas.current.scrollLeft+(dir*divEtiquetas.current.offsetWidth*.7));
        divEtiquetas.current.scroll(pixeles,0);
    }

    return(
        <div className="filtrar">
            <div className="wrapperEtiquetas" ref={wrapperEtiquetas}>
                <div className="mover left" onClick={()=>{
                        mover(-1)
                    }}>
                    <span>
                        <img src={flecha} alt="Deslizar Izquierda"/>
                    </span>
                </div>
                <div className="mover right" onClick={()=>{
                    mover(1)
                }}>
                    <span>
                        <img src={flecha} alt="Deslizar Derecha"/>
                    </span>
                </div>
                <div className="etiquetas" ref={divEtiquetas}>
                    {filtros["order"] &&
                        <Etiqueta datos={order[filtros["order"]]} selected={true} type={"order"}/>
                    }
                    {filtros["min"] !== undefined &&
                        <Etiqueta datos={{nombre: "$"+filtros["min"]+" - $"+filtros["max"], imagen: RangoPrecios}} selected={true} type={"price"}/>
                    }
                    {filtros["types"] &&
                        filtros.types.map(x=>{
                            return(
                                <Etiqueta datos={{id: x, nombre: nombres[x], imagen: Imagenes[idsRC[x]]}} selected={true} type={"types"}/>
                            )
                        })
                    }
                    {filtros["services"] &&
                        filtros.services.map(x=>{
                            return(
                                <Etiqueta datos={{id: x, nombre: idServiciosRC[x], imagen: Imagenes[idServiciosRC[x]]}} selected={true} type={"services"}/>
                            )
                        })
                    }
                    <Etiqueta datos={{nombre:"Favoritos"+(totalFavs != 0 ? " ("+totalFavs+")" : ""),imagen: Favoritos,type: "fav",}} selected={filtros?.fav} type={"fav"}/>
                    {etiquetas.map((etiqueta, i)=>{
                        return(
                            <Etiqueta datos={etiqueta} key={i} selected={false} type={"fav"}/>
                            )
                        })
                    }
                </div>
            </div>
            <div className="filtros">
                <button 
                    className={totalFiltros ? "conFiltros" : ""}
                    onClick={()=>{
                        setModalFiltro(true);
                    }}>
                    {totalFiltros!==0 &&
                        <div className="totalFiltros">{totalFiltros}</div>
                    }
                    <img src={Filtros}/>
                    <span>Filtros</span>
                </button>
                <label className="switch">
                    <span>Mostrar total sin impuestos</span>
                    <input type="checkbox" checked={impuestos} onChange={(e)=>{
                        setImpuestos(e.target.checked)
                    }}/>
                    <span>
                        <span className="bola">
                            <img src={Checked}/>
                        </span>
                    </span>
                </label>
            </div>
        </div>
    )
}

const Etiqueta = ({datos, selected, setSelected, i, type})=>{
    const {filtros, setFiltros} = useContext(BuscadorContext);
    return (
        <div className={(!datos?.nombre ? 'placeholder ' : '')+(selected ? "sel" : "")}
            onClick={()=>{
                if(type == "fav"){
                    setFiltros({...filtros, fav:true})
                }
            }}>
            {selected && 
                <button className="cerrar" onClick={(e)=>{
                    e.preventDefault();
                    e.stopPropagation();
                    if(type == "order"){
                        setFiltros({...filtros, order : undefined})
                    }
                    else if(type == "price"){
                        setFiltros({...filtros, min: undefined, max: undefined, time: undefined})
                    }
                    else if(type == "services"){
                        const services = filtros.services.filter(x=>{
                            console.log(x, datos.id)
                            return x !== datos.id;
                        })
                        setFiltros({...filtros, services})
                    }
                    else if(type == "types"){
                        const types = filtros.types.filter(x=>{
                            console.log(x, datos.id)
                            return x !== datos.id;
                        })
                        setFiltros({...filtros, types})
                    }
                    else if(type == "fav"){
                        setFiltros({...filtros, fav:undefined})
                    }
                }}>
                    <img src={Cerrar}/>
                </button>
            }
            <div className="imgEtiqueta">
                <img src={datos?.imagen}/>
            </div>
            <div className="nombreEtiqueta">{datos?.nombre}</div>
        </div>
    )
}

export default Etiquetas;