import React, { useState, useRef, useEffect, useContext } from "react";
import { baseUrl } from '../../config.js'
import { Chart } from "react-google-charts";

import './Dashboards.css';

import useToken from "../../useToken.js";
import { formatoFecha, formatoMoneda, formatoHora, Estados } from '../../almacen.js';
import { Link } from "react-router-dom";

const DashAlmacenes = ({})=>{

    const {token} = useToken();
    const [data, setData] = useState([]);
    const [dataAlmacenes, setDataAlmacenes] = useState([]);
    const [stores, setStores] = useState({});

    useEffect(()=>{
        if(token){
            getData();
        }
    }, [token])
    
    const getData = ()=>{
        fetch(baseUrl+'Info/Stores',{
            headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
        })
        .then(response=>response.json())
		.then(json=>{
            console.log(json);
            setStores({spaces : json?.data?.spaces})
		})
		.catch(error=>{
			console.error(error);
		})
    }
    useEffect(()=>{
        if(token){
            getLocations();
        }
    },[token])

    const getLocations = ()=>{
        fetch(baseUrl+'Info/LocationBookings',{
            method: 'post',
            headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
        })
        .then(response=>response.json())
		.then(json=>{
			console.log(json);
            formatData(json?.data);
            setDataAlmacenes(json?.data)
		})
		.catch(error=>{
			console.error(error);
		})
    }

    const formatData = (estados)=>{
        console.log(estados);
        const aux = [['States', 'Estado', 'Espacios']]
        estados.forEach(estado=>{
            console.log(estado.id, Estados)
            aux.push([Estados[estado.id].codigo, Estados[estado.id].estado, estado.bookedSpaces*1])
        })
        console.log(aux);
        setData(aux);
    }
    

    return(
        <div className="dashEspacios">
            <div className="movimientos contUbica">
                <div className="etiqueta">
                    <GraficaUbicacion data={data}/>
                </div>
            </div>
            <div className="movimientos">
                <div className="etiqueta">
                    <div>
                        <h3>Almacenes por Ubicación</h3>
                        <Movimientos data={dataAlmacenes}/>
                    </div>
                </div>
            </div>

        </div>
    )
}

const Movimientos = ({data})=>{
    
    return(
        <table className="tabla recientes">
        <tbody>
            {data.length !== 0 &&
                <tr>
                    <th>Estado</th>
                    <th>Almacén</th>
                    <th>Ocupados</th>
                </tr>
            }
            {data.map((fila, i)=>{
                return(
                    <tr key={i}>
                        <td>{Estados[fila.id].estado}</td>
                        <td>
                            <Link to={'/host/almacenes/'+fila.idStore}>{fila.name}</Link>
                        </td>
                        <td>{fila.bookedSpaces}</td>
                    </tr>
                )
            })
            }
        </tbody>
        </table>
    )
}

const GraficaUbicacion = ({data})=>{
    const options = {
        region: "MX", // Africa
        displayMode: "regions",
        resolution: "provinces",
        colors: ['#c83d3d', '#FFF000', '#20b320']
    };
    return(
        <div>
            <h4>Ubicaciones con espacios ocupados</h4>
            <Chart
                chartEvents={[
                    {
                    eventName: "select",
                    callback: ({ chartWrapper }) => {
                        const chart = chartWrapper.getChart();
                        const selection = chart.getSelection();
                        if (selection.length === 0) return;
                        const region = data[selection[0].row + 1];
                        console.log("Selected : " + region[0]);
                    },
                    },
                ]}
                chartType="GeoChart"
                width="100%"
                height="100%"
                data={data}
                options={options}
                />
        </div>
    )
}

export default DashAlmacenes;