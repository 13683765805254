import React from "react";
import { baseUrl } from '../../config.js'
import { Link } from "react-router-dom";
import './Unirte.css';

const Unirte = ({user, setModalLogin})=>{
    const year = (new Date()).getFullYear();
    return(
        <div className="unirte">
            <h1>¡Conviértete en Anfitrión!</h1>
            <div className="descUnirte">¿Tienes un almacén que no estás utilizando? ¡Haz que trabaje para ti! Únete a nuestra comunidad de anfitriones y comienza a ganar dinero alquilando tu espacio. Es fácil comenzar y te apoyamos en cada paso del camino. ¡Haz clic aquí para comenzar tu viaje como anfitrión hoy!</div>
            <div>
                {!user?.sub &&
                    <button className="btn" onClick={()=>{
                        setModalLogin(true)
                    }}>Quiero Unirme</button>
                }
                {user?.sub &&
                    <Link to='/host' className="btn">Quiero Unirme</Link>
                }
            </div>
        </div>
    )
}

export default Unirte;