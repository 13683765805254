import React, { useState, useRef, useEffect, useContext } from "react";
import { baseUrl } from '../../config.js'
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { signOut } from '@aws-amplify/auth';

import Buscador from '../Buscador/Buscador.js';
import Login from '../Login/Login.js';
import Modal from "../Modal/Modal.js";

import './NavBar.css';
import Burger from '../../img/Burger.png';
import Perfil from '../../img/Perfil.png';
import Mundo from '../../img/Mundo.png';
import Logo from '../../img/logoV2.png';
import LogoMobile from '../../img/logo.png';
import Filtros from '../../img/Filtros.png';
import Buscar from '../../img/Buscar.png';
import Flecha from '../../img/Flecha.png';

import {config} from '../../aws-exports.js';
import useToken from "../../useToken.js";
import { BuscadorContext } from "../Buscador/BuscadorContext.js";
Amplify.configure(config);

const NavBar = ({user, setUser, modalLogin, setModalLogin, modalFiltro, setModalFiltro, config, setConfig})=>{
    const {token, setToken} = useToken();
    const [showNavbar, setShowNavbar] = useState(true);
    const {filtros, setFiltros, totalFavs, setTotalFavs} = useContext(BuscadorContext);
    const [totalFiltros, setTotalFiltros] = useState(0);
    const [modalBuscador, setModalBuscador] = useState(false);
    const [modalMundo, setModalMundo] = useState(false);
    const location = useLocation();
    const [menu, setMenu] = useState(false);
    const [focused, setFocused] = useState(false);
    const [mas, setMas] = useState(false);

    const menuNav = useRef(null);
    const masNav = useRef(null);

    useEffect(()=>{
        document.querySelector(".nav")?.classList?.add("min");
        window.scroll({top:0})
    },[location])

    useEffect(() => {
        setMenu(false);
        if (location.pathname.split("/")[1] == "host") {
            setShowNavbar(false);
        }
        else {
          setShowNavbar(true);
        }
    }, [location]);

    useEffect(()=>{
        setTotalFavs(user?.favs || 0);
    }, [user])

    useEffect(()=>{
        if(focused)
            document.querySelector(".nav").classList.add("focused");
        else
            document.querySelector(".nav").classList.remove("focused");
        return ()=>{
            document.querySelector(".nav").classList.remove("focused");
        }
    }, [focused])

    useEffect(()=>{
        if(menu || mas)
            document.body.onclick = handleMenu;
        else
            document.body.onclick = undefined;
    }, [menu, mas])

    useEffect(()=>{
        const total = (filtros?.services?.length + filtros?.types?.length) || 0;
        setTotalFiltros(total);
        setModalBuscador(false);
    },[filtros])

    const handleMenu = (e)=>{
        if(menuNav.current && !menuNav.current.contains(e.target))
            setMenu(false);
        if(masNav.current && !masNav.current.contains(e.target))
            setMas(false);
    }
    const eliminarDatos = ()=>{
        if(window.confirm("Esto eliminará al usuario y todos sus datos, ¿estás seguro?")){
            fetch(baseUrl+"eliminarUsuario", {
                headers:{
                    'Authorization': `Bearer ${token}`,
                }
            })
            .then(response=>response.json())
            .then(json=>{
                setUser({});
                signOut({global:true})
            })
        }
    }
    if(showNavbar)
        return(
            <>
                {modalLogin &&
                
                    <Modal className='divLogin' titulo={'Inicia sesión o Regístrate'} setModal={setModalLogin}>
                        <Login setModalLogin={setModalLogin} user={user} setUser={setUser}/>
                    </Modal>
                }
                {modalMundo &&
                    <Modal className='modalMundo' titulo={'Opciones'} setModal={setModalMundo}>
                        <Configuracion setModalMundo={setModalMundo} config={config} setConfig={setConfig}/>
                    </Modal>
                }
                <nav className={"nav min"}>
                    <nav className="navbar">
                        <Link to='/' className="logo" tabIndex={-1} onClick={(e)=>{
                            if(!e.ctrlKey && !e.shiftKey)
                                setFiltros({})
                        }}>
                            <img src={Logo}/>
                            <img src={LogoMobile} className="logoMobile"/>
                        </Link>
                        <span className="navDerecha">
                            {user?.companies !== undefined && user?.companies != 0 &&
                                <Link to="/host" tabIndex={-1}>Modo Anfitrión</Link>
                            }
                            {!user?.companies &&
                                <Link to="/unirte" tabIndex={-1}>Renta tu almacén</Link>
                            }
                            <button className="mundo" onClick={()=>{
                                setModalMundo(true);
                            }}>
                                <img src={Mundo}/>
                            </button>
                            <span className="menu" onClick={(e)=>{
                                e.stopPropagation();
                                setMenu(!menu);
                            }}>
                                <img src={Burger} className="burger" alt="Menú"/>
                                <img src={user.url ? user.url : user.picture ? user.picture : Perfil} className={"imgPerfil"+(user.picture ? " conImg" : "")} alt="Menú Perfil"/>
                                {user?.notifications?.client > 0 &&
                                    <div className='numNoti'>
                                        {user.notifications?.client > 99 ? "99+" : user.notifications?.client}
                                    </div>
                                }
                            </span>
                            {menu && !user.sub &&
                                <div className="menuNav" ref={menuNav}>
                                    <button onClick={()=>{
                                        setModalLogin(true)
                                    }}>
                                        <b>Regístrate</b></button>
                                    <button onClick={()=>{
                                        setModalLogin(true)
                                    }}>Iniciar sesión</button>
                                </div>
                            }
                            {menu && user.sub &&
                                <div className="menuNav" ref={menuNav}>
                                    <div className="bold">
                                        <Link to="/mensajes">Mensajes</Link>
                                        <Link to="/notificaciones">Mis Notificaciones {(user.notifications?.client != 0 ? `(${user.notifications?.client})` : '')}</Link>
                                        <Link to="/host/gestion/publicaciones">Mis Publicaciones {(user.posts ? `(${user.posts})` : '')}</Link>
                                        <Link to="/reservaciones">Mis Reservaciones {(user.bookings ? `(${user.bookings})` : '')}</Link>
                                        <Link to="/favoritos">Mis Favoritos{totalFavs != 0 ? " ("+totalFavs+")" : ""}</Link>
                                    </div>
                                    <div>
                                        <Link to="/host">Renta tu almacén</Link>
                                        <Link to="/perfil">Mi Perfil</Link>
                                        <Link to="#" onClick={eliminarDatos}>Eliminar datos (test only)</Link>
                                        <Link to="/transferencia">Simular Transferencia (test only)</Link>
                                    </div>
                                    <div>
                                        <Link to="/ayuda">Ayuda</Link>
                                        <button onClick={()=>{
                                            setUser({});
                                            setToken('');
                                            signOut({global:true})
                                        }}>Cerrar sesión</button>
                                    </div>
                                </div>
                            }
                        </span>
                    </nav>
                    <Buscador focused={focused} setFocused={setFocused}/>
                </nav>
                <nav className="fakeSearch">
                    <div className="buscador" onClick={()=>{
                        setModalBuscador(true);
                    }}>
                        <div>
                            <span className="iconoBuscar">
                                <img src={Buscar}/>
                            </span>
                            <span className="label">
                                <div>Busca tu almacén ideal</div>
                                <div>Lugar | Fecha | Espacios</div>
                            </span>
                        </div>
                    </div>
                    <div className="filtros">
                        <button 
                            className={totalFiltros ? "conFiltros" : ""}
                            onClick={()=>{
                                setModalFiltro(true);
                            }}>
                            {totalFiltros!==0 &&
                                <div className="totalFiltros">{totalFiltros}</div>
                            }
                            <img src={Filtros}/>
                            <span>Filtros</span>
                        </button>
                    </div>
                </nav>
                {modalBuscador &&
                    <Buscador modal={true} setModalBuscador={setModalBuscador}/>
                }
            </>
        )
    else
    return(
        <>
            {modalMundo &&
                <Modal className='modalMundo' titulo={'Opciones'} setModal={setModalMundo}>
                    <Configuracion setModalMundo={setModalMundo} config={config} setConfig={setConfig}/>
                </Modal>
            }
            <nav className={"nav min"}>
                <nav className="navbar">
                    <Link to='/host' className="logo" tabIndex={-1}>
                        <img src={Logo}/>
                        <img src={LogoMobile} className="logoMobile"/>
                    </Link>
                    <div className="navBarHost">
                        <NavLink to='/host/rentas' className={location.pathname == '/host' || location.pathname == '/host/' ? 'active' : ''}>Rentas</NavLink>
                        <NavLink to='/host/mensajes'>Mensajes</NavLink>
                        <NavLink to='/host/gestion'>Gestión</NavLink>
                        <NavLink to='/host/dashboard'>Dashboard</NavLink>
                        <button className="showmas" onClick={()=>{
                            setTimeout(()=>{
                                setMas(!mas);
                            }, 0)
                        }}>
                            <span>Más</span>
                            <img src={Flecha} className="flecha abajo"/>
                        </button>
                        {mas &&
                            <div className="masOpc" ref={masNav}>
                                <NavLink to='/host/equipo'>Equipo de Trabajo</NavLink>
                            </div>
                        }
                    </div>
                    <span className="navDerecha">
                        <Link to="/" tabIndex={-1}>Modo Cliente</Link>
                        <button className="mundo" onClick={()=>{
                                setModalMundo(true);
                            }}>
                            <img src={Mundo}/>
                        </button>
                        <span className="menu" onClick={(e)=>{
                            e.stopPropagation();
                            setMenu(!menu);
                        }}>
                            <img src={Burger} className="burger" alt="Menú"/>
                            <img src={user.picture ? user.picture : Perfil} className={"imgPerfil"+(user.picture ? " conImg" : "")} alt="Menú Perfil"/>
                            {user?.notifications?.host > 0 &&
                                <div className='numNoti'>
                                    {user.notifications?.host > 99 ? "99+" : user.notifications?.host}
                                </div>
                            }
                        </span>
                        {menu && !user.sub &&
                            <div className="menuNav" ref={menuNav}>
                                <button onClick={()=>{
                                    setModalLogin(true)
                                }}>
                                    <b>Regístrate</b></button>
                                <button onClick={()=>{
                                    setModalLogin(true)
                                }}>Iniciar sesión</button>
                            </div>
                        }
                        {menu && user.sub &&
                            <div className="menuNav" ref={menuNav}>
                                <div className="bold">
                                    <Link to="/mensajes">Mensajes</Link>
                                    <Link to="/host/notificaciones">Mis Notificaciones {(user.notifications?.host  != 0 ? `(${user.notifications?.host})` : '')}</Link>
                                    <Link to="/host/gestion/publicaciones">Mis Publicaciones {(user.posts ? `(${user.posts})` : '')}</Link>
                                    <Link to="/reservaciones">Mis Reservaciones {(user.bookings ? `(${user.bookings})` : '')}</Link>
                                    <Link to="/favoritos">Mis Favoritos{totalFavs != 0 ? " ("+totalFavs+")" : ""}</Link>
                                </div>
                                <div>
                                    <Link to="/host">Renta tu almacén</Link>
                                    <Link to="/perfil">Mi Perfil</Link>
                                    <Link to="#" onClick={eliminarDatos}>Eliminar datos (test only)</Link>
                                    <Link to="/transferencia">Simular Transferencia (test only)</Link>
                                </div>
                                <div>
                                    <Link to="/ayuda">Ayuda</Link>
                                    <button onClick={()=>{
                                        setUser({});
                                        setToken('');
                                        signOut({global:true})
                                    }}>Cerrar sesión</button>
                                </div>
                            </div>
                        }
                    </span>
                </nav>
            </nav>
        </>
    )
}

const Configuracion = ({config, setConfig, setModalMundo})=>{
    const [aux, setAux] = useState(config);

    const guardarConfig = ()=>{
        setConfig(aux);
        setModalMundo(false);
    }

    return(
        <>
            <div>
                <div>
                    <h3>Idioma</h3>
                    <div className="tipos">
                        <button 
                            className={aux?.idioma == 'es' ? 'sel' : ''}
                            onClick={()=>{
                                setAux({...aux, idioma: 'es'})
                            }}    
                        >Español</button>
                        <button 
                            className={aux?.idioma == 'en' ? 'sel' : ''}
                            onClick={()=>{
                                setAux({...aux, idioma: 'en'})
                            }}    
                        >English</button>
                    </div>
                </div>
                <div>
                    <h3>Unidades</h3>
                    <div className="tipos">
                        <button 
                            className={aux?.sistema == 'internacional' ? 'sel' : ''}
                            onClick={()=>{
                                setAux({...aux, sistema: 'internacional'})
                            }}    
                        >Sistema Internacional</button>
                        <button 
                            className={aux?.sistema == 'ingles' ? 'sel' : ''}
                            onClick={()=>{
                                setAux({...aux, sistema: 'ingles'})
                            }}    
                        >Sistema Inglés</button>
                    </div>
                </div>
            </div>
            <div className="botones">
                <button className="btn" onClick={guardarConfig}>Aceptar</button> 
                <button className="btn black" onClick={()=>{
                    setModalMundo(false)
                }}>Cancelar</button> 
            </div>
        </>
    )
}

export default NavBar;