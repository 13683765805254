import React, { useState, useRef, useEffect } from "react";
import { baseUrl } from '../../config.js'
import { Link, NavLink } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { signOut } from '@aws-amplify/auth';

import Buscador from '../Buscador/Buscador.js';
import Login from '../Login/Login.js';

import './BottomBar.css';
import Explora from '../../img/navExplora.png';
import Favoritos from '../../img/navFavoritos.png';
import Host from '../../img/navHost.png';
import Perfil from '../../img/navPerfil.png';

import {config} from '../../aws-exports.js';
Amplify.configure(config);

const BottomBar = ({user, setUser})=>{

    return(
        <>
            <nav className="bottomBar">
                <NavLink to='/'>
                    <img src={Explora}/>
                    <span>Explorar</span>
                </NavLink>
                <NavLink to='/favoritos'>
                    <img src={Favoritos}/>
                    <span>Favoritos</span>
                </NavLink>
                <NavLink to='/host'>
                    <img src={Host}/>
                    <span>Mis almacenes</span>
                </NavLink>
                {user?.sub &&
                    <NavLink to='/cuenta'>
                        <img src={user.picture ? user.picture : Perfil} className={"imgPerfil"+(user.picture ? " conImg" : "")} alt="Menú Perfil"/>
                        <span>Perfil</span>
                    </NavLink>
                }
                {!user?.sub &&
                    <NavLink to='/login'>
                        <img src={Perfil}/>
                        <span>Iniciar Sesión</span>
                    </NavLink>
                }
            </nav>
        </>
    )
}

export default BottomBar;