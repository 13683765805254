import React, { useEffect, useRef, useState } from "react";
import { baseUrl } from '../../config.js'
import { Link, useParams, useNavigate } from 'react-router-dom';
import Modal from '../Modal/Modal.js';
import { Documentos } from '../Persona/Persona.js';
import { DocumentosAlmacen, FotosAlmacen } from '../InfoAlmacen/InfoAlmacen.js';
import { formatoMoneda, idsRC, nombres, Imagenes, idServiciosR, idsR } from "../../almacen.js";

import useToken from '../../useToken';

import './Publicacion.css';

import Checked from '../../img/Checked.png';
import Cerrar from '../../img/Cerrar.png';
import Info from '../../img/Info.png';

const Publicacion = ({id_publicacion})=>{

	const navigate = useNavigate();
	const {token} = useToken();
	const [config, setConfig] = useState(localStorage.getItem('config') ? JSON.parse(localStorage.getItem('config')) : {
	  idioma : 'es',
	  sistema : 'internacional',
	})
	const { id, idPost = id_publicacion } = useParams();
	const [almacen, setAlmacen] = useState({idCompany:0, direccion:{}});
	const [persona, setPersona] = useState({});
	const [ocupados, setOcupados] = useState(0);
	const [inicial, setInicial] = useState(0);
	const [times, setTimes] = useState(["3"]);
	const [reembolso, setReembolso] = useState(100);
	const [publicacion, setPublicacion] = useState({typeOfStores:[], TypeTime : []});
	const [desglose, setDesglose] = useState({});
	const [status, setStatus] = useState(0);
	const [info, setInfo] = useState('');
	const [disabled, setDisabled] = useState(false);
	const [inmediata, setInmediata] = useState(false);
	const [personas, setPersonas] = useState([]);
	const [archivos, setArchivos] = useState({});
	const [photos, setPhotos] = useState({});
	const [totalFotos, setTotalFotos] = useState(0);
	const [eliminarFotos, setEliminarFotos] = useState([]);
	const [docsAlmacen, setDocsAlmacen] = useState({});
	const [archivosInvalidos, setArchivosInvalidos] = useState({persona: [], almacen: []});
	const [subiendo, setSubiendo] = useState({
		total : 0,
		subidas : 0,
		archivosSubidos : 0,
		totalArchivos : 0,
	});
	const [float, setFloat] = useState({});
	const [floatModal, setFloatModal] = useState({});
	const [modal, setModal] = useState('');
	const refTimes = useRef([]);
	const divDesc = useRef();

	const periodos = [
		{
			"nombre" : "Día", 
			"id" : "1"
		},
		{
			"nombre" : "Semana", 
			"id" : "2"
		},
		{
			"nombre" : "Mes", 
			"id" : "3"
		},
		{
			"nombre" : "Año", 
			"id" : "4"
		},
	]

	const lbls = {
		"-1" : "Faltante",
		"3" : "Rechazado",
		"6" : "En Revisión",
	}
	const clases = {
		"-1" : "SinSubir",
		"3" : "Rechazado",
		"6" : "Revision",
	}

	useEffect(()=>{
		if(token)
            getData();
		getEmpresas();
	}, [idPost, token])

	useEffect(()=>{
		setPersona({...persona})
	}, [modal])

	useEffect(()=>{
		let total = 0;
		for(let k in photos){
			total+= photos[k].length;
		}
		setTotalFotos(total);
	}, [photos])

	useEffect(()=>{
		desglosar();
	},[publicacion, times])

	const handleClickOutside = (event) => {
        if (divDesc.current && !divDesc.current.contains(event.target)) {
            setInfo(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

	useEffect(()=>{
		const aux = {"persona": [], "almacen": []};
		Object.keys(aux).forEach(tipo=>{
			const arreglo = tipo == "persona" ? persona?.DocumentCompany || [] : almacen?.Document || [];
			arreglo.forEach(doc=>{
				if(!doc.idStatusDocument){
					doc.idStatusDocument = '-1';
				}
				if(
					(doc.idDocument == 32 && !publicacion.typeOfStores.includes('11')) ||
					(doc.idDocument == 33 && !publicacion.typeOfStores.includes('5')) ||
					(doc.idDocument == 35 && !publicacion.typeOfStores.includes('9')) ||
					(doc.idDocument == 44 && !persona?.OperativeService?.includes('17')) ||
					(doc.idDocument == 45 && !persona?.OperativeService?.includes('18')) ||
					(doc.idDocument == 37 && persona.TypeCompany == 'Persona fisica')
				){
					return false;
				}
				if(doc.requiredStore == 'required' && doc.idStatusDocument != 4){
					aux["almacen"].push(doc);
				}
				else if(doc.requiredCompany == 'required' && doc.idStatusDocument != 4){
					aux["persona"].push(doc);
				}
			})
		})
		setArchivosInvalidos(aux);

	}, [persona, publicacion])

	useEffect(()=>{
		let periodo = 0;
		let valor = 0;
		if(refTimes.current[2]){
			periodo = 2;
			valor = refTimes.current[2].value || publicacion?.month;
		}
		else{
			for(let i = 0; i <= 3; i++){
				if(refTimes.current[i]){
					periodo = i;
					valor = refTimes.current[i].value;
				}
			}
		}
		if(periodo && publicacion?.idPost){
			sugerir(periodo, valor);
		}
	}, [times, publicacion])

	async function getData(){
		const result = await fetch(baseUrl+"Store/"+id,{
			headers:{
				'Authorization': `Bearer ${token}`,
			}
		})
		.then(response=>response.json())
		.then(json=>{
			return json;
		})
		.catch(error=>{
			console.error(error);
		})
		
		let result1;
		if(idPost){
			result1 = await fetch(baseUrl+"Post/"+idPost,{
					headers:{
						'Authorization': `Bearer ${token}`,
					}
				})
			.then(response=>response.json())
			.then(json=>{
				return json;
			})
			.catch(error=>{
				console.error(error);
			})
		}
		if(result1?.success){
			if(config.sistema == 'ingles'){
				result1.data.width = (result1.data?.width || 0)*(3.2808399)/100;
				result1.data.length = (result1.data?.length || 0)*(3.2808399)/100;
				result1.data.height = (result1.data?.height || 0)*(3.2808399)/100;
				result1.data.weight = (result1.data?.weight || 0)*(2.20462262);
			}
			setPublicacion({typeOfStores:[], TypeTime: [], width : 100, length:100, ...result1?.data} || {});
			setReembolso(result1?.data?.refund || 0)
			setStatus(result1?.data?.status || 0)
			setTimes(result1?.data?.TypeTime || [])
			setInmediata(result1?.data?.inmediate);
		}
		const result2 = await fetch(baseUrl+"Store/"+id+"/totalAssignedSpaces",{
			headers:{
				'Authorization': `Bearer ${token}`,
			}
		})
		.then(response=>response.json())
		.then(json=>{
			return json;
		})
		.catch(error=>{
			console.error(error);
		})
		result.documentsData = result?.DocumentCompany;
		setPersona(result)
		setDocsAlmacen(result?.Document);

		const auxArchivos = {};
		result.Document?.forEach(document=>{
			auxArchivos[document.idDocument] = document;
		})
		setArchivos(auxArchivos);

		const auxPhotos = {};
		result.Photos?.forEach(photo=>{
			if(!auxPhotos[photo.idCategoryPhoto])
				auxPhotos[photo.idCategoryPhoto] = [];
			auxPhotos[photo.idCategoryPhoto].push(photo);
		})
		setPhotos(auxPhotos);
		result.tipos = {};
		result?.TypeStore?.forEach(x=>{
			result.tipos[idsR[x]] = true;
		})
		result.servicios = {};
		result?.OperativeService?.forEach(x=>{
			result.servicios[idServiciosR[x]] = true;
		})
		setAlmacen({idCompany:0, direccion:{}, tipos:{}, servicios:{}, ...result} || {idCompany:0, direccion:{}, tipos:{}, servicios:{}});
		setOcupados(result2?.totalAssignedSpaces*1 || 0)
		setInicial(result1?.data?.assignedSpaces*1 || 0)
	}

	const getEmpresas = async ()=>{
		const result = await fetch(baseUrl+"User/Companies/", {
			headers:{
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			}
		})
		.then(response=>response.json())
		.then(json=>{
			// console.log(json);
			return json;
		})
		.catch(error=>{
			console.error(error);
		})
		setPersonas(result?.data);
	}

	const guardar = async (e)=>{
		setFloat({estado:'guardando', mensaje:'Guardando...'})
		if(publicacion.assignedSpaces > (isNaN(inicial*1 + almacen?.availableSpace*1 - ocupados) ? 0 : inicial*1 + almacen?.availableSpace*1 - ocupados)){
			setFloat({estado:'error', mensaje:'El número de espacios asignados excede los espacios disponibles'});
			setTimeout(() => {
				setFloat({});
			}, 5000);
			return false;
		}
		publicacion.idStore = id;
		publicacion.TypeTime = times;
		publicacion.refund = reembolso;
		if(config.sistema == 'ingles'){
			publicacion.width = publicacion.width*100/3.2808399;
			publicacion.length = publicacion.length*100/3.2808399;
			publicacion.height = publicacion.height*100/3.2808399;
			publicacion.weight = publicacion.weight/2.20462262;
		}
		const data = {
			...publicacion,
		}
		const accion = idPost ? "put" : "post";
		if(idPost){
			data["idPost"] = idPost;
		}
		// return false;
		const result = await fetch(baseUrl+"Post", {
			method: accion,
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
			body: JSON.stringify(data),
		})
		.then(response=>response.json())
		.then(json=>{
			return json;
		})
		.catch(error=>{
			console.error(error);
			setFloat({})
		})
		if(result?.success){
			setFloat({estado:'listo', mensaje:'¡Listo! Se han guardado los cambios con éxito'})
			if(!idPost)
				navigate('/host/publicaciones/'+id+'/'+result.id, {replace: true})
			setTimeout(() => {
				setFloat({});
			}, 5000);
		}
		else{
			setFloat({estado:'error', mensaje:result?.error})
			setTimeout(() => {
				setFloat({});
			}, 5000);
		}
	}
	const cambiarStatus = (status = 0)=>{
		const data = {
			idPost,
			status,
		}
		fetch(baseUrl+"StatusPost", {
			method: "put",
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
			body: JSON.stringify(data),
		})
		.then(response=>response.json())
		.then(json=>{
			setStatus(status);
			return json;
		})
		.catch(error=>{
			console.error(error);
			setFloat({})
		})
	}
	const sugerir = (periodo, costo)=>{
		const rel = [1,7,30,365];
		const names = ["day", "week", "month", "year"];
		const costoDia = costo/rel[periodo];
		refTimes.current[periodo].parentNode.className = "";
		for(let i = 0; i <= 4; i++){
			if(refTimes.current[i]){
				if(refTimes.current[i].value == "" || refTimes.current[i].value == 0 || refTimes.current[i].parentNode.className == "sugerido"){
					refTimes.current[i].parentNode.className = "sugerido";
					publicacion[names[i]] = refTimes.current[i].value = Math.round(costoDia*rel[i]*(Math.pow(.91, i - periodo))*100)/100;
				}
			}
		}
		desglosar();
	}
	const desglosar = ()=>{
		let precioMes = 100;
		const rel = [30,30/7,1,1/12];
		const names = ["day", "week", "month", "year"];

		if(times.includes("3")){
			precioMes = publicacion["month"];
		}
		else{
			for(let i = 0; i <= 4; i++){
				if(times.includes((i+1)+"")){
					precioMes = publicacion[names[i]]*rel[i];
				}
			}
		}
		const comision = .05;
		const impuestos = .16;
		const monto = precioMes*1;
		const aux = {};
		aux["monto"] = Math.round(precioMes*100)/100;
		aux["comision"] = Math.round(monto*comision*100)/100;
		aux["subtotal"] = Math.round((monto + aux["comision"])*100)/100;
		aux["impuestos"] = Math.round(aux["monto"]*impuestos*100)/100;
		aux["total"] = Math.round((aux["monto"] + aux["impuestos"])*100)/100;
		
		setDesglose(aux);

	}
	const guardarDocsAlmacen = ()=>{
		const results = [];
		let totalArchivos = 0;
		for(let i in archivos){
			if(!archivos[i].file)
				continue;
			const data = new FormData();
			data.append('idStore', id);
			data.append('idDocument', archivos[i].idDocument);
			data.append('file', archivos[i].file);
			
			results.push(
				fetch(baseUrl+"Document/Store", {
					method: "post",
					headers: {
						'Accept': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
					body: data,
				})
				.then(response=>response.json())
				.then(json=>{
					// console.log(json);
					setSubiendo({
						totalArchivos : totalArchivos
					})
					
					archivos[i].file = undefined;
					archivos[i].idStatusDocument = 6;
					setArchivos([...archivos])
					return json;
				})
				.catch(error=>{
					console.error(error);
				})
			)
		}
		Promise.all(results)
		.then(()=>{
			setSubiendo({subidas:0, total : 0, archivosSubidos : 0, totalArchivos : 0})
			setFloatModal({estado:'listo', mensaje:'¡Listo! Se han guardado los cambios con éxito'})
			setTimeout(() => {
				setFloatModal({});
			}, 5000);
			return true;
		})
	}
	const guardarFotosAlmacen = ()=>{
		var results = {
			"fotos": [],
			"eliminarFotos": [],
			"ordenar": [],
		};
		let total = 0;
		for(let k in photos){
			total+= photos[k].reduce((a,b)=> {
				if(b.file)
					return a+1;
				else
					return a+0;
			}, 0);
		}
		let subidas = 0;
		const ordenar = {};
			for(let idCategory in photos){
				photos[idCategory].forEach(photo=>{
					if(photo.uid || !photo.file){
						if(photo.orden!==undefined){
							ordenar[photo.uid] = photo.orden;	
						}
						return false;
					}
					const data = new FormData();
					data.append('idStore', id);
					data.append('idCategoryPhoto', idCategory);
					data.append('file', photo.file);
					data.append('orden', photo.orden);
					
					results["fotos"].push(
						fetch(baseUrl+"Photo/Store", {
							method: "post",
							headers: {
								'Accept': 'application/json',
								'Authorization': `Bearer ${token}`,
							},
							body: data,
						})
						.then(response=>response.json())
						.then(json=>{
							// console.log(json);
							setSubiendo({
								total : total,
								subidas : ++subidas,
							})
							photo.file = undefined;
							return json;
						})
						.catch(error=>{
							console.error(error);
						})
					)
				})	
			}
			results["ordenar"].push(
				fetch(baseUrl+"Photo/Order", {
					method: "put",
					headers: {
						'Content-Type': 'application/json',
						'Accept': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
					body: JSON.stringify({ "photos" : ordenar }),
				})
				.then(response=>response.json())
				.then(json=>{
					return json;
				})
				.catch(error=>{
					console.error(error);
				})
			)
			results["eliminarFotos"].push(
				fetch(baseUrl+"Photo/Store", {
					method: "delete",
					headers: {
						'Content-Type': 'application/json',
						'Accept': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
					body: JSON.stringify({id, "photos":eliminarFotos}),
				})
				.then(response=>response.json())
				.then(json=>{
					return json;
				})
				.catch(error=>{
					console.error(error);
				})
			)
		Promise.all([...results["fotos"], ...results["ordenar"], ...results["eliminarFotos"]])
		.then(()=>{
			setSubiendo({subidas:0, total : 0, archivosSubidos : 0, totalArchivos : 0})
			setFloatModal({estado:'listo', mensaje:'¡Listo! Se han guardado los cambios con éxito'})
			setTimeout(() => {
				setFloatModal({});
			}, 5000);
			return true;
		})
	}
	const guardarAlmacen = async (e)=>{
		if(e?.target)
			e.target.disabled = true;
		setFloatModal({estado:'guardando', mensaje:'Guardando...'})
		const data = {
			"idCompany":0,
			"name":"",
			"locationURL":"",
			"storeArea":"",
			"officeArea":"",
			"storeAreaAvailable":"",
			"totalSpace":"",
			"availableSpace":"",
			"idColony":"0",
			"region":"",
			"street":"",
			"number":"",
			"zipCode":"",
			"idTypeAddress":"1",
			...almacen
		}
		// return false;
		if(id){
			data["idStore"] = id;
		}
		const result = await fetch(baseUrl+"Store/Company", {
			method: "put",
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
			body: JSON.stringify(data),
		})
		.then(response=>response.json())
		.then(json=>{
			setFloatModal({estado:'listo', mensaje:'¡Listo! Se han guardado los cambios con éxito'})
			setTimeout(() => {
				setFloatModal({});
			}, 5000);
			if(e?.target)
				e.target.disabled = false;
			return json;
		})
		.catch(error=>{
			console.error(error);
			setFloat({})
			if(e?.target)
				e.target.disabled = false;
		})		
	}
	return(
		<div className="almacen">
			{modal == 'persona' &&
				<Modal setModal={setModal} titulo={"Subir Archivos Persona"}>
					<Documentos tipo={almacen?.TypeCompany == "Persona fisica" ? "Física" : "Moral"} persona={persona} idCompany={almacen?.idCompany} guardar={guardar}/>
					<div className="btnSubir">
						<button className="btn" onClick={()=>{
							setModal({});
						}}>Cerrar</button>
					</div>
				</Modal>
			}
			
			{modal == 'sinPersona' &&
				<Modal setModal={setModal} titulo={"Asignar Almacén"} className={"asignaModal"}>
					<div className="centraModal">
						{floatModal?.estado &&
							<div className={"float"+(" "+floatModal.estado)}>
								<div className="mensaje">{floatModal.mensaje}</div>
							</div>
						}
						<form>
							<label className="select selectModal">
								<span>Empresa</span>
								<select defaultValue={almacen.idCompany} onChange={(e)=>{
									almacen.idCompany = e.target.value;
								}}>
									<option selected={almacen.idCompany == 0} value={0}>Sin asignar</option>
									{personas.map(persona=>{
										return(
											<option key={persona.idCompany} selected={almacen.idCompany == persona.idCompany} value={persona.idCompany}>({persona.idtypeCompany == 1 ? "Física" : "Moral"}) {persona.name}</option>
										)
									})
									}
								</select>
							</label>
						</form>
					</div>
					<div className="btnSubir">
						<button className="btn" onClick={guardarAlmacen}>Asignar Almacén</button>
					</div>
				</Modal>
			}
			{modal == 'almacen' &&
				<Modal setModal={setModal} titulo={"Subir Archivos Almacén"}>
					<div>
						{floatModal?.estado &&
							<div className={"float"+(" "+floatModal.estado)}>
								<div className="mensaje">{floatModal.mensaje}</div>
							</div>
						}
						<DocumentosAlmacen almacen={almacen} archivos={docsAlmacen} setArchivos={setDocsAlmacen} requiredOnly={true} guardar={guardarDocsAlmacen}/>
					</div>
					<div className="btnSubir">
						<button className="btn" onClick={()=>{
							setModal({});
						}}>Cerrar</button>
					</div>
				</Modal>
			}
			{modal == 'fotos' &&
				<Modal setModal={setModal} titulo={"Subir Fotos"}>
					<div>
						{floatModal?.estado &&
							<div className={"float"+(" "+floatModal.estado)}>
								<div className="mensaje">{floatModal.mensaje}</div>
							</div>
						}
						<FotosAlmacen almacen={almacen} photos={photos} setPhotos={setPhotos} setEliminarFotos={setEliminarFotos}/>
					</div>
					<div className="btnSubir">
						<button className="btn" onClick={guardarFotosAlmacen}>Subir Fotos</button>
					</div>
				</Modal>
			}
			{almacen?.idStore && !almacen?.idCompany &&
				<div className="noPublicable" onClick={()=>setModal('sinPersona')}>
					<div>El <b>Almacén</b> no está ligado a una <b>Persona Física o Moral</b>.</div>
					<div>Esto es necesario para poder publicar Almacenes.</div>
				</div>
			}
			{almacen?.idStore && almacen?.idCompany && archivosInvalidos["persona"].length != 0 &&
				<div className="noPublicable" onClick={()=>setModal('persona')}>
					<div>La <b>Persona {almacen?.TypeCompany == "Persona fisica" ? "Física" : "Moral"}</b> no cuenta con los siguientes archivos Aprobados para esta publicación</div>
					{archivosInvalidos["persona"].length != 0 &&
					<>
						<div>
							{archivosInvalidos["persona"].map(doc=>{
								if(id == 39 && almacen?.TypeCompany != "Persona fisica"){
									return (
										<div key={doc.idDocument}>— {doc.name} del Representante Legal <i className={clases[doc.idStatusDocument]}>{lbls[doc.idStatusDocument]}</i></div>
									)
								}
								return(
									<div key={doc.idDocument}>— {doc.name} <i className={clases[doc.idStatusDocument]}>{lbls[doc.idStatusDocument]}</i></div>
								)
							})
						}
						</div>
					</>
					}
				</div>
			}
			{almacen?.idStore && archivosInvalidos["almacen"].length !== 0 &&
				<div className="noPublicable" onClick={()=>setModal('almacen')}>
					<div>Este <b>Almacén</b> no cuenta con los archivos Aprobados para esta publicación.</div>
					{archivosInvalidos["almacen"].length != 0 &&
					<>
						<div>
							{archivosInvalidos["almacen"].map(doc=>{
								return(
									<div key={doc.idDocument}>— {doc.name} <i className={clases[doc.idStatusDocument]}>{lbls[doc.idStatusDocument]}</i></div>
								)
							})
						}
						</div>
					</>
					}
				</div>
			}
			{almacen?.idStore && totalFotos < 6 &&
				<div className="noPublicable" onClick={()=>setModal('fotos')}>
					<div>Este <b>Almacén</b> no cuenta con las fotos necesarias para poder ser publicado</div>
					<div><i>Mínimo 6 fotos</i> <span className={totalFotos === 0 ? 'SinSubir' : 'Revision'}>{totalFotos} fotos de 6</span></div>
				</div>
			}
			{float?.estado &&
				<div className={"float"+(" "+float.estado)}>
					<div className="mensaje">{float.mensaje}</div>
				</div>
			}
			<div className="opciones">
				{idPost &&
					<Link to={'/almacen/'+idPost} className="btn">Ver publicación</Link>
				}
				<button className="btn" onClick={guardar}>Guardar</button>
			</div>
			{almacen?.idCompany && archivosInvalidos["persona"].length == 0 && archivosInvalidos["almacen"].length == 0 && idPost &&
				<div className="statusPublicacion">
					<button type="button" className={"btn"+(status == 0 ? "" : " apagado")} disabled={disabled} onClick={()=>{
						if(status != 0 && window.confirm("La publicación pasará a 'Sin publicar', ¿Estás seguro?")){
							cambiarStatus(0)
						}
					}}>Sin Publicar</button>
					<button type="button" className={"btn"+(status == 1 ? "" : " apagado")} disabled={disabled} onClick={()=>{
						if(status != 1 && window.confirm("La publicación pasará a 'Publicada', ¿Estás seguro?")){
							cambiarStatus(1)
						}
					}}>Publicada</button>
				</div>
			}
			<form>
				<div>
					<h3>Tipo de Almacenaje</h3>
					<div className="tipos almacenaje">
						{almacen?.TypeStore?.map(id=>{
							return(
								<label key={id}>
									<input type="checkbox" defaultChecked={publicacion.typeOfStores.includes(id)} onChange={(e)=>{
										if(e.target.checked){
											if(publicacion.typeOfStores.indexOf(id) == -1){
												publicacion.typeOfStores.push(id)
											}
										}
										else{
											if(publicacion.typeOfStores.indexOf(id) != -1){
												publicacion.typeOfStores.splice(publicacion.typeOfStores.indexOf(id), 1)
											}
										}
										setPublicacion({...publicacion})
									}}/>
									<div>
										<img src={Imagenes[idsRC[id]]}/>
										<div>{nombres[id]}</div>
										<div className="cuadro">
											<img src={Checked}/>
										</div>
									</div>
								</label>
							)
						})
						}
					</div>
					<div>
						<h4 className="titleConfirmacion">
							<span>Confirmación de Reserva</span>
							<button type='button' className="info" onClick={()=>{
									setInfo('inmediata')
								}}>
								<img src={Info}/>
							</button>
							{info == 'inmediata' &&
								<div className="descHelp" ref={divDesc}>
									<button type='button' className="cerrar" onClick={()=>{
										setInfo('');
									}}>
										<img src={Cerrar}/>
									</button>
									<div>
										<b>Inmediata: </b> En el momento en el que se acredita el pago, se aprueba la reservación de manera automática.
										<br/>
										<b>Consultar Host: </b> Se pide la confirmación del Host antes de aprobar la reservación.
									</div>
								</div>
							}
						</h4>
						<div className="tipos reservacion">
							<button type="button" className={inmediata ? 'sel' : ''} onClick={()=>{
								setInmediata(true)
								publicacion.inmediate = true;
							}}>
								<div>Inmediata</div>
								<div className="contEspacios">
									<span>
										<div>Restantes</div>
										<div>{isNaN(almacen?.availableSpace - ocupados) ? 0 : almacen?.availableSpace - ocupados}</div>
									</span>
									<span>
										<div>Disponibles</div>
										<div>{isNaN(almacen?.availableSpace) ? 0 : almacen?.availableSpace*1}</div>
									</span>
								</div>
							</button>
							<button type="button" className={!inmediata ? 'sel' : ''} onClick={()=>{
								setInmediata(false)
								publicacion.inmediate = false;
							}}>
								<div>Consultar Host</div>
								<div className="contEspacios">
									<span>
										<div>Restantes</div>
										<div>{isNaN(almacen?.availableConfirmSpace - ocupados) ? 0 : almacen?.availableConfirmSpace - ocupados}</div>
									</span>
									<span>
										<div>Disponibles</div>
										<div>{isNaN(almacen?.availableConfirmSpace) ? 0 : almacen?.availableConfirmSpace*1}</div>
									</span>
								</div>
							</button>
						</div>
					</div>
					{false &&
					<>
						<div className="divInmediata">
							<label className="inmediata switch">
								<div>Reservación Inmediata</div>
								<input type="checkbox" checked={inmediata} onChange={(e)=>{
									publicacion.immediate = !inmediata;
									setInmediata(!inmediata);
								}}
								/>
								<span>
									<span className="bola">
										<img src={Checked}/>
									</span>
								</span>
							</label>
							<button type='button' className="info" onClick={()=>{
									setInfo('inmediata')
								}}>
								<img src={Info}/>
							</button>
							{info == 'inmediata' &&
								<div className="descHelp" ref={divDesc}>
									<button type='button' className="cerrar" onClick={()=>{
										setInfo('');
									}}>
										<img src={Cerrar}/>
									</button>
									<div>
										<b>Reservación Inmediata: </b> En el momento en el que se acredita el pago, se aprueba la reservación de manera automática.
										<br/>
										<b>Reservación Por Confirmar: </b> Se pide la confirmación del Host antes de aprobar la reservación.
									</div>
								</div>
							}
						</div>
						<div className="resumenEspacios">
							<div>Total de Espacios Disponibles: <b>{isNaN(almacen?.availableSpace) ? 0 : almacen?.availableSpace}</b></div>
							<div>Espacios restantes: <b>{isNaN(almacen?.availableSpace - ocupados) ? 0 : almacen?.availableSpace - ocupados}</b></div>
						</div>
					</>
					}
					<div className="infoPublicacion">
						<label>
							<input type="text" minLength={1} placeholder="" defaultValue={publicacion?.name} onChange={(e)=>{
								publicacion.name=e.target.value;
							}} disabled={disabled}/>
							<span>Título de la publicación</span>
						</label>
						<label className="mitad">
							<input type="number" min={1} max={isNaN(inicial*1 + almacen?.availableSpace*1 - ocupados) ? 0 : inicial*1 + almacen?.availableSpace*1 - ocupados} placeholder="" defaultValue={publicacion?.assignedSpaces} onChange={(e)=>{
								publicacion.assignedSpaces=e.target.value;
								setOcupados(ocupados - (inicial - e.target.value))
								setInicial(e.target.value)
							}} disabled={disabled}/>
							<span>Espacios Asignados</span>
						</label>
						<label className="mitad">
							<input type="number" min={1} placeholder="" defaultValue={publicacion?.width} onChange={(e)=>{
								publicacion.width=e.target.value;
							}} disabled={disabled}/>
							<span>Ancho ({config.sistema=='ingles' ? 'ft' : 'cm'})</span>
						</label>
						<label className="mitad">
							<input type="number" min={1} placeholder="" defaultValue={publicacion?.length} onChange={(e)=>{
								publicacion.length=e.target.value;
							}} disabled={disabled}/>
							<span>Largo ({config.sistema=='ingles' ? 'ft' : 'cm'})</span>
						</label>
						<label className="mitad">
							<input type="number" min={1} placeholder="" defaultValue={publicacion?.height} onChange={(e)=>{
								publicacion.height=e.target.value;
							}} disabled={disabled}/>
							<span>Alto ({config.sistema=='ingles' ? 'ft' : 'cm'})</span>
						</label>
						<label>
							<input type="number" min={1} placeholder="" defaultValue={publicacion?.weight} onChange={(e)=>{
								publicacion.weight = e.target.value;
							}} disabled={disabled}/>
							<span>Capacidad de carga ({config.sistema=='ingles' ? 'lb' : 'kg'})</span>
						</label>
					</div>
					<div>
						<h3>Precio por Periodo</h3>
					</div>
					<div className="infoPrecio">Selecciona uno o varios periodos mínimos de renta</div>
					<div className="tipos precios">
						{periodos.map(periodo=>{
							return(
								<label key={periodo.id}>
									<input type="checkbox" checked={times?.includes(periodo.id)} onChange={(e)=>{
										if(e.target.checked){
											if(times.indexOf(periodo.id) == -1){
												times.push(periodo.id)
											}
										}
										else{
											if(times.indexOf(periodo.id) != -1){
												times.splice(times.indexOf(periodo.id), 1)
											}
										}
										setTimes([...times]);
									}}/>
									<div>
										<div>{periodo.nombre}</div>
									</div>
								</label>
							)
						})
						}
					</div>
					<div className="preciosPost">
						{times.includes("1") &&
							<label>
								<input type="number" defaultValue={publicacion?.day} ref={ref=>{refTimes.current[0] = ref; return ref;}} onChange={(e)=>{
									if(e.target.value == ""){
										return false;
									}
									publicacion.day = e.target.value*1;
									sugerir(0, e.target.value)
								}} placeholder=""/>
								<span>Precio por Día</span>
							</label>
						}
						{times.includes("2") &&
							<label>
								<input type="number" defaultValue={publicacion?.week} ref={ref=>{refTimes.current[1] = ref; return ref;}} onChange={(e)=>{
									if(e.target.value == ""){
										return false;
									}
									publicacion.week = e.target.value*1;
									sugerir(1, e.target.value)
								}} placeholder=""/>
								<span>Precio por Semana</span>
							</label>
						}
						{times.includes("3") &&
							<label>
								<input type="number" defaultValue={publicacion?.month} ref={ref=>{refTimes.current[2] = ref; return ref;}} onChange={(e)=>{
									if(e.target.value == ""){
										return false;
									}
									publicacion.month = e.target.value*1;
									sugerir(2, e.target.value)
								}} placeholder=""/>
								<span>Precio por Mes</span>
							</label>
						}
						{times.includes("4") &&
							<label>
								<input type="number" defaultValue={publicacion?.year} ref={ref=>{refTimes.current[3] = ref; return ref;}} onChange={(e)=>{
									if(e.target.value == ""){
										return false;
									}
									publicacion.year = e.target.value*1;
									sugerir(3, e.target.value)
								}} placeholder=""/>
								<span>Precio por Año</span>
							</label>
						}
					</div>
					<div className="desglose">
						<div>Este es tu desglose por mes</div>
						<div>
							<span>Monto</span>
							<span>{formatoMoneda(desglose?.monto)}</span>
						</div>
						<div>
							<span>Comisión PalletCloud (5%)</span>
							<span>+{formatoMoneda(desglose?.comision)}</span>
						</div>
						<div>
							<span>Subtotal</span>
							<span>{formatoMoneda(desglose?.subtotal)}</span>
						</div>
						<div>
							<span>Impuestos (16%)</span>
							<span>+{formatoMoneda(desglose?.impuestos)}</span>
						</div>
						<div>
							<span>Total</span>
							<span>{formatoMoneda(desglose?.total)}</span>
						</div>
					</div>
					<div>
						<i>En caso de que el cliente <b>no cancele</b> su reserva, recibirás un <b>anticipo del 20% siete días</b> antes de la fecha de inicio y el resto se abonará en la fecha de inicio.</i>
					</div>
					<div>
						<h4>Política de Cancelación</h4>
						<div className="tipos cancelacion">
							<button className={reembolso == 100 ? 'sel' : ''} onClick={()=>{
								setReembolso(100);
							}} type="button">
								<h4>Reembolso Total</h4>
								<div>El cliente puede cancelar hasta <b>7 días</b> antes de la fecha de inicio y en este caso, se le <b>reembolsará el 100%</b> de su pago</div>
							</button>
							<button className={reembolso == 50 ? 'sel' : ''} onClick={()=>{
								setReembolso(50);
							}} type="button">
								<h4>Reembolso Parcial</h4>
								<div>El cliente puede cancelar hasta <b>7 días</b> antes de la fecha de inicio y en este caso, se le <b>reembolsará el 50%</b> de su pago</div>
								<div className="infoParcial">
									<i>En caso de que el cliente <b>cancele su reserva</b>, recibirás el 50% del pago correspondiente a más tardar <b>7 días hábiles</b> despues de la fecha de cancelación.</i>
								</div>
							</button>
							<button className={reembolso == 0 ? 'sel' : ''} onClick={()=>{
								setReembolso(0);
							}} type="button">
								<h4>Sin Reembolso</h4>
								<div>El cliente no puede cancelar la reservación y de hacerlo, <b>no se le devolverá el pago</b>.</div>
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
}
export default Publicacion;