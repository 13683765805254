import React, { useEffect, useState } from 'react';
import { baseUrl } from './config.js';
import { onMessageListener, requestPermission, deviceToken } from './firebase.js';
import { Amplify } from 'aws-amplify';
import { Hub } from "aws-amplify/utils";
import { fetchUserAttributes, fetchAuthSession } from 'aws-amplify/auth';
import { BrowserRouter, Route, Routes, Outlet, NavLink } from 'react-router-dom';
import useToken from './useToken';
import Inicio from './Components/Inicio/Inicio.js';
import NavBar from './Components/NavBar/NavBar.js';
import NavBarCuenta from './Components/NavBar/NavBarCuenta.js';
import NavBarGestion from './Components/NavBar/NavBarGestion.js';
import Login from './Components/Login/Login.js';
import Footer from './Components/Footer/Footer.js';
import Cuenta from './Components/Cuenta/Cuenta.js';
import Almacen from './Components/Almacen/Almacen.js';
import InfoAlmacen from './Components/InfoAlmacen/InfoAlmacen.js';
import MisPublicaciones from './Components/Publicaciones/MisPublicaciones.js';
import Publicaciones from './Components/Publicaciones/Publicaciones.js';
import Publicacion from './Components/Publicacion/Publicacion.js';
import Fotos from './Components/Fotos/Fotos.js';
import HostPersonas from './Components/Host/Personas.js';
import HostRentas from './Components/Host/Rentas.js';
import CalendarioRentas from './Components/CalendarioRentas/CalendarioRentas.js';
import HostAlmacenes from './Components/Host/Almacenes.js';
import Persona from './Components/Persona/Persona.js';
import BottomBar from './Components/BottomBar/BottomBar.js';
import Unirte from './Components/Unirte/Unirte.js';
import Reservar from './Components/Reservar/Reservar.js';
import Reservados from './Components/Reservados/Reservados.js';
import Reservacion from './Components/Reservacion/Reservacion.js';
import Notificaciones from './Components/Notificaciones/Notificaciones.js';

// cuenta
import DashEspacios from './Components/Dashboards/DashEspacios.js';
import DashAlmacenes from './Components/Dashboards/DashAlmacenes.js';
import DashPublicaciones from './Components/Dashboards/DashPublicaciones.js';
import DashUbicacion from './Components/Dashboards/DashUbicacion.js';
import DashFinanzas from './Components/Dashboards/DashFinanzas.js';
import DashFechas from './Components/Dashboards/DashFechas.js';
import Rentas from './Components/Rentas/Rentas.js';
import ReservacionHost from './Components/Reservacion/ReservacionHost.js';


// test only
import Transferencia from './Components/Transferencia/Transferencia.js';

import { BuscadorProvider }  from './Components/Buscador/BuscadorContext.js';
import './App.css';
import './Tablet.css';
import './Mobile.css';

import Cerrar from './img/Cerrar.png'
import Share from './img/share.png';

import {config} from './aws-exports.js';
Amplify.configure(config);

function App() {
  const {token, setToken} = useToken();
  const [config, setConfig] = useState(localStorage.getItem('config') ? JSON.parse(localStorage.getItem('config')) : {
    idioma : 'es',
    sistema : 'internacional',
  })
  const [user, setUser] = useState({});
  const [modalLogin, setModalLogin] = useState(false);
  const [modalFiltro, setModalFiltro] = useState(false);
  const [instalar, setInstalar] = useState(false);
  const [installPromptEvent, setInstallPromptEvent] = useState(null);
  const [iOSinstall, setIOSinstall] = useState(false);
  
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  useEffect(()=>{
    currentAuthenticatedUser();
  }, [])

  useEffect(()=>{
    checkNoti();
  }, [token])

  useEffect(()=>{
    localStorage.setItem('config', JSON.stringify(config));
  },[config])

  useEffect(() => {
    setIOSinstall(iOS && !navigator.standalone);
    setTimeout(()=>{
      setIOSinstall(false);
    }, 15000)
    window.addEventListener('beforeinstallprompt', (e) => {
      // Evita que Chrome muestre el mini-infobar
      e.preventDefault();
      // Guarda el evento para que pueda ser llamado más tarde
      setInstallPromptEvent(e);
      setInstalar(true);
    });
    window.addEventListener('appinstalled', () => {
      // La aplicación fue instalada
      setInstalar(false);
    });
  }, []);

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", async ({ payload }) => {
      console.log("listening")
      switch (payload.event) {
        case "signInWithRedirect":
          const redirectUrl = localStorage.getItem('redirectUrl') || '/';
          localStorage.removeItem('redirectUrl');
          if(redirectUrl != '/'){
            window.location.href = redirectUrl;
          }
          handleSignIn();
          break;
        case "signInWithRedirect_failure":
          console.log(payload)
          console.log("An error has occurred during the OAuth flow.");
          break;
      }
    });

    currentAuthenticatedUser();

    return unsubscribe;
  }, []);

  onMessageListener()?.then(payload => {
    console.log(payload)
    // setShow(true);
    // setNotification(payload)
		setTimeout(()=>{
			// setShow(false);
		},5000)
  }).catch(err => console.log('failed: ', err));

  const checkNoti = async ()=>{
    if(!token){
      const tokenDevice = await deviceToken();
      if(!tokenDevice){
        requestPermission();
      }
    }
  }

  const handleInstallPWA = () => {
    if (!installPromptEvent) return;
    // Muestra el mensaje de instalación
    installPromptEvent.prompt();
    // Espera a que el usuario responda a la pregunta
    installPromptEvent.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('El usuario aceptó la instalación');
      } else {
        console.log('El usuario rechazó la instalación');
      }
      // Borra el evento guardado
      setInstallPromptEvent(null);
    });
  };

  async function currentAuthenticatedUser() {
    try {
      const result2 = await fetchUserAttributes();
      const accessToken = (await fetchAuthSession()).tokens?.accessToken?.toString();
      getData({...user, ...result2, old:true}, accessToken);
      setToken(accessToken);

    } catch (err) {
      console.log(err);
    }
  }

  async function handleSignIn(){
    const usuario = await fetchUserAttributes();
    if(Object.keys(usuario) != 0){
      const data = {
          "name": usuario?.given_name,
          "lastname": usuario?.family_name,
          "password": usuario?.password,
          "cellphone": usuario?.phone_number,
          "uuid": usuario?.sub,
          "email": usuario?.email,
          "birthdate": usuario?.birthdate,
      }
      
      fetch(baseUrl+"api/alta/Usuario", {
          method: "post",
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then(response=>response.json())
      .then(json=>{
          console.log(json);
          if(json.idUser){
            subirFoto(usuario);
          }
      })
      .catch(error=>{
          console.error(error);
      })
    }
  }

  const subirFoto = async (usuario)=>{
    
    const accessToken = (await fetchAuthSession()).tokens?.accessToken?.toString();
    const response = await fetch(usuario.picture);
    console.log(response)
    const blob = await response.blob();

    const data = new FormData();
    data.append('file', blob);

    console.log(data, token)

    fetch(baseUrl+"User/Photo", {
      method: "post",
      headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
      },
      body: data,
    })
    .then(response=>response.json())
    .then(json=>{
        console.log(json);
    })
    .catch(error=>{
        console.error(error);
    })
  }

  const getData = async (userdata, accessToken)=>{
    // console.log("llamando getdata")
    // console.log(user);
    const result1 = await fetch(baseUrl+"User/Info",{
			method : 'get',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${accessToken}`,
			},
		}).then(response=> response.json())
		.then(json=>{
      console.log(json)
      userdata.companies = json?.data?.companies || 0;
      userdata.bookings = json?.data?.bookings || 0;
      userdata.notifications = {
        client: json?.data?.notifications?.client || 0,
        host: json?.data?.notifications?.host || 0,
      }
      userdata.posts = json?.data?.posts || 0;
      userdata.favs = json?.data?.favs;
      userdata.url = json?.data?.url || '';
      setUser({...userdata});
			return json;
		})
		.catch(error=>{
			console.log(error);
		})
  }

  return (
    <BrowserRouter basename='/'>
      <div className="contenedor">
        {iOSinstall &&
          <div className="iOSinstall" onClick={()=>{
            setIOSinstall(false);
          }}>
            <span>
              <b>Instala la app:</b> Presiona <img src={Share}/> y luego selecciona "Agregar a inicio"
            </span>
            <div className="triangulo"></div>
          </div>
        }
        {false && instalar &&
          <div onClick={handleInstallPWA} className='instalar'>
            <span>Logo</span>
            <span>Instala la app</span>
            <span className='cerrar'>
              <img src={Cerrar} onClick={(e)=>{
                e.stopPropagation();
                setInstalar(false);
              }}/>
            </span>
          </div>
        }
          <BuscadorProvider>
            <NavBar user={user} setUser={setUser} modalLogin={modalLogin} setModalLogin={setModalLogin} modalFiltro={modalFiltro} setModalFiltro={setModalFiltro} config={config} setConfig={setConfig}/>
            <Routes>
              <Route path='/' element={<Inicio modalFiltro={modalFiltro} setModalFiltro={setModalFiltro} setModalLogin={setModalLogin}/> }></Route>
              <Route path='/favoritos' element={<Inicio modalFiltro={modalFiltro} setModalFiltro={setModalFiltro} favoritos={true}/>}></Route>
              <Route path='/perfil' element={<Cuenta setUser={setUser}/>}></Route>
              <Route path='/login' element={<Login user={user} setUser={setUser} pagina={true}/>}></Route>
              <Route path='/almacen/:id' element={<Almacen user={user} setModalLogin={setModalLogin}/>}>
                <Route path='fotos' element={<Fotos/>}></Route>
                <Route path='fotos/:idPhoto' element={<Fotos/>}></Route>
              </Route>
              <Route path='/unirte' element={<Unirte user={user} modalLogin={modalLogin} setModalLogin={setModalLogin}/>}></Route>
              <Route path='/host' element={<DashFechas/>}></Route>
              <Route path='/host/gestion' element={<NavBarGestion/>}>
                <Route path='' element={<HostPersonas/>}></Route>
                <Route path='personas' element={<HostPersonas/>}></Route>
                <Route path='personas/nueva' element={<Persona/>}></Route>
                <Route path='personas/:id' element={<Persona/>}></Route>
                <Route path='personas/:idCompany/almacenes' element={<HostAlmacenes/>}></Route>
                <Route path='personas/:idCompany/almacenes/nuevo' element={<InfoAlmacen/>}></Route>
                <Route path='personas/:idCompany/almacenes/nuevo/:sel' element={<InfoAlmacen/>}></Route>
                <Route path='almacenes' element={<HostAlmacenes/>}></Route>
                <Route path='almacenes/seleccionar' element={<HostAlmacenes selectAlmacen={true}/>}></Route>
                <Route path='almacenes/nuevo' element={<InfoAlmacen/>}></Route>
                <Route path='almacenes/:id' element={<InfoAlmacen/>}></Route>
                <Route path='almacenes/nuevo/:sel' element={<InfoAlmacen/>}></Route>
                <Route path='almacenes/:id/:sel' element={<InfoAlmacen/>}></Route>
                <Route path='publicaciones/' element={<MisPublicaciones/>}></Route>
                <Route path='publicaciones/:id' element={<Publicaciones/>}></Route>
                <Route path='publicaciones/:id/nueva' element={<Publicacion/>}></Route>
                <Route path='publicaciones/:id/:idPost' element={<Publicacion/>}></Route>
              </Route>
              {/*
              <Route path='/host/publicaciones/' element={<MisPublicaciones/>}></Route>
              <Route path='/host/publicaciones/:id' element={<Publicaciones/>}></Route>
              <Route path='/host/publicaciones/:id/nueva' element={<Publicacion/>}></Route>
              <Route path='/host/publicaciones/:id/:idPost' element={<Publicacion/>}></Route>
              <Route path='/host/dashboard' element={<NavBarCuenta/>}>
              */}
              <Route path='/host/dashboard' element={<NavBarCuenta/>}>
                <Route path='' element={<DashEspacios/>}></Route>
                <Route path='rentas/espacios' element={<DashEspacios/>}></Route>
                <Route path='rentas/almacenes' element={<DashAlmacenes/>}></Route>
                <Route path='rentas/publicaciones' element={<DashPublicaciones/>}></Route>
                <Route path='rentas/ubicaciones' element={<DashUbicacion/>}></Route>
                <Route path='rentas/reservaciones' element={<DashFechas/>}></Route>
                <Route path='finanzas/' element={<Outlet/>}>
                  <Route path='' element={<DashFinanzas/>}></Route>
                  <Route path='pagos' element={<DashFinanzas/>}></Route>
                  <Route path='disputas' element={<DashFinanzas/>}></Route>
                  <Route path='transferencias' element={<DashFinanzas/>}></Route>
                </Route>
              </Route>
              <Route path='/host/rentas/solicitudes' element={<DashFechas solicitudes={true} key={'solicitudes'}/>}></Route>
              <Route path='/host/rentas/' element={<DashFechas key={'inicio'}/>}>
                {/* <Route path='' element={<DashFechas/>}/>
                <Route path='lista' element={<DashFechas/>}/>
                <Route path='calendario' element={<CalendarioRentas/>}/> */}
              </Route>
              <Route path='/host/rentas/:id' element={<ReservacionHost/>}></Route>
              <Route path='/reservar/:id' element={<Reservar user={user} setModalLogin={setModalLogin}/>}></Route>
              <Route path='/reservaciones/' element={<Reservados/>}></Route>
              <Route path='/reservaciones/:id' element={<Reservar/>}></Route>
              <Route path='/completado/:id' element={<Reservacion/>}></Route>
              <Route path='/confirmando/:id' element={<Reservacion/>}></Route>
              <Route path='/transferencia/' element={<Transferencia/>}></Route>
              <Route path='/host/notificaciones/' element={<Notificaciones user={user} setUser={setUser} tipo={'host'}/>}></Route>
              <Route path='/notificaciones/' element={<Notificaciones user={user} setUser={setUser} tipo={'client'}/>}></Route>
            </Routes>  
          </BuscadorProvider>
        <Footer/>
        <BottomBar user={user}></BottomBar>
      </div>
    </BrowserRouter>
  );
}

export default App;
