import React, { useEffect, useRef, useState } from "react";
import { updatePassword, updateUserAttribute } from 'aws-amplify/auth';
import { baseUrl } from '../../config.js'
import { formatoFechaCorta } from "../../almacen.js";
import useToken from '../../useToken';
import './Cuenta.css';

import defaultPersona from '../../img/nombre.png';
import Quitar from '../../img/Cerrar.png';
import Aprobar from "../../img/Aprobar.png";
import Cargando from "../../img/Cargando.gif";
import Ver from '../../img/ver.png';
import Ocultar from '../../img/ocultar.png';

const Cuenta = ({setUser})=>{

	const {token} = useToken();
	const [datos, setDatos] = useState({});
	const [pass, setPass] = useState('');
	const [mostrar, setMostrar] = useState(false);
	const [editar, setEditar] = useState({});
	const [float, setFloat] = useState({});
	const [disabled, setDisabled] = useState(false);
	const [foto, setFoto] = useState({});
	
	const contraseñaRef = useRef();
	const actualRef = useRef();
	const confirmarRef = useRef();
	const telefonoRef = useRef();
	const correoRef = useRef();
	const nombreRef = useRef();
	const apellidoRef = useRef();
	const fechaRef = useRef();

	const longitudMinima = new RegExp('.{8,}');
    const contieneNumero = new RegExp('\\d');
    const contieneMinuscula = new RegExp('[a-z]');
    const contieneMayuscula = new RegExp('[A-Z]');
    const contieneCaracterEspecial = new RegExp('[^a-zA-Z\\d\\s:]');
    const contador = 60;

	useEffect(()=>{
		if(token)
            getData();
	},[token])

	const getData = async ()=>{
		const result = await fetch(baseUrl+"User", {
			headers:{
				'Authorization': `Bearer ${token}`,
			}
		})
		.then(response=>response.json())
		.then(json=>{
			console.log(json);
			return json;
		})
		.catch(error=>{
			console.error(error);
		})
		if(result.success){
			setDatos(result.data);
			setFoto({url: result.data?.url})
		}
	}

	const guardarFoto = async (e, boton)=>{
		if(boton)
			e.target.disabled = true;
		const data = new FormData();
		if(foto.file)
			data.append('file', foto.file);
		fetch(baseUrl+"User/Photo", {
			method: foto.file ? "post" : "delete",
			headers: {
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
			body: data,
		})
		.then(response=>response.json())
		.then(json=>{
			// console.log(json);
			setEditar({...editar, foto: false})
			setDatos({...datos, url:json?.newUrl})
			setUser(user=>{
				return {...user, url: json?.newUrl}
			})
			return json;
		})
		.catch(error=>{
			console.error(error);
		})
	}

	const guardarDatos = async (e, boton)=>{
		if(boton)
			e.target.disabled = true;
		setFloat({estado:'guardando', mensaje:'Guardando...'})
		console.log(nombreRef.current.value.trim())
		if(nombreRef.current.value.trim() == ""){
			setFloat({estado:'error', mensaje:'Ingresa el nombre.'})
			setTimeout(()=>{
				if(boton)
					e.target.disabled = false;
				setFloat({})
			},5000)
			return false;
		}
		else if(apellidoRef.current.value.trim() == ""){
			setFloat({estado:'error', mensaje:'Ingresa el apellido.'})
			setTimeout(()=>{
				if(boton)
					e.target.disabled = false;
				setFloat({})
			},5000)
			return false;
		}
		else if(fechaRef.current.value.trim() == ""){
			setFloat({estado:'error', mensaje:'Ingresa una fecha de nacimiento.'})
			setTimeout(()=>{
				if(boton)
					e.target.disabled = false;
				setFloat({})
			},5000)
			return false;
		}

		const data = {
			name: nombreRef.current.value,
			lastname: apellidoRef.current.value,
			birthdate: fechaRef.current.value,
		}
		
		const result = await fetch(baseUrl+"User", {
			method: 'put',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
			body: JSON.stringify(data),
		})
		.then(response=>response.json())
		.then(json=>{
			console.log(json)
			setFloat({estado:'listo', mensaje:'¡Listo! Se han guardado los cambios con éxito'})
			setDatos({...datos, ...data})
			setEditar({...editar, datos: false})
			if(boton){
				e.target.disabled = false;
			}
			else{
				if(boton)
					e.target.disabled = false;
			}
			setTimeout(() => {
				setFloat({});
			}, 5000);
			return true;
		})
		.catch(error=>{
			console.error(error);
			setFloat({estado:'error', mensaje:error})
			setTimeout(()=>{
				if(boton)
					e.target.disabled = false;
				setFloat({})
			},5000)
			return false;
		})
	}

	const guardarPassword = async (e, boton)=>{
		try {
			const actual = actualRef.current.value;
			const nueva = contraseñaRef.current.value;
			const confirmar = confirmarRef.current.value;
			if(!(
				actualRef.current.reportValidity() &&
				contraseñaRef.current.reportValidity() &&
				confirmarRef.current.reportValidity()
			))
				return false;
			if(!contraseñaRef.current.reportValidity()){
				setFloat({estado:'error', mensaje:'La nueva contraseña no cumple con las condiciones.'})
				setTimeout(() => {
					setFloat({});
				}, 5000);
				return false;
			}
			if(nueva != confirmar){
				setFloat({estado:'error', mensaje:'La nueva contraseña no coincide.'})
				setTimeout(() => {
					setFloat({});
				}, 5000);
				return false;
			}
			await updatePassword({ oldPassword: actual, newPassword: nueva });
		} catch (err) {
			console.log(err);
		}
		
	}

	const guardarAtributo = async (attributeKey, e, boton)=>{
		if(boton)
			e.target.disabled = true;
		setFloat({estado:'guardando', mensaje:'Guardando...'})
		
		let value = '';

		if(attributeKey == 'phone_number'){
			if(telefonoRef.current.value.trim() == ""){
				setFloat({estado:'error', mensaje:'Ingresa el Teléfono.'})
				setTimeout(()=>{
					if(boton)
						e.target.disabled = false;
					setFloat({})
				},5000)
				return false;
			}
			value = "+52"+telefonoRef.current.value;
		}
		else{
			if(correoRef.current.value.trim() == ""){
				setFloat({estado:'error', mensaje:'Ingresa el Correo Electronico.'})
				setTimeout(()=>{
					if(boton)
						e.target.disabled = false;
					setFloat({})
				},5000)
				return false;
			}
			value = correoRef.current.value;
		}

		try {
			const output = await updateUserAttribute({
			  userAttribute: {
				attributeKey,
				value
			  }
			});
			console.log(output);
			// handleUpdateUserAttributeNextSteps(output);
		  } catch (error) {
			console.log(error);
		  }
		
		const result = await fetch(baseUrl+"User", {
			method: 'put',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
			body: JSON.stringify(datos),
		})
		.then(response=>response.json())
		.then(json=>{
			console.log(json)
			setFloat({estado:'listo', mensaje:'¡Listo! Se han guardado los cambios con éxito'})
			if(boton){
				e.target.disabled = false;
			}
			else{
				if(boton)
					e.target.disabled = false;
			}
			setTimeout(() => {
				setFloat({});
			}, 5000);
			return true;
		})
		.catch(error=>{
			console.error(error);
			setFloat({estado:'error', mensaje:error})
			setTimeout(()=>{
				if(boton)
					e.target.disabled = false;
				setFloat({})
			},5000)
			return false;
		})
	}

	const validaInput = ()=>{

	}
	

	return(
		<div className="persona">
			{float?.estado &&
				<div className={"float"+(" "+float.estado)}>
					<div className="mensaje">{float.mensaje}</div>
				</div>
			}
			<form>
				<div className="datos">
					<div className="divFoto">
						<div className="seccionH foto">
							<h3>Foto de Perfil</h3>
							<button type='button' className="editarBtn" onClick={()=>{
								if(editar?.foto)
									guardarFoto();
								else
									setEditar({...editar, foto: true})
							}}>{editar?.foto ? 'Guardar' : 'Editar'}</button>
						</div>
						<Foto foto={foto} setFoto={setFoto} editar={editar?.foto}/>
					</div>
					<div>
						<div className="seccionH">
							<h3>Datos Personales</h3>
							<button type='button' className="editarBtn" onClick={()=>{
								if(editar?.datos)
									guardarDatos()
								else
									setEditar({...editar, datos: true})
							}}>{editar?.datos ? 'Guardar' : 'Editar'}</button>
						</div>
						{editar?.datos &&
							<div>
								<label>
									<input type='text' ref={nombreRef} placeholder='' defaultValue={datos?.name}  onChange={validaInput} minLength={1} required/>
									<span>Nombre(s)</span>
								</label>
								<label>
									<input type='text' ref={apellidoRef} placeholder='' defaultValue={datos?.lastname}  onChange={validaInput} minLength={1} required/>
									<span>Apellido(s)</span>
								</label>
								<label>
									<input type='date' ref={fechaRef} placeholder='' defaultValue={datos?.birthdate}  onChange={validaInput} required/>
									<span>Fecha de Nacimiento</span>
								</label>
							</div>
						}
						{!editar?.datos &&
							<>
								<div className="infoDato">
									<span>Nombre(s)</span>
									<span>{datos?.name || '---'}</span>
								</div>
								<div className="infoDato">
									<span>Apellido(s)</span>
									<span>{datos?.lastname || '---'}</span>
								</div>
								<div className="infoDato">
									<span>Fecha de Nacimiento</span>
									<span>{datos?.birthdate ? formatoFechaCorta(new Date(datos?.birthdate.split("-"))) : '---'}</span>
								</div>
							</>
						}
						<div className="seccionH">
							<h3>Contraseña</h3>
							<button type='button' className="editarBtn" onClick={()=>{
								if(editar?.contraseña)
									guardarPassword()
								else
									setEditar({...editar, contraseña: true})
							}}>{editar?.contraseña ? 'Guardar' : 'Editar'}</button>
						</div>
						{editar?.contraseña &&
							<div>
								<label>
									<input type='password' ref={actualRef} placeholder='' onChange={validaInput} minLength={1} required disabled={disabled}/>
									<span>Contraseña Actual</span>
								</label>
								<label className="lblPass">
									<input type={mostrar ? 'text' : 'password'} 
										ref={contraseñaRef}
										placeholder='' 
										minLength={1} 
										pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s:]).{8,}$" 
										onChange={(e)=>{
											setPass(e.target.value)
											validaInput(e)
										}}
										required/>
									<span>Nueva Contraseña</span>
									<div className="reqPass">
										<div><b>La contraseña debe incluir</b></div>
										<div>
											<span className="check">
												{longitudMinima.test(pass) &&
													<img src={Aprobar}/>
												}
											</span>
											<span>Al menos <b>8 caracteres</b></span>
										</div>
										<div>
											<span className="check">
												{contieneNumero.test(pass) &&
													<img src={Aprobar}/>
												}
											</span>
											<span>Al menos <b>1 número</b> </span>
										</div>
										<div>
											<span className="check">
												{contieneMinuscula.test(pass) &&
													<img src={Aprobar}/>
												}
											</span>
											<span>Al menos una <b>letra minúscula</b></span>
										</div>
										<div>
											<span className="check">
												{contieneMayuscula.test(pass) &&
													<img src={Aprobar}/>
												}
											</span>
											<span>Al menos una <b>letra mayúscula</b></span>
										</div>
										<div>
											<span className="check">
												{contieneCaracterEspecial.test(pass) &&
													<img src={Aprobar}/>
												}
											</span>
											<span>Al menos <b>1 carácter especial</b> del siguiente conjunto.</span>
										</div>
										<div>{`^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ \` + =`}</div>
									</div>
									<button className="mostrarPass" tabIndex={-1} type="button" onClick={(e)=>{
										setMostrar(!mostrar);
									}}>
										<img src={mostrar ? Ocultar : Ver}/>
									</button>
								</label>
								<label>
									<input type='password' ref={confirmarRef} placeholder='' onChange={validaInput} minLength={1} required disabled={disabled}/>
									<span>Confirmar Nueva Contraseña</span>
								</label>
							</div>
						}
						{!editar?.contraseña &&
							<div className="infoDato">
								<span>Contraseña</span>
								<span>{'*********'}</span>
							</div>
						}
						<div className="seccionH">
							<h3>Teléfono</h3>
							<button type='button' className="editarBtn" onClick={(e)=>{
								if(editar?.telefono)
									guardarAtributo('phone_number', e)
								else
									setEditar({...editar, telefono: true})
							}}>{editar?.telefono ? 'Guardar' : 'Editar'}</button>
						</div>
						{editar?.telefono &&
							<div>
								<label>
									<input type='tel' ref={telefonoRef} placeholder='' defaultValue={datos?.cellphone}  onChange={validaInput} minLength={1} required disabled={disabled}/>
									<span>Teléfono</span>
								</label>
							</div>
						}
						{!editar?.telefono &&
							<div className="infoDato">
								<span>Teléfono</span>
								<span>{datos?.cellphone || '---'}</span>
							</div>
						}
						<div className="seccionH">
							<h3>Correo</h3>
							<button type='button' className="editarBtn" onClick={(e)=>{
								if(editar?.correo)
									guardarAtributo('email', e)
								else
									setEditar({...editar, correo: true})
							}}>{editar?.correo ? 'Guardar' : 'Editar'}</button>
						</div>
						{editar?.correo &&
							<div>
								<label>
									<input type='email' ref={correoRef} placeholder='' defaultValue={datos?.email}  onChange={validaInput} required disabled={disabled}/>
									<span>Correo</span>
								</label>
							</div>
						}
						{!editar?.correo &&
							<div className="infoDato">
								<span>Correo</span>
								<span>{datos?.email || '---'}</span>
							</div>
						}
					</div>
				</div>
			</form>
		</div>
	);
}

const Foto = ({foto, setFoto, editar})=>{
	const {token} = useToken();
    const [error, setError] = useState({});
	const input = useRef();

	const selecciona = ()=>{
        input.current.click();
    }
    const cambiaEstado = (e)=>{
		if(input.current.files[0].size/(1024*1024) > 25){
			setError({error:true, descripcion: "El documento no debe ser mayor a 25MB"});
			input.current.value = "";
			return false;
		}
        if(input.current.files[0]){
			setError({});
        }
		const aux = [];
		const reader = new FileReader();
		reader.readAsDataURL(e.target.files[0]);
		reader.onload = () => {
			setFoto({url: String(reader.result), file: e.target.files[0]});
		};
    }

	function dragstart(e) {
        e.preventDefault();
        e.stopPropagation();
    }
    function drag(e) {
        e.preventDefault();
        e.stopPropagation();
    }
    function dragover(e) {
        e.currentTarget.classList.add("hov");
        e.preventDefault();
        e.stopPropagation();
    }
    function dragenter(e) {
        e.currentTarget.classList.add("hov");
        e.preventDefault();
        e.stopPropagation();
    }
    function dragend(e) {
        e.currentTarget.classList.remove("hov");
        e.preventDefault();
        e.stopPropagation();
    }
    function dragleave(e) {
        e.currentTarget.classList.remove("hov");
        e.preventDefault();
        e.stopPropagation();
    }
    function drop(e) {
        e.currentTarget.classList.remove("hov");
        e.preventDefault();
        e.stopPropagation();
        input.current.files = e.dataTransfer.files;
		const event = new Event('change', { bubbles: true });
		event.simulated = true;
		input.current.dispatchEvent(event);
    }
	function eliminar(){
		setFoto({});
	}
	if(!editar){
		return(
			<div className="ver perfil">
				<img src={foto?.url || defaultPersona}/>
			</div>
		)
	}
    return(
		<>
			{!foto.url &&
				<div onClick={selecciona} className={'subir fotos perfil'}
					onDragStart = {dragstart}
					onDrag = {drag}
					onDragEnd = {dragend}
					onDragOver = {dragover}
					onDragEnter = {dragenter}
					onDragLeave = {dragleave}
					onDrop = {drop}
					>
					{error.error &&
						<div className="error">{error.descripcion}</div>
					}
					<div className="arrastra">
						<div className='lblSel'>Selecciona o arrastra el archivo</div>
						<input type='file' ref={input} accept='image/*' onChange={cambiaEstado} multiple={true}/>
					</div>
				</div>
			}
			{foto?.url &&
				<div className="ver perfil locked" onClick={selecciona}
					onDragStart = {dragstart}
					onDrag = {drag}
					onDragEnd = {dragend}
					onDragOver = {dragover}
					onDragEnter = {dragenter}
					onDragLeave = {dragleave}
					onDrop = {drop}
					>
					<div className="cambiarFoto">
						<div>Cambiar Foto</div>
					</div>
					<img src={foto?.url || defaultPersona}/>
					<button className="eliminarFoto" onClick={(e)=>{
						e.preventDefault();
						e.stopPropagation();
						eliminar()
					}}>
						<img src={Quitar}/>
					</button>
					<input type='file' ref={input} accept='image/*' onChange={cambiaEstado} multiple={true}/>
				</div>
			}
		</>
    )
}

export default Cuenta;