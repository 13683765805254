import React from "react";
import { baseUrl } from '../../config.js'
import { Link } from "react-router-dom";
import './Footer.css';

import Facebook from '../../img/FacebookSoc.png';
import Twitter from '../../img/Twitter.png';
import Instagram from '../../img/Instagram.png';
import Tiktok from '../../img/Tiktok.png';
import Whatsapp from '../../img/Whatsapp.png';

const Footer = ()=>{
    const year = (new Date()).getFullYear();
    return(
        <div className="footer">
            <div className="txtFooter">
                <div>
                    <h3>¡Bienvenido a la comunidad de alquiler de almacenes!</h3>
                    <div>Más de 1.05 millones de empresas como la tuya se han unido a nuestra comunidad para compartir más de 692 mil opciones de almacenes industriales reales, generando 11.09 millones de comentarios entre personas que comparten sus conocimientos, experiencias y consejos sobre la renta de almacenes.</div>
                    <div>¡Únete a nosotros y descubre la mejor opción para tu negocio!</div>
                </div>
                <div className="seguir">
                    <div>Síguenos</div>
                    <div>
                        <Link>
                            <img src={Facebook}/>
                        </Link>
                        <Link>
                            <img src={Twitter}/>
                        </Link>
                        <Link>
                            <img src={Instagram}/>
                        </Link>
                        <Link>
                            <img src={Tiktok}/>
                        </Link>
                        <Link>
                            <img src={Whatsapp}/>
                        </Link>
                    </div>
                </div>
            </div>
            <hr/>
            <div className="mapaFooter">
                <div>
                    <b>Nuestra Compañía</b>
                    <Link to='/about'>Acerca de Nosotros</Link>
                    <Link to=''>Blog</Link>
                    <Link to=''>Carreras</Link>
                    <Link to=''>Prensa</Link>
                </div>
                <div>
                    <b>Explorar</b>
                    <Link to=''>Cómo Funciona</Link>
                    <Link to=''>Almacenes Populares</Link>
                    <Link to=''>Ciudades</Link>
                    <Link to=''>Categorías</Link>
                </div>
                <div>
                    <b>Anfitriones</b>
                    <Link to=''>Listar tu Almacén</Link>
                    <Link to=''>Responsabilidades del Anfitrión</Link>
                    <Link to=''>Recursos para Anfitriones</Link>
                </div>
                <div>
                    <b>Legal</b>
                    <Link to=''>Términos y Condiciones</Link>
                    <Link to=''>Política de Privacidad</Link>
                    <Link to=''>Política de Cookies</Link>
                </div>
                <div>
                    <b>Contacto</b>
                    <Link to=''>Atención al Cliente</Link>
                    <Link to=''>FAQs</Link>
                </div>
            </div>
            <hr/>
            <div className="barraFooter">© {year} PalletCloud, Inc.</div>
        </div>
    )
}

export default Footer;