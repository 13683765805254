import React, {useState, useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import { baseUrl } from '../../config.js'
import useToken from '../../useToken';
import { formatoFechaCorta, formatoHora } from '../../almacen.js';

import './Notificaciones.css';

const Notificaciones = ({user, setUser, tipo = 'client'})=>{
    const {token} = useToken();
    const [datos, setDatos] = useState([]);
    
    useEffect(()=>{
        if(token)
            getData();
    }, [token])

    const getData = ()=>{
        // fetch data almacenes
        fetch(baseUrl+"Notifications/"+tipo, {
            method: "get",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
            },
        })
        .then(response=>response.json())
        .then(json=>{
            console.log(json);
            if(json.success){
                const aux = {};
                json.data.forEach(notificacion => {
                    if(!aux[notificacion.onlyDate]){
                        aux[notificacion.onlyDate] = [];
                    }
                    aux[notificacion.onlyDate].push(notificacion);
                });
                setDatos(aux);
                console.log(user, user.notifications);
                user.notifications[tipo] = 0;
                setUser({...user});
            }
        })
        .catch(error=>{
            console.error(error);
        })
        
    }
    
    return(
        <div className="notificaciones">
            {Object.keys(datos).length === 0 &&
                <div>No hay notificaciones</div>
            }
            {Object.keys(datos).map(fecha=>{
                return (<Dia key={fecha} notificaciones={datos[fecha]} fecha={fecha}/>)
            })
            }
        </div>
    )
}

const Dia = ({notificaciones, fecha})=>{
    return(
        <div className="grupo">
            <h4>{formatoFechaCorta(new Date(fecha.split("T")[0].split("-")))}</h4>
            {notificaciones.map(notificacion =>{
                return(
                    <Notificacion key={notificacion.idNotification} notification={notificacion}/>
                )
            })}
        </div>
    )
}

const Notificacion = ({notification})=>{

    const [notificacion, setNotificacion] = useState(notification);

    const {token} = useToken();

    const handleClick = (e)=>{
        e.preventDefault();
        
        if(notificacion.link){
            fetch(baseUrl+'Notifications/visit/'+notificacion.idNotification, {
                method: "put",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            })
            .then(response=>response.json())
            .then(json=>{
                notificacion.status = 'visited';
                setNotificacion({...notificacion});
                if(e.ctrlKey){
                    window.open(notificacion.link, '_blank');
                }
                else{
                    window.location.href = notificacion.link;
                }
            })
            .catch((err)=>{
                console.log(err);
            })
        }
    }
    
    return(
        <Link to={notificacion.link || '#'} className={notificacion.status} onClick={handleClick}>
            <div className="title">
                <span><b>{notificacion.title}</b></span>
                <span>{formatoHora(new Date(notificacion.date))}</span>
            </div>
            <div>
                {notificacion.message}
            </div>
        </Link>
    )
}

export default Notificaciones;