import React, { createContext, useState } from 'react';

export const BuscadorContext = createContext();

export const BuscadorProvider = ({ children }) => {
  const [filtros, setFiltros] = useState({});
  const [totalFavs, setTotalFavs] = useState(0);
  const [totalReservaciones, setTotalReservaciones] = useState(0);
  const [maximo, setMaximo] = useState(0);
  const [precios, setPrecios] = useState(0);
  return (
    <BuscadorContext.Provider value={{ filtros, setFiltros, totalFavs, setTotalFavs, totalReservaciones, setTotalReservaciones, maximo, setMaximo, precios, setPrecios }}>
      {children}
    </BuscadorContext.Provider>
  );
};

