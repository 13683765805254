import React, { useState, useRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { baseUrl } from '../../config.js'
import { Chart } from "react-google-charts";

import './Dashboards.css';

import useToken from "../../useToken.js";
import { formatoFecha, formatoMoneda, formatoHora, Meses } from '../../almacen.js';

const DashAlmacenes = ({})=>{

    const {token} = useToken();
    const [stores, setStores] = useState({});

    useEffect(()=>{
        if(token){
            getData();
        }
    }, [token])
    
    const getData = ()=>{
        fetch(baseUrl+'Info/Stores',{
            headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
        })
        .then(response=>response.json())
		.then(json=>{
            console.log(json);
            setStores({spaces : json?.data?.spaces})
		})
		.catch(error=>{
			console.error(error);
		})
    }
    

    return(
        <div className="dashEspacios dashAlmacen">
            <div className="graficaEspacios">
                <div className="etiqueta">
                    <h4>Espacios ocupados</h4>
                    <GraficaEspacios/>
                </div>
            </div>
            <div className="movimientos">
                <div className="etiqueta">
                    <div>
                        <h3>Movimientos recientes</h3>
                        <Movimientos/>
                    </div>
                </div>
            </div>

        </div>
    )
}

const GraficaTotales = ({spaces})=>{

    const [data, setData] = useState([])

    const options={
        legend: {
            position: 'bottom'
        },
        chartArea: {'width': '90%', 'height': '80%'},
        pieHole: 0.4,
    }

    useEffect(()=>{
        console.log(spaces)
        const aux =[];
        aux.push(['Espacios', 'Total'])
        spaces.forEach(x=>{
            console.log(x);
            aux.push([x.name, x.bookedSpaces*1])
        })
        setData(aux);
    },[spaces])

    return(
        <Chart
            chartType="PieChart"
            data={data}
            options={options}
            width="100%"
            height="100%"
            legendToggle
        />
    )
}

const GraficaEspacios = ({})=>{
    
    const {token} = useToken();
    const [tiempo, setTiempo] = useState('Month');
    const [verTotal, setVerTotal] = useState(false);
    const [data, setData] = useState([]);
    const [fecha, setFecha] = useState(new Date());

    const options ={
        legend: 'bottom',
        chartArea: {'width': '90%'},
        isStacked: true,
        hAxis : { 
            format: '0',
            slantedText: false,
            maxAlternation: 1,
            textStyle : {
                fontSize: 11
            }
    
        },
    }

    useEffect(()=>{
        if(token){
            getData();
        }
    },[token, fecha, tiempo])

    const getData = ()=>{
        const body = {time : tiempo, date: fecha};
        fetch(baseUrl+'Info/PostsTime/',{
            method: 'post',
            headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
            body: JSON.stringify(body)
        })
        .then(response=>response.json())
		.then(json=>{
			console.log(json);
            setData(formatData(json?.data?.bookings))
		})
		.catch(error=>{
			console.error(error);
		})
    }
    const formatData = (events)=>{
        let aux = [["Fecha"]];
        events.forEach((x,i)=>{
            if(aux[0].indexOf(x.postName) == -1)
                aux[0].push(x.postName)
        })
        console.log(aux[0]);
        if(tiempo == 'Month'){
            const auxFecha = new Date(fecha.getFullYear(), fecha.getMonth()+1, 0);
            const diasMes = auxFecha.getDate();
            const auxContador = {};
            Array(diasMes).fill(0).map((x,i)=>{
                const dia = new Date(fecha.getFullYear(), fecha.getMonth(), i+1);
                if(!aux[i+1])
                    aux[i+1] = [dia.getDate()]
                auxContador[i+1] = 0;
                events.forEach(event=>{
                    const indice = aux[0].indexOf(event.postName);
                    // console.log(i, indice, aux[i+1])
                    const inicio = new Date(event.startDate.split("T")[0].split("-"))
                    inicio.setHours(0,0,0,0)
                    const fin = new Date(event.endDate.split("T")[0].split("-"))
                    fin.setHours(0,0,0,0)
                    if(dia >= inicio && dia <= fin){
                        auxContador[i+1] += event.spaces*1;
                        if(indice == -1)
                            aux[i+1].push(event.spaces*1);
                        else{
                            if(!aux[i+1][indice]){
                                aux[i+1][indice] = 0;
                            }
                            aux[i+1][indice] += event.spaces*1;
                        }
                    }
                    else{
                        console.log(i+1, indice);
                        if(indice == -1)
                            aux[i+1].push(0);
                        else if(!aux[i+1][indice]){
                            aux[i+1][indice] = 0;
                        }
                    }
                })
            })
            if(verTotal){
                aux = [["Fecha"]]
                aux[0].push("Total")
                for(let dia in auxContador){
                    aux[dia] = [dia]
                    aux[dia].push(auxContador[dia])
                }
            }
        }
        else if(tiempo == "Year"){
            aux.push(["Mes", "Espacios Ocupados"])
            
            const auxContador = {};
            Array(12).fill(0).map((x,i)=>{
                const inicioMes = new Date(fecha.getFullYear(), i, 1);
                const finMes = new Date(fecha.getFullYear(), i+1, 0);
                auxContador[i] = 0;
                events.forEach(event=>{
                    const inicio = new Date(event.startDate.split("T")[0].split("-"))
                    inicio.setHours(0,0,0,0)
                    const fin = new Date(event.endDate.split("T")[0].split("-"))
                    fin.setDate(fin.getDate()+1)
                    fin.setHours(0,0,0,0)
                    if(inicio <= finMes && fin >= inicioMes){
                        auxContador[i] += event.stores*1;
                    }
                })
            })
            for(let mes in auxContador){
                aux.push([Meses[mes], auxContador[mes]])
            }
        }
        else{
            aux.push(["Mes", "Espacios Ocupados"])
            const startDates = events.map(event=>{
                return new Date(event.startDate);
            })
            const endDates = events.map(event=>{
                return new Date(event.endDate);
            })
            const minDate = new Date(Math.min(...startDates));
            const maxDate = new Date(Math.max(...endDates));
            console.log(minDate, maxDate);
            
            const auxContador = {};
            const auxFecha = new Date(minDate.getTime());
            auxFecha.setDate(1);
            while(auxFecha <= maxDate){
                const inicioMes = new Date(auxFecha.getFullYear(), auxFecha.getMonth(), 1);
                const finMes = new Date(auxFecha.getFullYear(), auxFecha.getMonth()+1, 0);
                auxContador[(inicioMes.getMonth())+"-"+inicioMes.getFullYear()] = 0;
                events.forEach(event=>{
                    const inicio = new Date(event.startDate.split("T")[0].split("-"))
                    inicio.setHours(0,0,0,0)
                    const fin = new Date(event.endDate.split("T")[0].split("-"))
                    fin.setDate(fin.getDate()+1)
                    fin.setHours(0,0,0,0)
                    if(inicio <= finMes && fin >= inicioMes){
                        auxContador[(inicioMes.getMonth())+"-"+inicioMes.getFullYear()] += event.stores*1;
                    }
                })
                auxFecha.setMonth(auxFecha.getMonth()+1);
            }
            console.log(auxContador);
            for(let mes in auxContador){
                aux.push([Meses[mes.split("-")[0]]+"-"+mes.split("-")[1], auxContador[mes]])
            }
        }
        console.log(aux);
        return aux;
    }
    
    return(
        <div className="grafica">
            <div className="tabs">
                <button className={tiempo == 'Month' ? 'sel' : ''}
                    onClick={()=>{
                        setTiempo('Month');
                    }}
                >Mes</button>
                <button className={tiempo == 'Year' ? 'sel' : ''}
                    onClick={()=>{
                        setTiempo('Year');
                    }}
                >Año</button>
                <button className={tiempo == 'All' ? 'sel' : ''}
                    onClick={()=>{
                        setTiempo('All');
                    }}
                >Todo</button>
            </div>
            {tiempo != 'All' &&
                <div className="tipos">
                    <button onClick={()=>{
                        const auxFecha = new Date(fecha.getTime())
                        auxFecha.setFullYear(auxFecha.getFullYear()-1)
                        setFecha(auxFecha);
                    }}>{"<<"}</button>
                    
                    {tiempo == "Month" &&
                        <button onClick={()=>{
                            const auxFecha = new Date(fecha.getTime())
                            auxFecha.setMonth(auxFecha.getMonth()-1)
                            setFecha(auxFecha);
                        }}>{"<"}</button>
                    }
                    <span className="actual">
                        {tiempo == "Month" &&
                            <div>{Meses[fecha.getMonth()]}</div>
                        }
                        <div>{fecha.getFullYear()}</div>
                    </span>
                    {tiempo == "Month" &&
                        <button onClick={()=>{
                            const auxFecha = new Date(fecha.getTime())
                            auxFecha.setMonth(auxFecha.getMonth()+1)
                            setFecha(auxFecha);
                        }}>{">"}</button>
                    }
                    <button onClick={()=>{
                        const auxFecha = new Date(fecha.getTime())
                        auxFecha.setFullYear(auxFecha.getFullYear()+1)
                        setFecha(auxFecha);
                    }}>{">>"}</button>
                </div>
            }
            <div className={"contGrafica"+(data.length === 0 ? " placeholder" : "")}>
                {data.length === 0 &&
                    <div className="informacion placeGrafica"></div>
                }
                {data.length !== 0 &&
                    <Chart
                        chartType="SteppedAreaChart"
                        data={data}
                        options={options}
                        width="100%"
                        height="100%"
                        legendToggle
                    />
                }
            </div>
        </div>
    )
}

const TablaMejores = ()=>{

    const { token } = useToken();
    const [tiempo, setTiempo] = useState('Month');
    const [data, setData] = useState([]);
    const [fecha, setFecha] = useState(new Date())

    useEffect(()=>{
        if(token){
            getData();
        }
    },[token])

    const getData = ()=>{
        const body = {time : tiempo, date: fecha};
        fetch(baseUrl+'Info/BestStores',{
            method: 'post',
            headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
            body: JSON.stringify(body)
        })
        .then(response=>response.json())
		.then(json=>{
			console.log(json);
            setData(json?.data)
		})
		.catch(error=>{
			console.error(error);
		})
    }
    
    return(
        <table className="tabla mejores">
        <tbody>
            {data.length !== 0 &&
                <tr>
                    <th>Almacén</th>
                    <th>Ocupados</th>
                    <th>Total</th>
                    <th>%</th>
                </tr>
            }
            {data.map((fila, i)=>{
                return(
                    <tr key={i}>
                        <td>{fila.name}</td>
                        <td>{fila.stores}</td>
                        <td>{fila.availableSpace}</td>
                        <td>{Math.round(fila.stores*10000/fila.availableSpace)/100}%</td>
                    </tr>
                )
            })
            }
        </tbody>
        </table>
    )
}
const Movimientos = ({})=>{

    const { token } = useToken();
    const [tiempo, setTiempo] = useState('Month');
    const [data, setData] = useState([]);
    const [fecha, setFecha] = useState(new Date())

    useEffect(()=>{
        if(token){
            getData();
        }
    },[token])

    const getData = ()=>{
        const body = {time : tiempo, date: fecha};
        fetch(baseUrl+'Info/RecentBookings',{
            method: 'post',
            headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
            body: JSON.stringify(body)
        })
        .then(response=>response.json())
		.then(json=>{
			console.log(json);
            setData(json?.data || [])
		})
		.catch(error=>{
			console.error(error);
		})
    }
    
    return(
        <table className="tabla recientes">
        <tbody>
            {data?.length !== 0 &&
                <>
                    <tr className="header">
                        <th rowSpan={2}>Operación</th>
                        <th rowSpan={2}>Almacén</th>
                        <th rowSpan={2}>Publicación</th>
                        <th rowSpan={2}>Transacción</th>
                        <th colSpan={3}>Espacios</th>
                        <th colSpan={2}>Fechas</th>
                        <th rowSpan={2}>Ingreso</th>
                    </tr>
                    <tr className="header subheader">
                        <th>Ocupados</th>
                        <th>
                            <div>Total</div><div>Publicación</div>
                        </th>
                        <th>
                            <div>Total</div><div>Almacen</div>
                        </th>
                        <th>Inicio</th>
                        <th>Fin</th>
                    </tr>
                </>
            }
            {data?.length === 0 &&
                <tr>
                    <td>No hay movimientos recientes</td>
                </tr>
            }
            {data?.map((fila, i)=>{
                let auxFecha = formatoFecha(new Date(fila.mainDate.split("T")[0].split("-")));
                if(auxFecha == formatoFecha(new Date())){
                    auxFecha = "Hoy";
                }

                return(
                    <tr key={i}>
                        <td>
                            <div>{auxFecha}</div>
                        </td>
                        <td>
                            <Link to={'/host/almacenes/'+fila.idStore}>{fila.name}</Link>
                        </td>
                        <td>
                            <Link to={'/host/publicaciones/'+fila.idStore+'/'+fila.idPost}>{fila.postName}</Link>
                        </td>
                        <td className={fila.transaction < 0 ? "menos" : "mas"}>{(fila.transaction < 0 ? "" : "+")+fila.transaction}</td>
                        <td>{fila.occupied}</td>
                        <td>{fila.assignedSpaces}</td>
                        <td>{fila.availableSpace}</td>
                        <td>{formatoFecha(new Date(fila.startDate.split("T")[0].split("-")))}</td>
                        <td>{formatoFecha(new Date(fila.endDate.split("T")[0].split("-")))}</td>
                        <td>${formatoMoneda(fila.transaction < 0 ? "" : fila.price*1)}</td>
                    </tr>
                )
            })
            }
        </tbody>
        </table>
    )
}

const MapaEspacios = ()=>{
    const data = [
        ['States', 'Estado', 'Espacios'],
        ['MX-AGU', 'Aguascalientes', 0],
        ['MX-BCN', 'Baja California', 0],
        ['MX-BCS', 'Baja California Sur', 0],
        ['MX-CAM', 'Campeche', 0],
        ['MX-DIF', 'Ciudad de México', 100],
        ['MX-COA', 'Coahuila', 0],
        ['MX-COL', 'Colima', 0],
        ['MX-CHP', 'Chiapas', 0],
        ['MX-CHH', 'Chihuahua', 0],
        ['MX-DUR', 'Durango', 0],
        ['MX-GUA', 'Guanajuato', 0],
        ['MX-GRO', 'Guerrero', 0],
        ['MX-HID', 'Hidalgo', 0],
        ['MX-JAL', 'Jalisco', 0],
        ['MX-MEX', 'México', 50],
        ['MX-MIC', 'Michoacán', 0],
        ['MX-MOR', 'Morelos', 0],
        ['MX-NAY', 'Nayarit', 0],
        ['MX-NLE', 'Nuevo León', 0],
        ['MX-OAX', 'Oaxaca', 0],
        ['MX-PUE', 'Puebla', 0],
        ['MX-QUE', 'Querétaro', 0],
        ['MX-ROO', 'Quintana Roo', 0],
        ['MX-SLP', 'San Luis Potosí', 0],
        ['MX-SIN', 'Sinaloa', 0],
        ['MX-SON', 'Sonora', 0],
        ['MX-TAB', 'Tabasco', 0],
        ['MX-TAM', 'Tamaulipas', 0],
        ['MX-TLA', 'Tlaxcala', 0],
        ['MX-VER', 'Veracruz', 0],
        ['MX-YUC', 'Yucatán',  0],
        ['MX-ZAC', 'Zacatecas', 0],
      ];
      const options = {
        region: "MX", // Africa
        displayMode: "regions",
        resolution: "provinces"
      };
    return(
        <div>
            <Chart
                chartType="ScatterChart"
                data={[["Age", "Weight"], [4, 5.5], [8, 12]]}
                width="100%"
                height="400px"
                legendToggle
            />
            <Chart
                chartEvents={[
                    {
                    eventName: "select",
                    callback: ({ chartWrapper }) => {
                        const chart = chartWrapper.getChart();
                        const selection = chart.getSelection();
                        if (selection.length === 0) return;
                        const region = data[selection[0].row + 1];
                        console.log("Selected : " + region[0]);
                    },
                    },
                ]}
                chartType="GeoChart"
                width="100%"
                height="400px"
                data={data}
                options={options}
                />
        </div>
    )
}

export default DashAlmacenes;