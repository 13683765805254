import React, { useEffect, useRef, useState } from "react";
import { baseUrl } from '../../config.js'
import useToken from "../../useToken.js";

const Transferencia = ({})=>{
    
    const {token} = useToken();
    const [customerId, setCustomerId] = useState('');
    const [transferencias, setTransferencias] = useState([]);
    const [cargando, setCargando] = useState(false);
    const reference = useRef();
    const amount = useRef();

    useEffect(()=>{
        if(token)
            getData();
    }, [token])

    const getData = ()=>{
        fetch(baseUrl+'Customer', {
            method:'get',
            headers:{
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			}
        }).then(response=>response.json())
        .then(json=>{
            console.log(json)
            setCustomerId(json?.customerId);
        })
        .catch(error=>{
            console.log(error)
        })
    }

    const submit = ()=>{
        if(cargando)
            return false;
        setCargando(true);
        let data = "amount="+(amount.current.value*100)+"&currency=mxn";
        if(reference.current.value){
            data = "reference="+(reference.current.value)+"&amount="+(amount.current.value*100)+"&currency=mxn";
        }
        console.log(data);
        fetch("https://api.stripe.com/v1/test_helpers/customers/"+customerId+"/fund_cash_balance", {
            body: data,
            headers: {
              Authorization: "Bearer sk_test_7vC2z9qYa2PErdknLMlIWDEY00P8T22g9i",
              "Content-Type": "application/x-www-form-urlencoded"
            },
            method: "POST"
        }).then(response=>response.json())
        .then(json=>{
            transferencias.push(amount.current.value);
            setTransferencias([...transferencias])
            setCargando(false);
        })
        .catch(error=>{
            console.log(error)
            setCargando(false);
        })
    }
    const handleSubmit = (e)=>{
        e.preventDefault();
        submit();
    }
    return(
      <div className="booking">
        <form onSubmit={handleSubmit}>
            <div>
                <i>La Transferencia mínima es de $10.00MXN</i>
            </div>
            <label>
                <input type="number" ref={amount}/>
                <span>Cantidad</span>
            </label>
            <label>
                <input type="number" ref={reference}/>
                <span>Referencia</span>
            </label>
            <button disabled={cargando} className="btn">{cargando ? 'Depositando...' : 'Depositar'}</button>
        </form>
        {transferencias?.map(transferencia=>{
            return(
                <div>
                    Transferencia completa por ${transferencia} MXN
                </div>
            )
        })

        }
      </div>
    )
}

export default Transferencia;