import React, { useContext, useEffect, useRef, useState } from "react";
import { baseUrl } from '../../config.js'
import { Imagenes, idServiciosRC, idsRC, nombres } from "../../almacen.js";
import { BuscadorContext } from "../Buscador/BuscadorContext.js";
import Modal from "../Modal/Modal";
import './Filtros.css';

import Flecha from '../../img/Flecha.png';
import PriceUp from '../../img/PriceUp.png';
import PriceDown from '../../img/PriceDown.png';
import EspacioUp from '../../img/EspacioUp.png';
import EspacioDown from '../../img/EspacioDown.png';
import TimeUp from '../../img/TimeUp.png';
import TimeDown from '../../img/TimeDown.png';

const Filtros = ({ setModalFiltro })=>{
    const {filtros, setFiltros, maximo, precios} = useContext(BuscadorContext);
    const [scrollEnd, setScrollEnd] = useState(false);
    const [scrollStart, setScrollStart] = useState(true);
    const [sel, setSel] = useState(filtros?.order || "Best");
    const [selPeriodo, setSelPeriodo] = useState(filtros?.time || "3");
    const [min, setMin] = useState(filtros?.min || 0);
    const [max, setMax] = useState(filtros?.max || maximo["3"]*30);
    const [seleccionados, setSeleccionados] = useState({types:[...(filtros?.types ||[])], services:[...(filtros?.services || [])]});
    const contFiltros = useRef();
    const multiplica = {
        "1" : 1,
        "2" : 7,
        "3" : 30,
        "4" : 365,
    }

    return(
        <Modal className='divFiltros' titulo={'Filtros'} setModal={setModalFiltro}>
            <div className="contFiltros" ref={contFiltros} onScroll={(e)=>{
                const scroll = Math.floor(e.target.scrollHeight - e.target.offsetHeight);
                if(Math.floor(e.target.scrollTop) <= 0){
                    setScrollEnd(false);
                    setScrollStart(true);
                }
                else if(Math.ceil(e.target.scrollTop) >= scroll){
                    setScrollEnd(true);
                    setScrollStart(false);
                }
                else{
                    if(!scrollStart){
                        setScrollEnd(true);
                        setScrollStart(false);
                    }
                    else{
                        setScrollEnd(false);
                        setScrollStart(true);                        
                    }
                }
            }}>
                <div className="orderBy">
                    <h3>Ordenar Por</h3>
                    <div className="botonesFiltro">
                        <button onClick={()=>{
                            setSel("Best");
                        }} className={sel == "Best" ? "sel" : ""}>
                            <img src={PriceDown}/>
                            <span>Mejores Calificados</span>
                        </button>
                        <button onClick={()=>{
                            setSel("Recent");
                        }} className={sel == "Recent" ? "sel" : ""}>
                            <img src={TimeDown}/>
                            <span>Recientes</span>
                        </button>
                        <button onClick={()=>{
                            setSel("Oldest");
                        }} className={sel == "Oldest" ? "sel" : ""}>
                            <img src={TimeUp}/>
                            <span>Antiguos</span>
                        </button>
                        <button onClick={()=>{
                            setSel("MoreAvailability");
                        }} className={sel == "MoreAvailability" ? "sel" : ""}>
                            <img src={EspacioDown}/>
                            <span>Más Espacios</span>
                        </button>
                        <button onClick={()=>{
                            setSel("LessAvailability");
                        }} className={sel == "LessAvailability" ? "sel" : ""}>
                            <img src={EspacioUp}/>
                            <span>Menos Espacios</span>
                        </button>
                        <button onClick={()=>{
                            setSel("PriceDown");
                        }} className={sel == "PriceDown" ? "sel" : ""}>
                            <img src={PriceDown}/>
                            <span>Mayor Costo</span>
                        </button>
                        <button onClick={()=>{
                            setSel("PriceUp");
                        }} className={sel == "PriceUp" ? "sel" : ""}>
                            <img src={PriceUp}/>
                            <span>Menor Costo</span>
                        </button>
                    </div>
                </div>
                <div className="filtrarPrecio" onDragOver={(e)=>{
                    e.preventDefault();
                    e.stopPropagation();
                    e.dataTransfer.dropEffect = 'move';
                }}>
                    <h3>Rango de precios</h3>
                    <div className="contPeriodos">
                        <div className="selPeriodo">
                            <button className={selPeriodo == "1" ? "sel" : ""} onClick={()=>{
                                setSelPeriodo("1")
                            }}>Flexible</button>
                            <button className={selPeriodo == "2" ? "sel" : ""} onClick={()=>{
                                setSelPeriodo("2")
                            }}>Semana</button>
                            <button className={selPeriodo == "3" ? "sel" : ""} onClick={()=>{
                                setSelPeriodo("3")
                            }}>Mes</button>
                            <button className={selPeriodo == "4" ? "sel" : ""} onClick={()=>{
                                setSelPeriodo("4")
                            }}>Año</button>
                        </div>
                    </div>
                    <Grafica precios={precios[selPeriodo]} maximo={maximo[selPeriodo]} min={min} max={max} multiplica={multiplica[selPeriodo]}/>
                    <Slider min={min} setMin={setMin} max={max} setMax={setMax} maximo={maximo[selPeriodo]} multiplica={multiplica[selPeriodo]}/>
                </div>
                <div className="filtrarTipos">
                    <h3>¿Qué tipo de Almacén buscas?</h3>
                    <div className="tipos">
                        {Object.keys(idsRC).map(id=>{
                            return(
                                <label key={id}>
                                    <input type="checkbox" defaultChecked={seleccionados.types.includes(id)} onChange={(e)=>{
                                        if(e.target.checked){
                                            seleccionados.types.push(id);
                                        }
                                        else{
                                            seleccionados.types.splice(seleccionados.types.indexOf(id), 1);
                                        }
                                    }}/>
                                    <div>
                                        <img src={Imagenes[idsRC[id]]}/>
                                        <div>{nombres[id]}</div>
                                    </div>
                                </label>
                            )
                        })
                        }
                    </div>
                </div>
                <div className="filtrarServicios">
                    <h3>¿Qué Servicios Básicos necesitas?</h3>
                    <div className="tipos">
                        {[9,10,11,13].map(id=>{
                            return(
                                <label key={id}>
                                    <input type="checkbox" defaultChecked={seleccionados.services.includes(id)} onChange={(e)=>{
                                        if(e.target.checked){
                                            seleccionados.services.push(id);
                                        }
                                        else{
                                            seleccionados.services.splice(seleccionados.services.indexOf(id), 1);
                                        }
                                    }}/>
                                    <div>
                                        <img src={Imagenes[idServiciosRC[id]]}/>
                                        <div>{idServiciosRC[id]}</div>
                                    </div>
                                </label>
                            )
                        })
                        }
                    </div>
                </div>
                <div className="filtrarServicios">
                    <h3>¿Qué Servicios Operativos necesitas?</h3>
                    <div className="tipos">
                        {[1,2,3,7,12].map(id=>{
                            return(
                                <label key={id}>
                                    <input type="checkbox" defaultChecked={seleccionados.services.includes(id)} onChange={(e)=>{
                                        if(e.target.checked){
                                            seleccionados.services.push(id);
                                        }
                                        else{
                                            seleccionados.services.splice(seleccionados.services.indexOf(id), 1);
                                        }
                                    }}/>
                                    <div>
                                        <img src={Imagenes[idServiciosRC[id]]}/>
                                        <div>{idServiciosRC[id]}</div>
                                    </div>
                                </label>
                            )
                        })
                        }
                    </div>
                </div>
            </div>
            <div className="opcionesFiltros">
                {!scrollEnd &&
                    <div className="masContenido" onClick={()=>{
                        contFiltros.current.scrollTo({top: contFiltros.current.scrollTop + 250, behavior: "smooth"});
                    }}>
                        <img src={Flecha}/>
                    </div>
                }
                {!scrollStart &&
                    <div className="masContenido arriba" onClick={()=>{
                        contFiltros.current.scrollTo({top: contFiltros.current.scrollTop - 250, behavior: "smooth"});
                    }}>
                        <img src={Flecha} className="rev"/>
                    </div>
                }
                <button className="btn quitar" onClick={()=>{
                    setFiltros({...filtros, types:[], services:[], min: undefined, max: undefined, time: undefined, order: undefined})
                    setModalFiltro(false);
                }}>Quitar Filtros</button>
                <button className="btn" onClick={()=>{
                    if(min === 0 && max === maximo[selPeriodo]*multiplica[selPeriodo])
                        setFiltros({...filtros, types: seleccionados.types, services: seleccionados.services, order: sel})
                    else
                        setFiltros({...filtros, types: seleccionados.types, services: seleccionados.services, min, max, time: selPeriodo, order: sel})
                    setModalFiltro(false);
                }}>Mostrar Resultados</button>
            </div>
        </Modal>
    )
}

const Slider = ({min, setMin, max, setMax, maximo, multiplica, selPeriodo})=>{
    const[inicio, setInicio] = useState(min*100/(maximo*multiplica));
    const[final, setFinal] = useState(max*100/(maximo*multiplica));
    const slider = useRef();
    
    useEffect(()=>{
        setMin(Math.round(inicio*maximo*multiplica)/100);
    },[inicio, multiplica])
    
    useEffect(()=>{
        setMax(Math.round(final*maximo*multiplica)/100);
    },[final, multiplica])

    const handleDrag = (e)=>{
        e.preventDefault();
        const start = slider.current.offsetLeft;
        const size = slider.current.offsetWidth;
        const porcentaje = (e.clientX-start)*100/size;
        if(porcentaje < final && porcentaje > 0){
            const valor = Math.floor((e.clientX-start)*100/size);
            const margen = (((maximo*multiplica*5)/100))*100/(maximo*multiplica);
            if(valor < max-margen){
                setInicio(valor);
            }
            else{
                setInicio(max-margen);
            }
        }
    }
    
    const handleDragF = (e)=>{
        e.preventDefault();
        const start = slider.current.offsetLeft;
        const size = slider.current.offsetWidth;
        const porcentaje = (e.clientX-start)*100/size;
        if(porcentaje > inicio && porcentaje < 100){
            const valor = Math.ceil((e.clientX-start)*100/size);
            const margen = (((maximo*multiplica*5)/100))*100/(maximo*multiplica);
            if(valor > min+margen){
                setFinal(valor);
            }
            else{
                setFinal(min+margen);
            }
        }
    }
    const handleDragEnd = (e)=>{
        e.preventDefault();
        const start = slider.current.offsetLeft;
        const size = slider.current.offsetWidth;
        const porcentaje = (e.clientX-start)*100/size;
        if(porcentaje < final && porcentaje > 0)
            setInicio(Math.floor((e.clientX-start)*100/size));
    }
    const handleDragEndF = (e)=>{
        e.preventDefault();
        const start = slider.current.offsetLeft;
        const size = slider.current.offsetWidth;
        const porcentaje = (e.clientX-start)*100/size;
        if(porcentaje > inicio && porcentaje < 100)
            setFinal(Math.ceil((e.clientX-start)*100/size));
    }

    // touch
    
    const handleTouchDrag = (e)=>{
        const start = slider.current.offsetLeft;
        const size = slider.current.offsetWidth;
        const porcentaje = (e.touches[0].clientX-start)*100/size;
        if(porcentaje < final && porcentaje > 0)
            setInicio(Math.floor((e.touches[0].clientX-start)*100/size));
    }
    
    const handleTouchDragF = (e)=>{
        const start = slider.current.offsetLeft;
        const size = slider.current.offsetWidth;
        const porcentaje = (e.touches[0].clientX-start)*100/size;
        if(porcentaje > inicio && porcentaje < 100)
            setFinal(Math.ceil((e.touches[0].clientX-start)*100/size));
    }

    return(
        <>
            <div className="slider" ref={slider}>
                <div className="progreso inicio" style={{width:inicio+"%"}}></div>
                <div className="bola inicio"
                    draggable={true}
                    style={{left:"calc("+inicio+"% - 1em)"}}
                    onDragStart={(e)=>{
                        const span = document.createElement("span");
                        e.dataTransfer.setDragImage(span,null,null)
                    }}
                    onDrag={handleDrag}
                    onDragEnd={handleDragEnd}
                    onTouchMove={handleTouchDrag}
                    ></div>
                <div className="bola final"
                    draggable={true}
                    style={{right:"calc("+(100-final)+"% - 1em)"}}
                    onDragStart={(e)=>{
                        const span = document.createElement("span");
                        e.dataTransfer.setDragImage(span,null,null)
                    }}
                    onDrag={handleDragF}
                    onDragEnd={handleDragEndF}
                    onTouchMove={handleTouchDragF}
                    ></div>
                <div className="progreso final" style={{width:(100-final)+"%"}}></div>
            </div>
            <div>
                <form className="minMax">
                    <label>
                        <div className="simbolo">$</div>
                        <input type="number" value={min} min={0} max={max} onChange={(e)=>{
                            if(e.target.value < max){
                                setInicio(e.target.value*100/(maximo*multiplica))
                            }
                            else{
                                setInicio((max-((maximo*multiplica*5)/100))*100/(maximo*multiplica))
                            }
                        }
                        }/>
                        <span>Mínimo</span>
                    </label>
                    <span></span>
                    <label>
                        <div className="simbolo">$</div>
                        <input type="number" value={max} min={min} max={maximo*multiplica} onChange={(e)=>{
                            if(e.target.value > min)
                                setFinal(e.target.value*100/(maximo*multiplica))
                            else{
                                setFinal((min+((maximo*multiplica*5)/100))*100/maximo*multiplica);
                            }
                        }
                        }/>
                        <span>Máximo</span>
                    </label>
                </form>
            </div>
        </>
    )
}

const Grafica = ({precios, maximo, min, max, multiplica})=>{
    const ancho = maximo*multiplica/50;
    const [masAlto, setMasAlto] = useState(0);
    const [alto, setAlto] = useState([]);
    useEffect(()=>{
        const aux = Array(50).fill(0);
        precios?.forEach(precio => {
            const indice = Math.floor(precio*multiplica/ancho);
            if(!aux[indice])
                aux[indice] = 0;
            aux[indice] += 1;
        });
        setAlto(aux);
        setMasAlto(Math.max(...aux));
    },[precios])
    return(
        <div className="graficaPrecios">
            {alto.map((x,i)=>{
                return(
                    <div key={i} style={{height:(x*100/masAlto)+"%"}} className={i*ancho < min || (i)*ancho > max ? "disabled" : ""}></div>
                );
            })
            }
        </div>
    )
}

export default Filtros;