import React, { useEffect, useRef, useState } from "react";
import { baseUrl } from '../../config.js'
import { Link, useNavigate } from 'react-router-dom'
import useToken from '../../useToken';

import './Host.css';

import Agregar from '../../img/Agregar.png';
import Moral from '../../img/moral.png';
import Física from '../../img/fisica.png';
import SinEmpresa from '../../img/sinempresa.png';
import Editar from '../../img/editar.png';
import defaultAlmacen from '../../img/defaultAlmacen.png';

const Host = ({selectAlmacen})=>{
	
	const {token} = useToken();
	const navigate = useNavigate();
	const [personas, setPersonas] = useState([]);
	const [almacenes, setAlamcenes] = useState([]);
	const [selected, setSelected] = useState(null);
	const [cantidades, setCantidades] = useState({});
	const [filtros, setFiltros] = useState({});

	useEffect(()=>{
		if(token)
            getData();
	}, [token])

	const getData = async ()=>{
		console.log(token)
		const result = await fetch(baseUrl+"User/Companies/",{
			headers:{
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			}
		})
		.then(response=>response.json())
		.then(json=>{
			console.log(json);
			return json;
		})
		.catch(error=>{
			console.error(error);
		})
		setPersonas(result?.data || []);

		const result1 = await fetch(baseUrl+"Stores/User/",{
			headers:{
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			}
		})
		.then(response=>response.json())
		.then(json=>{
			console.log(json);
			return json;
		})
		.catch(error=>{
			console.error(error);
		})
		if(result1?.success){
			setAlamcenes(result1.data || []);
		}
	}

	useEffect(()=>{
		const aux = {activos:0, proceso:0}
		almacenes.forEach(almacen => {
			aux[almacen.idCompany || 0] = aux[almacen.idCompany || 0]*1+1 || 1;
		});
		console.log(aux);

		personas?.forEach(persona=>{
			if(persona.approved)
				aux["activos"]++;
			else
				aux["proceso"]++;
		})

		setCantidades({...aux});
	}, [personas, almacenes])
	if(selectAlmacen)
		return(
	
			<div className="personas">
				<h2>Selecciona el Almacén</h2>
				<div className="hostAlmacenes">
					{almacenes.map(almacen=>{
						if(selected === null || almacen.idCompany == selected)
						return(
							<Link to={'/host/publicaciones/'+almacen.idStore+'/nueva'} key={almacen.idStore}>
								<div className="imgAlmacen">
									{almacen?.Photos.length!=0 && almacen?.Photos[0] &&
										<img src={almacen?.Photos[0].url}/>
									}
									{almacen?.Photos.length === 0 &&
										<img src={defaultAlmacen}/>
									}
								</div>
								<div className="nombreAlmacen">{almacen.name}</div>
							</Link>
						)
					})
					}
				</div>
			</div>
		)
	else
		return(
			<div className="personas">
				<div>
					<div className="tipos pagos">
						<button
							className={filtros?.status == undefined ? 'sel' : ''}
							onClick={()=>{
								setFiltros({...filtros, status: undefined});
							}}
						>
							<span>Todos</span>
							<h2>{personas?.length || 0}</h2>
						</button>
						<button
							className={filtros?.status == 'activos' ? 'sel' : ''}
							onClick={()=>{
								setFiltros({...filtros, status: 'activos'});
							}}
							>
							<span>Activos</span>
							<h2>{cantidades?.activos || 0}</h2>
						</button>
						<button
							className={filtros?.status == 'proceso' ? 'sel' : ''}
							onClick={()=>{
								setFiltros({...filtros, status: 'proceso'});
							}}
							>
							<span>En Proceso</span>
							<h2>{cantidades?.proceso || 0}</h2>
						</button>
					</div>
					<div className="divAgregar">
						<Link className="agregar btn black" to='/host/gestion/personas/nueva'>
							<img src={Agregar}/>
							<span>Agregar Empresa/Persona</span>
						</Link>
					</div>
					{personas?.map(persona=>{
						if(!filtros.status || (filtros.status == 'activos' && persona.approved) || (filtros.status == 'proceso' && !persona.approved))
						return(
							<Link to={'/host/gestion/personas/'+persona.idCompany} key={persona.idCompany}>
								<>
									<div className="imgPersona">
										{!persona.url &&
											<img src={persona.idtypeCompany == 1 ? Física : Moral}/>
										}
										{persona.url &&
											<img src={persona.url}/>
										}
									</div>
									<span><b>{persona.name}</b></span>
									<span className="rfc">{persona.RFC}</span>
									{cantidades[persona.idCompany] && cantidades[persona.idCompany] != 0 &&
										<Link to={'/host/gestion/personas/'+persona.idCompany+'/almacenes'} className="cantidad btn black">
											<span>Ver Almacenes</span>
											<span>
												({cantidades[persona.idCompany]})
											</span>
										</Link>
									}
									{(!cantidades[persona.idCompany] || cantidades[persona.idCompany] == 0) &&
										<Link to={'/host/gestion/personas/'+persona.idCompany+'/almacenes/nuevo'} className="cantidad btn black">
											<span>Agregar Almacén</span>
										</Link>
									}
								</>
							</Link>
						)
					})

					}
					<span className={selected === 0 ? "selected" : ""} onClick={()=>{
							if(personas.length === 0)
								navigate('personas/nueva');
							if(selected === 0 )
								setSelected(null)
							else
								setSelected(0);
						}}>
						<div className="imgPersona">
							<img src={SinEmpresa}/>
						</div>
						<span>Sin Empresa Registrada</span>
						{cantidades[0] &&
							<Link to={'/host/personas/0/almacenes'} className="cantidad btn black">
								<span>Ver Almacenes</span>
									<span>
										({cantidades[0]})
									</span>
							</Link>
						}
					</span>
				</div>
			</div>
		);
}

export default Host;