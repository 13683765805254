import React, { useEffect, useRef, useState, useContext } from "react";
import { baseUrl } from '../../config.js'
import useToken from '../../useToken';
import { Link } from 'react-router-dom';
import { BuscadorContext } from "../Buscador/BuscadorContext.js";
import Modal from '../Modal/Modal.js'

import './Almacenes.css';
import { formatoNumero, formatoNumeroEntero } from "../../almacen.js";

import Flecha from '../../img/Flecha.png';

import Compartir from '../../img/Compartir.png';
import Fav from '../../img/Fav.png';
import unFav from '../../img/unFav.png';
import Aprobar from '../../img/Aprobar.png';

import Copiar from '../../img/Copiar.png';
import Email from '../../img/CorreoShare.png';
import Mensaje from '../../img/Mensaje.png';
import WhatsApp from '../../img/Whatsapp.png';
import Messenger from '../../img/Messenger.png';
import Facebook from '../../img/FacebookSoc.png';
import Twitter from '../../img/Twitter.png';

const Almacenes = ({impuestos, setModalLogin, favoritos, propias})=>{
	const { 
		filtros, setFiltros,
		totalFavs, setTotalFavs,
		setMaximo,
		setPrecios } = useContext(BuscadorContext);
	const {token} = useToken();
	const [publicaciones, setPublicaciones] = useState(Array(40).fill({}));
	const [recientes, setRecientes] = useState([]);
	const [copiar, setCopiar] = useState(false);
	const [share, setShare] = useState(false);

	useEffect(()=>{
		console.log(favoritos, propias)
			setFiltros({...filtros, fav: favoritos, own: propias})
	}, [favoritos, propias])

    useEffect(()=>{
		console.log(filtros);
		if(propias && !filtros?.own){

		}
		else{
			getData();
			if(token && !propias){
				getRecientes();
			}
		}
    }, [filtros, token])

    const getData = async ()=>{
        const result1 = await fetch(baseUrl+"Search",{
			method : 'post',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
			body: JSON.stringify(filtros),
		}).then(response=> response.json())
		.then(json=>{
			return json;
		})
		.catch(error=>{
			console.log(error);
		})
		setPublicaciones(result1?.data || []);
		const precios = {
			"1" : [],
			"2" : [],
			"3" : [],
			"4" : [],
		};
		const maximo = {
			"1" : [],
			"2" : [],
			"3" : [],
			"4" : [],
		};
		const periodos = {
			"1" : "day",
			"2" : "week",
			"3" : "month",
			"4" : "year",
		}
		const dividir = {
			"1" : 1,
			"2" : 7,
			"3" : 30,
			"4" : 365,
		}
		result1?.prices?.forEach((x,m)=>{
			const aux = {
				"1" : [],
				"2" : [],
				"3" : [],
				"4" : [],
			}
			x.TypeTime.forEach(k=>{
				const precioDia = x[periodos[k]]/dividir[k];
				aux["1"].push(precioDia);
				if(k!="1"){
					for(let j = k*1; j <= 4; j++){
						const l = j + "";
						aux[l].push(precioDia)
					}
				}
			})
			for(let i = 1; i <= 4; i++){
				const k = i+"";
				if(aux[k].length)
					precios[k].push(Math.min(...aux[k]));
			}
		}) 
		for(let k in precios){
			maximo[k] = result1?.max || 10000;
		}
		console.log(maximo);
		setMaximo(maximo);
		setPrecios(precios);
    }

	const getRecientes = async ()=>{
		const result1 = await fetch(baseUrl+"Posts/Recent",{
			method : 'get',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
		}).then(response=> response.json())
		.then(json=>{
			return json;
		})
		.catch(error=>{
			console.log(error);
		})
		setRecientes(result1?.data || []);
	}

	const comparte = (medio)=>{
		const enlace = "https://palletcloud.com.mx/almacen/"+share?.idPost;
		let texto = "¡Descubre este increíble almacén en renta! Perfecto para tu negocio. Más detalles aquí ";
		switch(medio){
			case "Copiar":
				navigator.clipboard.writeText(enlace);
        		setCopiar(true);
				setTimeout(() => {
					setCopiar(false);
				}, 4000);
				break;
			case "Email":
				texto = "¡Aprovecha esta oportunidad! Almacén en renta ideal para tu negocio.";
				const mailtoUrl = `mailto:?subject=${encodeURIComponent(texto)}&body=${encodeURIComponent(enlace)}`;
					window.open(mailtoUrl, '_blank');
				break;
			case "Mensaje":
				const smsUrl = `sms:?body=${texto + " " +encodeURIComponent(enlace)}`;
        		window.open(smsUrl, '_blank');
				break;
			case "WhatsApp":
				const whatsappUrl = `https://wa.me/?text=${texto + " " +encodeURIComponent(enlace)}`;
				window.open(whatsappUrl, '_blank');
				break;
			case "Messenger":
				const messengerUrl = `http://www.facebook.com/dialog/send?
				app_id=1269730677749200&link=${encodeURIComponent(enlace)}&redirect_uri=${encodeURIComponent(enlace)}`;
        		window.open(messengerUrl, '_blank');
				break;
			case "Facebook":
				const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(enlace)}`;
				window.open(facebookUrl, '_blank');
				break;
			case "Twitter":
				const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(texto + " " + enlace)}`;
        		window.open(twitterUrl, '_blank');
				break;
		}
	}

    return(
		<>
			{share &&
				<Modal setModal={setShare} titulo={"Comparte este Almacén"} className={"modalCompartir"}>
					<div>
						<div className="miniDescripcion">
							<div className="miniImg">
								<img src={share?.Photos[0].url}/>
							</div>
							<div>
								<div className="nombreAlmacen">{share?.name}</div>
							</div>
						</div>
						<div className="compartir">
							<button onClick={()=>{
								comparte('Copiar')
							}}>
								<span className="shareImg">
									<img src={Copiar}/>
								</span>
								<span>Copiar Enlace</span>
							</button>
							<button onClick={()=>{
								comparte('Email')
							}}>
								<span className="shareImg">
									<img src={Email}/>
								</span>
								<span>Email</span>
							</button>
							<button onClick={()=>{
								comparte('Mensaje')
							}}>
								<span className="shareImg">
									<img src={Mensaje}/>
								</span>
								<span>Mensaje</span>
							</button>
							<button onClick={()=>{
								comparte('WhatsApp')
							}}>
								<span className="shareImg">
									<img src={WhatsApp}/>
								</span>
								<span>WhatsApp</span>
							</button>
							<button onClick={()=>{
								comparte('Messenger')
							}}>
								<span className="shareImg">
									<img src={Messenger}/>
								</span>
								<span>Messenger</span>
							</button>
							<button onClick={()=>{
								comparte('Facebook')
							}}>
								<span className="shareImg">
									<img src={Facebook}/>
								</span>
								<span>Facebook</span>
							</button>
							<button onClick={()=>{
								comparte('Twitter')
							}}>
								<span className="shareImg">
									<img src={Twitter}/>
								</span>
								<span>Twitter</span>
							</button>
						</div>
					</div>
					{copiar &&
						<div className="copiado">
							<div>
								<img src={Aprobar}/>
								<span>Enlace copiado al portapapeles</span>
							</div>
						</div>
					}
				</Modal>
			}
			<div className="almacenes">
				{publicaciones.length === 0 &&
					<div className="noResult">
						{filtros?.fav ? "Aún no has agregado ningún almacen a favoritos" : filtros?.own ? "Aún no has creado publicaciones." :"Esta búsqueda no produjo ningún resultado"}
					</div>
				}
				{publicaciones.map((publicacion, i)=>{
					return(
						<Almacen publicacion={publicacion} impuestos={impuestos} setShare={setShare} key={i} propias={propias} setModalLogin={setModalLogin}/>
					)
				})
				}
			</div>
			{recientes.length!==0 &&
			<>
				<h3 className="headReciente">Visitados recientemente</h3>
				<div className="almacenes recientes">
					{recientes.map((publicacion, i)=>{
						return(
							<Almacen publicacion={publicacion} impuestos={impuestos} setShare={setShare} key={i} setModalLogin={setModalLogin}/>
						)
					})
					}
				</div>
			</>
			}
		</>
    )
}

const Almacen = ({ publicacion, impuestos, setShare, propias, setModalLogin})=>{
	const { setTotalFavs } = useContext(BuscadorContext);
    const {token} = useToken();
	const [config, setConfig] = useState(localStorage.getItem('config') ? JSON.parse(localStorage.getItem('config')) : {
	  idioma : 'es',
	  sistema : 'internacional',
	})
	const [faved, setFaved] = useState(publicacion?.faved);
    const [indice, setIndice] = useState(0);
    const wrapper = useRef();
    
    useEffect(()=>{
        if(wrapper.current){
            wrapper.current.scrollLeft = indice*wrapper.current.offsetWidth;
        }
    }, [indice])
	
	useEffect(()=>{
		setFaved(publicacion?.faved);
	}, [publicacion])

    const cambiaIndice = (dir)=>{
        setIndice(indice+dir);
    }

	const addFav = (e)=>{
		e.preventDefault();
		e.stopPropagation();
		fetch(baseUrl+"Fav/"+publicacion?.idPost,{
			method : faved ? 'delete' : 'post',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
		}).then(response=> response.json())
		.then(json=>{
			if(json.success){
				console.log(!faved);
				if(faved){
					setTotalFavs(totalFavs=>totalFavs-1);
				}
				else{
					setTotalFavs(totalFavs=>totalFavs+1);
				}
				setFaved(!faved);
			}
			console.log(json);
		})
		.catch(error=>{
			console.log(error);
		})
	}

    let desde = 0;
	const aux = []
	if(publicacion?.TypeTime?.includes("1")){
		aux.push(publicacion.day*30)
	}
	if(publicacion?.TypeTime?.includes("2")){
		aux.push(publicacion.week*30/7)
	}
	if(publicacion?.TypeTime?.includes("3")){
		aux.push(publicacion.month)
	}
	if(publicacion?.TypeTime?.includes("4")){
		aux.push(publicacion.year/12)
	}
	desde = Math.min(...aux);
	desde = Math.round(desde*100)/100;
	desde = isNaN(desde) || !isFinite(desde) ? 0 : desde;
	if(!impuestos){
		desde = desde*1.16;
	}
    return(
        <Link to={propias ? '/host/publicaciones/'+publicacion?.idStore+'/'+publicacion?.idPost : (publicacion?.idPost ? '/almacen/'+publicacion.idPost : '')} className={!publicacion?.idPost ? "placeholder" : ""}>
			{propias &&
				<div className="statusPost">{publicacion?.status ? "Publicada" : "Sin publicar"}</div>
			}
			{publicacion?.idPost &&
				<div className="quick">
					<button className="share" onClick={(e)=>{
						e.preventDefault();
						e.stopPropagation();
						setShare(publicacion)}
					}>
						<img src={Compartir}/>
					</button>
					<button className={"fav"+(faved ? " faved" : " unfaved")} onClick={(e)=>{
						e.preventDefault();
						e.stopPropagation();
						if(token)
							addFav(e)
						else
							setModalLogin(true);
					}}>
						{faved ?
							<img src={Fav}/>
							:
							<img src={unFav}/>
						}
					</button>
				</div>
			}
            <div className="imgAlmacen">
				{publicacion?.Photos?.length > 1 && indice != 0 &&
					<div className="mover izq" onClick={(e)=>{
						e.preventDefault();
						cambiaIndice(-1)
						}}>
						<img src={Flecha}/>
					</div>
				}
				{publicacion?.Photos?.length > 1 && indice != publicacion?.Photos?.length-1 &&
					<div className="mover der" onClick={(e)=>{
						e.preventDefault();
						cambiaIndice(1)
						}}>
						<img src={Flecha}/>
					</div>
				}
				<div className="wrapperImg" ref={wrapper}>
					{
						publicacion?.Photos?.map((imagen, i)=>{
							return(
								<div key={i}>
								<img src={imagen.url}/>
							</div>
							)
						})
					}
				</div>
			</div>
			<div className="nombreAlmacen">{publicacion?.Town+", "+ publicacion?.State}</div>
			<div className="">{publicacion?.name}</div>
			<div className="tipo">
				<div className="img">
					{/* <img src={iconos[publicacion.tipo]}/> */}
				</div>
				{/* <span className="descripcion">{publicacion.tipo}</span> */}
			</div>
			<div className="descAlmacen">{formatoNumeroEntero(publicacion?.assignedSpaces)} espacio{publicacion?.assignedSpaces == 1 ? "" : "s"}</div>
			<div className="descAlmacen medidas">
				<span>Ancho: {config.sistema == 'ingles' ? formatoNumero(publicacion?.width*(3.2808399)/100)+'ft' : formatoNumero(publicacion?.width/100)+'m'}</span>
				<span>Largo: {config.sistema == 'ingles' ? formatoNumero(publicacion?.length*(3.2808399)/100)+'ft' : formatoNumero(publicacion?.length/100)+'m'}</span>
				<span>Alto: {config.sistema == 'ingles' ? formatoNumero(publicacion?.height*(3.2808399)/100)+'ft' : formatoNumero(publicacion?.height/100)+'m'}</span>
			</div>
			<div className="descAlmacen medidas">
				<span>Capacidad de carga: {config.sistema == 'ingles' ? formatoNumero(publicacion?.weight*(2.20462262)/100)+'lb' : formatoNumero(publicacion?.weight/100)+'kg'}</span>
			</div>
			<div className="precioDesde">Desde <b>${desde.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} MXN</b><i>/espacio</i> por mes</div>
        </Link>
    )
}

export default Almacenes;