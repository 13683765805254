import React, { useEffect, useRef, useState, useMemo } from "react";
import { baseUrl } from '../../config.js'
import { Link, useParams, useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import {Icon} from 'leaflet';



import useToken from '../../useToken';

import './InfoAlmacen.css';
import {ids, idsR, idServicios, idServiciosR, Imagenes, idServiciosRC, idsRC, nombres, ImagenesTransporte, Dias } from '../../almacen.js';

import Correcto from '../../img/Correcto.png';
import Incorrecto from '../../img/Incorrecto.png';
import Revisando from '../../img/Revisando.png';
import Descargar from '../../img/Descargar.png';

import Domicilio from '../../img/ComprobanteDomicilio.png';
import Plano from '../../img/Plano.png';
import Croquis from '../../img/Croquis.png';
import TIF from '../../img/TIF.png';
import ID from '../../img/ID.png';
import Agregar from '../../img/Agregar.png';
import Quitar from '../../img/Cerrar.png';

const ImagenesStore = {
	36: Domicilio,
	39: ID,
	29: Plano,
	31: Croquis,
	34: TIF,
}

const InfoAlmacen = ({ id_almacen })=>{

	const navigate = useNavigate();
	const {token} = useToken();
	const { idCompany, id = id_almacen, sel="Información" } = useParams();
	const [paso, setPaso] = useState(sel) || "Información";
	const [servicio, setServicio] = useState('Operacionales');
	const [disponibles, setDisponibles] = useState(0);
	const [direcciones, setDirecciones] = useState([]);
	const [almacen, setAlmacen] = useState({idCompany: idCompany || 0, direccion:{}, tipos:{}, servicios:{}});
	const [address, setAddress] = useState("");
	const [position, setPosition] = useState({});
	const [personas, setPersonas] = useState([]);
	const [disabled, setDisabled] = useState(false);
	const [archivos, setArchivos] = useState([]);
	const [seguros, setSeguros] = useState([]);
	const [photos, setPhotos] = useState({});
	const [eliminarFotos, setEliminarFotos] = useState([]);
	const [eliminarSeguros, setEliminarSeguros] = useState([]);
	const [subiendo, setSubiendo] = useState({
		total : 0,
		subidas : 0,
		archivosSubidos : 0,
		totalArchivos : 0,
	});
	const [float, setFloat] = useState({});
	const [servicios, setServicios] = useState({
		"basicos" : {
			"activos" : 0,
			"totales" : 4,
		},
		"operacionales" : {
			"activos" : 2,
			"totales" : 5,
		}
	});

	const fullPath = idCompany ? '/host/gestion/personas/'+idCompany+'/almacenes/'+(id || "nuevo") : '/host/gestion/almacenes/'+(id || "nuevo");

	const Operativos = [3,7,12,14,15,16,17,18,19];
	const Basicos = [13,10,9,11];

	const nombre = useRef();
	const rfc = useRef();

	useEffect(()=>{
		setPaso(sel);
	}, [sel])

	useEffect(()=>{
		window.onbeforeunload = float?.estado == "guardando" ? ()=>{
			return true;
		} : null;
		
		return ()=>{
			window.onbeforeunload = null;
		}
	},[float])

	useEffect(()=>{
		if(token){
			getData();
			if(idCompany){
				getAddress(idCompany)
			}
		}
	},[id, token])

	useEffect(()=>{
		getEmpresas();
	}, [id, token])

	useEffect(()=>{
		if(id){
			setDisabled(almacen?.estado == "Activo");
			recuenta();
		}
	},[almacen])

	async function getData(){
		const result = await fetch(baseUrl+"Store/"+(id || ''),{
			headers:{
				'Authorization': `Bearer ${token}`,
			}
		})
		.then(response=>response.json())
		.then(json=>{
			return json;
		})
		.catch(error=>{
			console.error(error);
		})
		setDisponibles(result?.availableSpace*1 + result?.availableConfirmSpace*1);
		if(result?.lat)
			setPosition({lat: result?.lat, lng: result?.lng})
		result.tipos = {};
		result?.TypeStore?.forEach(x=>{
			result.tipos[idsR[x]] = true;
		})
		result.servicios = {};
		result?.OperativeService?.forEach(x=>{
			result.servicios[idServiciosR[x]] = true;
		})
		if(result.idCompany!=0){
			getAddress(result.idCompany);
		}
		setAlmacen({idCompany: idCompany || 0, direccion:{}, tipos:{}, servicios:{}, ...result} || {idCompany: idCompany || 0, direccion:{}, tipos:{}, servicios:{}});
		result.Document?.forEach(document=>{
			document.imagen = ImagenesStore[document.idDocument];
		})
		setArchivos(result.Document || []);
		setSeguros(result.Insurances || []);
		const auxPhotos = {};
		result.Photos?.forEach(photo=>{
			if(!auxPhotos[photo.idCategoryPhoto])
				auxPhotos[photo.idCategoryPhoto] = [];
			auxPhotos[photo.idCategoryPhoto].push(photo);
		})
		setPhotos(auxPhotos);
	}

	const getEmpresas = async ()=>{
		const result = await fetch(baseUrl+"User/Companies/", {
			headers:{
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			}
		})
		.then(response=>response.json())
		.then(json=>{
			// console.log(json);
			return json;
		})
		.catch(error=>{
			console.error(error);
		})
		setPersonas(result?.data);
	}
	const getAddress = async (idCompany)=>{
		if(idCompany && idCompany!=0){
			const result = await fetch(baseUrl+"Addresses/Company/"+idCompany, {
				headers:{
					'Authorization': `Bearer ${token}`,
				}
			})
			.then(response=>response.json())
			.then(json=>{
				return json;
			})
			.catch(error=>{
				console.error(error);
			})
			setDirecciones(result?.data);
		}
		else{
			setDirecciones([]);
		}
	}
	const recuenta = ()=>{
		const aux = {
			"basicos" : {
				"activos" : 0,
				"totales" : 0,
			},
			"operacionales" : {
				"activos" : 2,
				"totales" : 2,
			}
		}
		for(let k in idServicios){
			if([9,10,11,13].includes(idServicios[k])){
				aux["basicos"]["totales"]++;
				aux["basicos"]["activos"] += almacen.servicios[k] ? 1 : 0;
			}
			else{
				if(idServicios[k] != 2 && idServicios[k] != 1){
					aux["operacionales"]["totales"]++;
					aux["operacionales"]["activos"] += almacen.servicios[k] ? 1 : 0;
				}
			}
		}
		setServicios({...aux});
	}
	const guardar = async (e)=>{
		if(e?.target)
			e.target.disabled = true;
		setFloat({estado:'guardando', mensaje:'Guardando...'})
		let total = 0, totalArchivos = 0;
		for(let k in photos){
			total+= photos[k].reduce((a,b)=> {
				if(b.file)
					return a+1;
				else
					return a+0;
			}, 0);
		}
		for(let id in archivos){
			if(!archivos[id].file)
				continue;
			totalArchivos++;
		}
		setSubiendo({
			subidas : 0,
			total : total,
			archivosSubidos : 0,
			totalArchivos : totalArchivos
		})
		almacen["lat"] =  position?.lat;
		almacen["lng"] =  position?.lng;
		const data = {
			"idCompany":0,
			"name":"",
			"locationURL":"",
			"storeArea":"",
			"officeArea":"",
			"storeAreaAvailable":"",
			"totalSpace":"",
			"availableSpace":"",
			"availableConfirmSpace":"",
			"idColony":"0",
			"region":"",
			"street":"",
			"number":"",
			"zipCode":"",
			"idTypeAddress":"1",
			...almacen
		}
		console.log(data);
		// return false;
		const accion = id ? "put" : "post";
		if(id){
			data["idStore"] = id;
		}
		const result = await fetch(baseUrl+"Store/Company", {
			method: accion,
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
			body: JSON.stringify(data),
		})
		.then(response=>response.json())
		.then(json=>{
			console.log(json);
			return json;
		})
		.catch(error=>{
			console.error(error);
			setFloat({})
		})
		const idStore = id || result.idAlta;
		var results = {
			"tipos": [],
			"servicios": [],
			"documentos": [],
			"seguros": [],
			"fotos": [],
			"eliminarFotos": [],
			"eliminarSeguros": [],
			"ordenar": [],
		};
		if(result.success){
			const tipos = [];
			for(let tipo in almacen.tipos){
				if(!almacen.tipos[tipo])
					continue;
				tipos.push(ids[tipo])
			}
			results["tipos"].push(
				fetch(baseUrl+"Store/Type", {
					method: "post",
					headers: {
						'Content-Type': 'application/json',
						'Accept': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
					body: JSON.stringify({idStore, "typeStores" : tipos }),
				})
				.then(response=>response.json())
				.then(json=>{
					// console.log(json);
					return json;
				})
				.catch(error=>{
					console.error(error);
				})
			)
			const servicios = [];
			for(let tipo in almacen.servicios){
				console.log(tipo, almacen.servicios)
				if(!almacen.servicios[tipo])
					continue;
				servicios.push(idServicios[tipo])
			}
			results["servicios"].push(
				fetch(baseUrl+"Store/Services", {
					method: "post",
					headers: {
						'Content-Type': 'application/json',
						'Accept': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
					body: JSON.stringify({idStore, "services":servicios}),
				})
				.then(response=>response.json())
				.then(json=>{
					return json;
				})
				.catch(error=>{
					console.error(error);
				})
			)
			let subidas = 0, archivosSubidos = 0;
			for(let id in archivos){
				if(!archivos[id].file)
					continue;
				const data = new FormData();
				data.append('idStore', idStore);
				data.append('idDocument', archivos[id].idDocument);
				data.append('file', archivos[id].file);
				
				results["documentos"].push(
					fetch(baseUrl+"Document/Store", {
						method: "post",
						headers: {
							'Accept': 'application/json',
							'Authorization': `Bearer ${token}`,
						},
						body: data,
					})
					.then(response=>response.json())
					.then(json=>{
						// console.log(json);
						setSubiendo({
							total : total,
							subidas : subidas,
							archivosSubidos : ++archivosSubidos,
							totalArchivos : totalArchivos
						})
						
						archivos[id].file = undefined;
						archivos[id].idStatusDocument = 6;
						setArchivos([...archivos])
						return json;
					})
					.catch(error=>{
						console.error(error);
					})
				)
			}

			// subir seguros
			for(let id in seguros){
				const data = new FormData();
				data.append('idStore', idStore);
				data.append('idInsurance', seguros[id]?.idInsurance || 0);
				data.append('name', seguros[id]?.name || '');
				if(seguros[id].file)
					data.append('file', seguros[id].file);
				
				results["seguros"].push(
					fetch(baseUrl+"Document/Insurance" + (seguros[id]?.file ? '' : '/name'), {
						method: "post",
						headers: {
							'Accept': 'application/json',
							'Authorization': `Bearer ${token}`,
						},
						body: data,
					})
					.then(response=>response.json())
					.then(json=>{
						// console.log(json);
						/*
						setSubiendo({
							total : total,
							subidas : subidas,
							segurosSubidos : ++segurosSubidos,
							totalSeguros : totalSeguros
						})
						
						*/
						console.log("json")
						console.log(json)
						if(json["url"])
							seguros[id].url = json["url"];
						seguros[id].idInsurance = json["idInsurance"];
						seguros[id].file = undefined;
						console.log(seguros);
						return json;
					})
					.catch(error=>{
						console.error(error);
					})
				)
			}

			const ordenar = {};
			for(let id in photos){
				photos[id].forEach(photo=>{
					if(photo.uid || !photo.file){
						if(photo.orden!==undefined){
							ordenar[photo.uid] = photo.orden;	
						}
						return false;
					}
					const data = new FormData();
					data.append('idStore', idStore);
					data.append('idCategoryPhoto', id);
					data.append('file', photo.file);
					data.append('orden', photo.orden);
					
					results["fotos"].push(
						fetch(baseUrl+"Photo/Store", {
							method: "post",
							headers: {
								'Accept': 'application/json',
								'Authorization': `Bearer ${token}`,
							},
							body: data,
						})
						.then(response=>response.json())
						.then(json=>{
							// console.log(json);
							setSubiendo({
								total : total,
								subidas : ++subidas,
								archivosSubidos : archivosSubidos,
								totalArchivos : totalArchivos
							})
							photo.file = undefined;
							return json;
						})
						.catch(error=>{
							console.error(error);
						})
					)
				})	
			}
			results["ordenar"].push(
				fetch(baseUrl+"Photo/Order", {
					method: "put",
					headers: {
						'Content-Type': 'application/json',
						'Accept': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
					body: JSON.stringify({ "photos" : ordenar }),
				})
				.then(response=>response.json())
				.then(json=>{
					return json;
				})
				.catch(error=>{
					console.error(error);
				})
			)
			results["eliminarFotos"].push(
				fetch(baseUrl+"Photo/Store", {
					method: "delete",
					headers: {
						'Content-Type': 'application/json',
						'Accept': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
					body: JSON.stringify({idStore, "photos":eliminarFotos}),
				})
				.then(response=>response.json())
				.then(json=>{
					return json;
				})
				.catch(error=>{
					console.error(error);
				})
			)
			
			results["eliminarSeguros"].push(
				fetch(baseUrl+"Document/Insurance", {
					method: "delete",
					headers: {
						'Content-Type': 'application/json',
						'Accept': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
					body: JSON.stringify({idStore, "insurances":eliminarSeguros}),
				})
				.then(response=>response.json())
				.then(json=>{
					return json;
				})
				.catch(error=>{
					console.error(error);
				})
			)
		}
		else{
			setFloat({estado:'error', mensaje:result.error})
			setTimeout(()=>{
				setFloat({})
			}, 5000)
			return false;
		}
		Promise.all([...results["tipos"],...results["servicios"],...results["documentos"],...results["fotos"], ...results["ordenar"]])
		.then(()=>{
			if(e?.target)
				e.target.disabled = false;
			setEliminarFotos([]);
			setSubiendo({subidas:0, total : 0, archivosSubidos : 0, totalArchivos : 0})
			setPhotos({...photos})
			console.log(seguros);
			setSeguros([...seguros])
			setFloat({estado:'listo', mensaje:'¡Listo! Se han guardado los cambios con éxito'})
			if(!id){
				if(e?.target)
					e.target.disabled = false;
				navigate('/host/almacenes/'+idStore, {replace: true})
			}
			else{
				if(e?.target)
					e.target.disabled = false;
			}
			setTimeout(() => {
				setFloat({});
			}, 5000);
			return true;
		})
	}
	// console.log(almacen);
	return(
		<div className="almacen">
			{float?.estado &&
				<div className={"float"+(" "+float.estado)}>
					<div className="mensaje">
						{float.mensaje}
						{subiendo?.total !== 0 &&
							<div>Subiendo Fotografías: {subiendo.subidas} de {subiendo.total}</div>
						}
						{subiendo?.totalArchivos !== 0 &&
							<div>Subiendo Archivos: {subiendo.archivosSubidos} de {subiendo.totalArchivos}</div>
						}
					</div>
				</div>
			}
			<div className="opciones">
				{id &&
					<Link to={'/host/gestion/publicaciones/'+id}>Publicaciones</Link>
				}
				<button className="btn" onClick={guardar}>Guardar</button>
			</div>
			<div className="pasos">
				<button className={paso == 'Información' ? 'selected' : ''} onClick={()=>{
					navigate(fullPath+'/Información', {replace:true})
				}}>Información</button>
				<button className={paso == 'Tipo' ? 'selected' : ''} onClick={()=>{
					navigate(fullPath+'/Tipo', {replace:true})
				}}>Tipo de Almacén</button>
				<button className={paso == 'Domicilio' ? 'selected' : ''} onClick={()=>{
					navigate(fullPath+'/Domicilio', {replace:true})
				}}>Domicilio</button>
				<button className={paso == 'Horarios' ? 'selected' : ''} onClick={()=>{
					navigate(fullPath+'/Horarios', {replace:true})
				}}>Horarios de Atención</button>
				<button className={paso == 'Vehiculos' ? 'selected' : ''} onClick={()=>{
					navigate(fullPath+'/Vehiculos', {replace:true})
				}}>Tipos de Vehículos</button>
				<button className={paso == 'Servicios' ? 'selected' : ''} onClick={()=>{
					navigate(fullPath+'/Servicios', {replace:true})
				}}>Servicios</button>
				<button className={paso == 'Seguros' ? 'selected' : ''} onClick={()=>{
					navigate(fullPath+'/Seguros', {replace:true})
				}}>Seguros</button>
				<button className={paso == 'Documentos' ? 'selected' : ''} onClick={()=>{
					navigate(fullPath+'/Documentos', {replace:true})
				}}>Documentos</button>
				<button className={paso == 'Fotografías' ? 'selected' : ''} onClick={()=>{
					navigate(fullPath+'/Fotografías', {replace:true})
				}}>Fotografías</button>
			</div>
			<form>
				{paso == "Información" &&
					<div className="conMitad">
						<label>
							<input type="text" placeholder="" ref={nombre} defaultValue={almacen?.name} onChange={(e)=>{
								almacen.name=e.target.value;
							}} disabled={disabled}/>
							<span>Nombre</span>
						</label>
						<label className="select">
							<span>Empresa</span>
							<select defaultValue={almacen.idCompany} onChange={(e)=>{
								almacen.idCompany = e.target.value;
								getAddress(e.target.value);
							}}>
								<option selected={almacen.idCompany == 0} value={0}>Sin asignar</option>
								{personas.map(persona=>{
									return(
										<option key={persona.idCompany} selected={almacen.idCompany == persona.idCompany} value={persona.idCompany}>({persona.idtypeCompany == 1 ? "Física" : "Moral"}) {persona.name}</option>
									)
								})
								}
							</select>
						</label>
						<label className="mitad">
							<input type="number" min={1} placeholder="" defaultValue={almacen?.officeArea} onChange={(e)=>{
								almacen.officeArea=e.target.value;
							}} disabled={disabled}/>
							<span>Área de Oficinas (m²)</span>
						</label>
						<label className="mitad">
							<input type="number" min={1} placeholder="" defaultValue={almacen?.storeArea} onChange={(e)=>{
								almacen.storeArea=e.target.value;
							}} disabled={disabled}/>
							<span>Área de Almacén Total (m²)</span>
						</label>
						<label className="mitad">
							<input type="number" min={1} placeholder="" defaultValue={almacen?.storeAreaAvailable} onChange={(e)=>{
								almacen.storeAreaAvailable=e.target.value;
							}} disabled={disabled}/>
							<span>Área de Almacén Disponible (m²)</span>
						</label>
						<label className="mitad">
							<input type="number" min={1} placeholder="" defaultValue={almacen?.totalSpace} onChange={(e)=>{
								almacen.totalSpace=e.target.value;
							}} disabled={disabled}/>
							<span>Pallet Position Totales</span>
						</label>
						<label className="mitad">
							<input type="number" min={1} placeholder="" defaultValue={almacen?.ramps} onChange={(e)=>{
								almacen.ramps=e.target.value;
							}} disabled={disabled}/>
							<span>Rampas de carga/descarga</span>
						</label>
						<label className="mitad">
							<input type="number" min={1} placeholder="" defaultValue={almacen?.platforms} onChange={(e)=>{
								almacen.platforms=e.target.value;
							}} disabled={disabled}/>
							<span>Andenes</span>
						</label>
						<div className="conMitad">
							<h4 style={{width: '100%'}}>Pallet Position Disponibles{disponibles ? ': '+disponibles : ''}</h4>
							<label className="mitad">
								<input type="number" min={1} placeholder="" defaultValue={almacen?.availableSpace} onChange={(e)=>{
									almacen.availableSpace=e.target.value;
									setDisponibles(almacen.availableSpace*1 + almacen.availableConfirmSpace*1)
								}} disabled={disabled}/>
								<span>Para Reservación Inmediata</span>
							</label>
							<label className="mitad">
								<input type="number" min={1} placeholder="" defaultValue={almacen?.availableConfirmSpace} onChange={(e)=>{
									almacen.availableConfirmSpace=e.target.value;
									setDisponibles(almacen.availableSpace*1 + almacen.availableConfirmSpace*1)
								}} disabled={disabled}/>
								<span>Para Reservación Por Confirmar</span>
							</label>
						</div>
					</div>
				}
				{paso == "Tipo" &&
					<div className="tiposAlmacen">
						<h2>¿Qué tipo de almacenaje ofrece?</h2>
						{
							[1,2,3,4,5,6,7,8,9,10,11].map(x=>{
								return(
									<label key={x}>
										<input type="checkbox" checked={!!almacen?.tipos[idsR[x]]} onChange={(e)=>{
											almacen.tipos[idsR[x]] = e.target.checked;
											recuenta();
										}}/>
										<div className="img">
											<img src={Imagenes[idsRC[x].split(" ")[0]]}/>
										</div>
										<span>{nombres[x]}</span>
									</label>
								)
							})
						}
					</div>
				}
				{paso == "Domicilio" &&
					<>
						<h2>¿En dónde se encuentra el almacén?</h2>
						{direcciones.length!==0 &&
							<div>
								<div>La misma que:</div>
								<div className="dirAlmacen">
									{direcciones.map(direccion=>{
										return(
											<button type="button" key={direccion.idTypeAddress} onClick={()=>{
												almacen.street = direccion.street;
												almacen.number = direccion.number;
												almacen.zipCode = direccion.zipCode;
												almacen.idColony = direccion.idColony;
												almacen.Colony = direccion.Colony;
												almacen.Town = direccion.Town;
												almacen.State = direccion.State;
												// setInfo({State: almacen.State, Town: almacen.Town});
												
												let add = almacen.street+", "+almacen.number+", "+almacen.Colony+", "+almacen.Town+", "+almacen.State; 
												setAddress(add);

											}}>Domicilio {direccion.type}</button>
										)
									})
									}
								</div>
							</div>
						}
						<Direccion almacen={almacen} disabled={disabled} position={position} setPosition={setPosition} address={address} setAddress={setAddress}/>
					</>
				}
				{paso == "Vehiculos" &&
					<Vehiculos almacen={almacen}/>
				}
				{paso == "Servicios" &&
					<>
						<h2>¿Con qué servicios cuenta el almacén?</h2>
						<div className="pasos">
							<button type="button" className={servicio == 'Operacionales' ? 'selected' : ''} onClick={()=>{
								setServicio('Operacionales')
							}}>Operacionales ({servicios["operacionales"]["activos"]}/{servicios["operacionales"]["totales"]})</button>
							<button type="button" className={servicio == 'Básicos' ? 'selected' : ''} onClick={()=>{
								setServicio('Básicos')
							}}>Básicos ({servicios["basicos"]["activos"]}/{servicios["basicos"]["totales"]})</button>
						</div>
						{servicio == "Básicos" &&
							<div className="serviciosAlmacen">
								{
									Basicos.map(x=>{
										return(
											<label key={x}>
												<input type="checkbox" checked={!!almacen?.servicios[idServiciosR[x]]} onChange={(e)=>{
													almacen.servicios[idServiciosR[x]] = e.target.checked;
													recuenta();
												}}/>
												<div className="img">
													<img src={Imagenes[idServiciosRC[x].split(" ")[0]]}/>
												</div>
												<span>{idServiciosRC[x]}</span>
											</label>
										)
									})
								}
							</div>
						}
						{servicio == "Operacionales" &&
							<div className="serviciosAlmacen">
								<div>
									<input type="checkbox" readOnly={true} disabled={true} checked={true} onChange={(e)=>{
									}}/>
									<div className="img">
										<img src={Imagenes['In']}/>
									</div>
									<label>
										<input type="number" placeholder="" defaultValue={almacen?.priceIn} onChange={(e)=>{
											almacen.priceIn = e.target.value;
										}}/>
										<span>In</span>
										<span className="simbolo">$</span>
									</label>
								</div>
								<div>
									<input type="checkbox" readOnly={true} disabled={true} checked={true} onChange={(e)=>{
									}}/>
									<div className="img">
										<img src={Imagenes['Out']}/>
									</div>
									<label>
										<input type="number" placeholder="" defaultValue={almacen?.priceOut} onChange={(e)=>{
											almacen.priceOut = e.target.value;
										}}/>
										<span>Out</span>
										<span className="simbolo">$</span>
									</label>
								</div>
								{
									Operativos.map(x=>{
										return(
											<label key={x}>
												<input type="checkbox" checked={!!almacen?.servicios[idServiciosR[x]]} onChange={(e)=>{
													almacen.servicios[idServiciosR[x]] = e.target.checked;
													recuenta();
												}}/>
												<div className="img">
													<img src={Imagenes[idServiciosRC[x].split(" ")[0]]}/>
												</div>
												<span>{idServiciosRC[x]}</span>
											</label>
										)
									})
								}
							</div>
						}	
					</>
				}
				{paso == "Horarios" &&
					<>
						<h2>¿Cuáles son los horarios de atención del Almacén?</h2>
						{almacen.idCompany !== 0 &&
							<HorariosAlmacen almacen={almacen}/>
						}
					</>
				}
				{paso == "Documentos" &&
					<DocumentosAlmacen almacen={almacen} archivos={archivos} setArchivos={setArchivos} guardar={guardar}/>
				}
				{paso == "Seguros" &&
					<Seguros almacen={almacen} seguros={seguros} setSeguros={setSeguros} setEliminarSeguros={setEliminarSeguros} guardar={guardar}/>
				}
				{paso == "Fotografías" &&
					<FotosAlmacen almacen={almacen} photos={photos} setPhotos={setPhotos} setEliminarFotos={setEliminarFotos} guardar={guardar}/>
				}
			</form>
		</div>
	);
}

const Vehiculos = ({almacen})=>{

	const [transportes, setTransportes] = useState(almacen?.transport || []);

	useEffect(()=>{
		almacen?.transport?.forEach(x=>{
			x.diff = true;
		})
		setTransportes(almacen?.transport || [])
	}, [almacen])

	const seleccionarPrevios = (id)=>{
		transportes.forEach(x=>{
			if(!x.diff && x.idTransport <= id){
				x.status = true;
			}
		})
		setTransportes([...transportes])
	}

	const desmarcar = ()=>{
		transportes.forEach(x=>{
			x.status = false;
			x.diff = false;
		})
		setTransportes([...transportes]);
	}

	return(
		<>
			<h2>¿Qué Vehículos puede manejar tu almacén?</h2>
			<div className="unmark">
				<button type="button" onClick={desmarcar}>Desmarcar todos</button>
			</div>
			<div className="serviciosAlmacen">
				{
					transportes.map(transporte=>{
						return(
							<label key={transporte.idTransport}>
								<input type="checkbox" checked={transporte.status} onChange={(e)=>{
									if(!e.target.checked){
										transporte.diff = true;
										transporte.status = e.target.checked;
										setTransportes([...transportes])
									}
									else{
										transporte.diff = false;
										transporte.status = e.target.checked;
										setTransportes([...transportes])
									}
									if(transporte.diff){
										transporte.status = e.target.checked;
									}
									else{
										seleccionarPrevios(transporte.idTransport)
									}
								}}/>
								<div className="img">
									{ImagenesTransporte[transporte.idTransport] &&
										<img src={ImagenesTransporte[transporte.idTransport]}/>
									}
								</div>
								<span>{transporte.name} {transporte.capacity ? `(${transporte.capacity})` : ''}</span>
							</label>
						)
					})
				}
			</div>	
		</>
	)
}

const Seguros = ({almacen, seguros, setSeguros, setEliminarSeguros, guardar})=>{
	return(
		<div>
			<div>
				<button type="button" className="btn" onClick={()=>{
					setSeguros([...seguros, {}])
				}}>Agregar Seguro</button>
			</div>
			<div className="seguros">
				{seguros.map((seguro, i) =>{
					return(
						<FileSeguro seguro={seguro} seguros={seguros} setSeguros={setSeguros} setEliminarSeguros={setEliminarSeguros} guardar={guardar} key={i} indice={i}/>
					)
				})
				}
			</div>
		</div>
	)
}

const FileSeguro = ({indice, seguro, seguros, setSeguros, setEliminarSeguros, guardar})=>{
	const [error, setError] = useState('');

	const input = useRef();
	const nombre = useRef();

	const subir = (e)=>{
		if(input.current.files[0].size/(1024*1024) > 25){
			setError({error:true, descripcion: "El documento no debe ser mayor a 25MB"});
			input.current.value = "";
		}
		if(input.current.files[0]){
			setError({});
		}
		seguro.file = input.current.files[0];
		seguro.name = nombre.current.value;
		setSeguros([...seguros])
		if(guardar){
			guardar();
		}
	}

	function dragstart(e) {
        e.preventDefault();
        e.stopPropagation();
    }
    function drag(e) {
        e.preventDefault();
        e.stopPropagation();
    }
    function dragover(e) {
        e.currentTarget.classList.add("hov");
        e.preventDefault();
        e.stopPropagation();
    }
    function dragenter(e) {
        e.currentTarget.classList.add("hov");
        e.preventDefault();
        e.stopPropagation();
    }
    function dragend(e) {
        e.currentTarget.classList.remove("hov");
        e.preventDefault();
        e.stopPropagation();
    }
    function dragleave(e) {
        e.currentTarget.classList.remove("hov");
        e.preventDefault();
        e.stopPropagation();
    }
    function drop(e) {
        e.currentTarget.classList.remove("hov");
        e.preventDefault();
        e.stopPropagation();
        input.current.files = e.dataTransfer.files;
		const event = new Event('change', { bubbles: true });
		event.simulated = true;
		input.current.dispatchEvent(event);
    }

	const eliminarSeguro = ()=>{
		if(seguro.idInsurance)
			setEliminarSeguros(eliminar=>{
				return [...eliminar, seguro.idInsurance];
			});
		setSeguros(seguros.filter((s, i)=>{
			if(seguro.idInsurance)
				return s.idInsurance != seguro.idInsurance;
			else
				return i != indice;
		}))
	}

	return(
		<div>
			<button type="button" className="eliminar" onClick={eliminarSeguro}>
				<img src={Quitar}/>
			</button>
			<label>
				<input type="text" defaultValue={seguro?.name} ref={nombre} onChange={(e)=>{
					seguro.name = e.target.value;
				}}/>
				<span>Nombre</span>
			</label>
			<label className="fileSeguro"
				onDragStart = {dragstart}
				onDrag = {drag}
				onDragEnd = {dragend}
				onDragOver = {dragover}
				onDragEnter = {dragenter}
				onDragLeave = {dragleave}
				onDrop = {drop}
			>
				<input type='file' onChange={subir} accept="image/*,application/pdf" ref={input}/>
				<span>Poliza</span>
					{seguro?.url &&
						<button type="button" onClick={(e)=>{
							e.preventDefault();
							e.stopPropagation();
							window.open(seguro.url, '_blank')
						}} className="placeFile">
							<div>Descargar</div>
							<img src={Descargar}/>
						</button>
					}
					{!seguro?.url &&
						<div className="placeFile">Subir archivo</div>
					}
			</label>
		</div>
	)
}

export const DocumentosAlmacen = ({ almacen, archivos, setArchivos, requiredOnly, guardar})=>{
	return(
		<div className="documentos">
			<div className="docs">
				{archivos.map((archivo)=>{
					archivo.imagen = ImagenesStore[archivo.idDocument];
					if(
						(requiredOnly && archivo.requiredStore != 'required') ||
						(archivo.idDocument == 32 && !almacen?.tipos[idsR['11']]) ||
						(archivo.idDocument == 33 && !almacen?.tipos[idsR['5']]) ||
						(archivo.idDocument == 35 && !almacen?.tipos[idsR['9']]) ||
						(archivo.idDocument == 44 && !almacen?.servicios[idServiciosR['17']]) ||
						(archivo.idDocument == 45 && !almacen?.servicios[idServiciosR['18']])
					){
						return false;
					}
					return(
						<Archivo archivo={archivo} archivos={archivos} setArchivos={setArchivos} guardar={guardar}/>
					)
				})
				}
			</div>
		</div>
	)
}

const Archivo = ({archivo, archivos, setArchivos, guardar})=>{
    const [error, setError] = useState({});

	const input = useRef();
	const lbls = {
		3 : "Rechazado",
		4 : "Aprobado",
		6 : "En Revisión",
	}
	const clases = {
		3 : "Incorrecto",
		4 : "Correcto",
		6 : "Revisando",
	}
    const imgs = {
		3 : Incorrecto,
        4 : Correcto,
        6 : Revisando,
    }

    const selecciona = ()=>{
        input.current.click();
    }
    const cambiaEstado = ()=>{
		if(input.current.files[0].size/(1024*1024) > 25){
			setError({error:true, descripcion: "El documento no debe ser mayor a 25MB"});
			input.current.value = "";
		}
        if(input.current.files[0]){
            setError({});
        }
		archivo.file = input.current.files[0];
		setArchivos([...archivos])
		if(guardar){
			guardar();
		}
    }

	function dragstart(e) {
        e.preventDefault();
        e.stopPropagation();
    }
    function drag(e) {
        e.preventDefault();
        e.stopPropagation();
    }
    function dragover(e) {
        e.currentTarget.classList.add("hov");
        e.preventDefault();
        e.stopPropagation();
    }
    function dragenter(e) {
        e.currentTarget.classList.add("hov");
        e.preventDefault();
        e.stopPropagation();
    }
    function dragend(e) {
        e.currentTarget.classList.remove("hov");
        e.preventDefault();
        e.stopPropagation();
    }
    function dragleave(e) {
        e.currentTarget.classList.remove("hov");
        e.preventDefault();
        e.stopPropagation();
    }
    function drop(e) {
        e.currentTarget.classList.remove("hov");
        e.preventDefault();
        e.stopPropagation();
        input.current.files = e.dataTransfer.files;
		const event = new Event('change', { bubbles: true });
		event.simulated = true;
		input.current.dispatchEvent(event);
    }

    return(
        <div
			onClick={selecciona}
			className={!archivo?.idStatusDocument ? "subir" : clases[archivo?.idStatusDocument] || 'subir'}
			onDragStart = {!archivo?.idStatusDocument || archivo?.idStatusDocument == 3 ? dragstart : null}
			onDrag = {!archivo?.idStatusDocument || archivo?.idStatusDocument == 3 ? drag : null}
			onDragEnd = {!archivo?.idStatusDocument || archivo?.idStatusDocument == 3 ? dragend : null}
			onDragOver = {!archivo?.idStatusDocument || archivo?.idStatusDocument == 3 ? dragover : null}
			onDragEnter = {!archivo?.idStatusDocument || archivo?.idStatusDocument == 3 ? dragenter : null}
			onDragLeave = {!archivo?.idStatusDocument || archivo?.idStatusDocument == 3 ? dragleave : null}
			onDrop = {!archivo?.idStatusDocument || archivo?.idStatusDocument == 3 ? drop : null}
			>
			{archivo?.imagen &&
				<div className="imgDoc">
					<img src={archivo.imagen}/>
				</div>
			}
			<div>
				<h3>{archivo?.name} {archivo?.requiredStore == 'optional' ? <i>(opcional)</i> : ""}</h3>
				{error.error &&
					<div className="error">{error.descripcion}</div>
				}
				<div className="arrastra">
					{(!archivo?.idStatusDocument || archivo?.idStatusDocument == 3) &&
						<div className='lblSel'>Selecciona o arrastra el archivo</div>
					}
					<div className="opcDocumento">
						{archivo?.url && archivo?.idStatusDocument != 3 &&
							<>
								<button type="button" onClick={(e)=>{
									e.preventDefault();
									e.stopPropagation();
									window.open(archivo?.url, "_blank");
								}}>
									<img src={Descargar}/>
								</button>
							</>
						}
					</div>
					{archivo?.note &&
						<div>
							<div><b>Motivos del rechazo:</b></div>
							<pre>
								{archivo?.note}
							</pre>
						</div>
					}
					<input type='file' ref={input} accept='application/pdf' onChange={cambiaEstado}/>
				</div>
				<div className="descEstado">{lbls[archivo?.idStatusDocument]}</div>
				<div className={'estado'}>
					{archivo?.idStatusDocument &&
						<>
							<div className='infoEstado'>{lbls[archivo?.idStatusDocument]}</div>
							<img src={imgs[archivo?.idStatusDocument]}/>
						</>
					}
				</div>
			</div>
        </div>
    )
}


const Direccion = ({almacen, disabled, position, setPosition, address, setAddress})=>{
	const [colonias, setColonias] = useState([]);
	const [info, setInfo] = useState({State: almacen.State, Town: almacen.Town});
	const calle = useRef();
	const numero = useRef();
	const cp = useRef();
	const state = useRef();
	const town = useRef();
	const colony = useRef();

	async function getCoordinates(address) {
		if(address){
			const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyBldMNI3yB6pOTwe8Pb_SvBP7fvucP6Gx8`)
			.then(response=>response.json());
			if(response.results.length!=0)
				return {"lat" : response.results[0].geometry.location.lat, "lng" : response.results[0].geometry.location.lng};
			else{
				return { lat: 51.505, lng: -0.09 };
			}
		}
	}
	useEffect(()=>{
		setInfo({State: almacen?.State, Town: almacen?.Town});
	}, [almacen.idColony])
	useEffect(() => {
		if(address){
			getCoordinates(address).then(setPosition);
		}
	  }, [address]);
	
	useEffect(()=>{
		getColonias(almacen.zipCode, true)
	}, [almacen.idColony])

	function getDireccion(e){
		const cp = e.target.value;
		getColonias(cp, true);
	}
	
	function getColonias(cp, cambio){
		fetch(baseUrl+'Zipcode/'+cp)
		.then(response=>response.json())
		.then(json=>{
			if(cambio){
				almacen.idColony = json.data[0].id;
				almacen.Colony = json.data[0].name;
				setInfo({State: json.address.State, Town: json.address.Town, Fronterizo: json.address.Frontier})
			}
			setColonias(json.data ||[]);
		})
		.catch(error=>{
			console.log(error);
		})
	}

	return (
	<>
		<div className="conMitad">
			<label>
				<input type="text" placeholder="" onChange={(e)=>{
					almacen.street = e.target.value;
				}} ref={calle} defaultValue={almacen?.street} disabled={disabled}/>
				<span>Calle</span>
			</label>
			<label className="mitad">
				<input type="text" placeholder="" onChange={(e)=>{
					almacen.number = e.target.value;
				}} ref={numero} defaultValue={almacen?.number} disabled={disabled}/>
				<span>Número</span>
			</label>
			<label className="mitad">
				<input type="number" placeholder="" max={99999} onChange={(e)=>{
					if(e.target.value.length == 5){
						getDireccion(e);
					}
					almacen.zipCode = e.target.value;
				}} ref={cp} defaultValue={almacen?.zipCode} disabled={disabled}/>
				<span>Código Postal</span>
			</label>
			<label>
				<input type="text" placeholder="" ref={state} value={info?.State} disabled={true}/>
				<span>Estado</span>
			</label>
			<label>
				<input type="text" placeholder="" ref={town} value={info?.Town} disabled={true}/>
				<span>Municipio</span>
			</label>
			{colonias[0] && 
				<label className="select">
					<span>Colonia</span>
					<select defaultValue={almacen?.idColony} onChange={(e)=>{
						almacen.idColony = e.target.value;
						almacen.Colony = e.target.innerText;
					}}>
						{colonias?.map(colonia=>{
							return(
								<option key={colonia.id} value={colonia.id}>{colonia.name}</option>
							)
						})
						}
					</select>
				</label>
			}
		</div>
		<div className="btnBuscar">
			<button type="button" className="btn" onClick={()=>{
				let add = almacen.street+", "+almacen.number+", "+almacen.Colony+", "+info.Town+", "+info.State; 
				setAddress(add);
			}}>Buscar</button>
		</div>
		{position?.lat &&
			<div className="mapa">
				<MapComponent position={position} setPosition={setPosition}/>
			</div>
		}
		</>
	)
}

// cambiar tipos de almacen y servicios a for y checkeds correctos

export const FotosAlmacen = ({ almacen, photos, setPhotos, setEliminarFotos, guardar })=>{
	const [categoria, setCategoria] = useState(1)
	return (
		<>
			<div className="pasos">
				<button type="button" className={categoria == 1 ? 'selected' : ''} onClick={()=>{
					setCategoria(1)
				}}>Fachada ({photos[1]?.length || 0})</button>
				<button type="button" className={categoria == 2 ? 'selected' : ''} onClick={()=>{
					setCategoria(2)
				}}>Andenes de carga y descarga ({photos[2]?.length || 0})</button>
				<button type="button" className={categoria == 3 ? 'selected' : ''} onClick={()=>{
					setCategoria(3)
				}}>Rampa de carga y descarga ({photos[3]?.length || 0})</button>
				<button type="button" className={categoria == 4 ? 'selected' : ''} onClick={()=>{
					setCategoria(4)
				}}>Interior ({photos[4]?.length || 0})</button>
				{almacen?.servicios?.fullfilment &&
					<button type="button" className={categoria == 5 ? 'selected' : ''} onClick={()=>{
						setCategoria(5)
					}}>Fullfilment (Pick & Pack) ({photos[5]?.length || 0})</button>
				}
				{almacen?.servicios?.maquila &&
					<button type="button" className={categoria == 6 ? 'selected' : ''} onClick={()=>{
						setCategoria(6)
					}}>Servicio de valor agregado (maquila) ({photos[6]?.length || 0})</button>
				}
				<button type="button" className={categoria == 7 ? 'selected' : ''} onClick={()=>{
					setCategoria(7)
				}}>Área de oficinas ({photos[7]?.length || 0})</button>
				<button type="button" className={categoria == 8 ? 'selected' : ''} onClick={()=>{
					setCategoria(8)
				}}>Recepción ({photos[8]?.length || 0})</button>
				<button type="button" className={categoria == 9 ? 'selected' : ''} onClick={()=>{
					setCategoria(9)
				}}>Video ({photos[9]?.length || 0})</button>
			</div>
			<div>
				<div className="documentos">
					<div><i>Cada almacén requiere al menos 6 fotografías.</i></div>
					<Fotos id={categoria} photos={photos} setPhotos={setPhotos} setEliminarFotos={setEliminarFotos} guardar={guardar}/>
				</div>
			</div>
		</>
	)
}

const Fotos = ({id, photos, setPhotos, setEliminarFotos, guardar})=>{
	const [fotos, setFotos] = useState(photos[id] || []);
	const [indice, setIndice] = useState(0);
    const [cargando, setCargando] = useState(false);
    const [error, setError] = useState({});
    const input = useRef();
	const sizeLimit = id == 9 ? 250 : 25;
	const validTypes = id == 9 ? 'video/*' : 'image/*';

	useEffect(()=>{
		setFotos(photos[id] || []);
		setIndice(0)
	},[id, photos])

	useEffect(()=>{
		fotos.forEach((x,i)=>{
			x.orden = i;
		})
		photos[id] = [...fotos];
	}, [fotos])

    const selecciona = ()=>{
        input.current.click();
    }
    const cambiaEstado = (e)=>{
		if(input.current.files[0].size/(1024*1024) > sizeLimit){
			setError({error:true, descripcion: `El documento no debe ser mayor a ${sizeLimit}MB`});
			input.current.value = "";
			return false;
		}
        if(input.current.files[0]){
			setError({});
        }
		const aux = [];
		function getURL(i){
			const reader = new FileReader();
			reader.readAsDataURL(e.target.files[i]);
			reader.onload = () => {
				aux.push({url: String(reader.result), file: e.target.files[i], orden: fotos.length+i})
				if(i < e.target.files.length-1){
					getURL(i+1);
				}
				else{
					setFotos([...fotos, ...aux])
					setPhotos(photos=>{
							photos[id] = [...fotos, ...aux];
							return {...photos};
						}
					)
					setCargando(false);
					if(guardar){
						guardar();
					}
				}
			};
		}
		getURL(0);
    }
	function dragstartHandler(ev, i) {
		// Add the target element's id to the data transfer object
		ev.dataTransfer.setData("text/plain", i);
		ev.dataTransfer.effectAllowed = "move";
	}	
	function dragoverHandler(e) {
		e.preventDefault();
		e.dataTransfer.dropEffect = "move";
	}
	  function dropHandler(e, i) {
		e.preventDefault();
		// Get the id of the target and add the moved element to the target's DOM
		const data = e.dataTransfer.getData("text/plain");
		const aux = fotos[data];
		fotos.splice(data, 1);
		fotos.splice(i, 0, aux);
		setFotos([...fotos]);
		if(indice == data || i == indice){
			setIndice(i);
		}
		else if(indice > data && indice < i){
			setIndice(indice*1-1);
		}
		else if(indice < data && indice > i){
			setIndice(indice*1+1);
		}
		// e.target.appendChild(document.getElementById(data));
	}

	function dragstart(e) {
        e.preventDefault();
        e.stopPropagation();
    }
    function drag(e) {
        e.preventDefault();
        e.stopPropagation();
    }
    function dragover(e) {
        e.currentTarget.classList.add("hov");
        e.preventDefault();
        e.stopPropagation();
    }
    function dragenter(e) {
        e.currentTarget.classList.add("hov");
        e.preventDefault();
        e.stopPropagation();
    }
    function dragend(e) {
        e.currentTarget.classList.remove("hov");
        e.preventDefault();
        e.stopPropagation();
    }
    function dragleave(e) {
        e.currentTarget.classList.remove("hov");
        e.preventDefault();
        e.stopPropagation();
    }
    function drop(e) {
        e.currentTarget.classList.remove("hov");
        e.preventDefault();
        e.stopPropagation();
        input.current.files = e.dataTransfer.files;
		const event = new Event('change', { bubbles: true });
		event.simulated = true;
		input.current.dispatchEvent(event);
    }
	function eliminar(id){
		setEliminarFotos(eliminar=>{
			return [...eliminar, id];
		})
		const aux = fotos.filter((x, i)=>{
			if(x.uid)
				return x.uid !== id;
			else
				return i !== id;
		}
		)
		if(aux.length <= indice){
			setIndice(aux.length-1);
		}
		setFotos(aux);
	}
	
    return(
		<>
			<div onClick={selecciona} className={'subir fotos'}
				onDragStart = {dragstart}
				onDrag = {drag}
				onDragEnd = {dragend}
				onDragOver = {dragover}
				onDragEnter = {dragenter}
				onDragLeave = {dragleave}
				onDrop = {drop}
				>
				{error.error &&
					<div className="error">{error.descripcion}</div>
				}
				<div className="arrastra">
					{!cargando &&
						<div className='lblSel'>Selecciona o arrastra el archivo</div>
					}
					{cargando &&
						<div className='cargando'>Subiendo...</div>
					}
					<input type='file' ref={input} accept={validTypes} onChange={cambiaEstado} multiple={true}/>
				</div>
			</div>
			{fotos?.length !== undefined && fotos?.length !== 0 &&
				<>
					<div className="ver">
						{id != 9 &&
							<img src={fotos[indice].url}/>
						}
						{id == 9 &&
							<video src={fotos[indice].url} controls/>
						}
					</div>
					<div className="miniaturas">
						{fotos.map((foto,i)=>{
							return(
								<div 
									key={foto.uid || i}
									data-uid={foto.uid || i}
									draggable={true}
									onDragStart={(e)=>dragstartHandler(e, i)}
									onDrop={(e)=>dropHandler(e, i)}
									onDragOver={dragoverHandler}
									className={indice == i ? 'sel' : ''}
									onClick={()=>{
									setIndice(i)
								}}>
									{id != 9 &&
										<img draggable={false} src={fotos[i].url}/>
									}
									{id == 9 &&
										<video draggable={false} src={fotos[i].url+"#t=1.00"}/>
									}
									<button className="eliminarFoto" onClick={(e)=>{
										e.preventDefault();
										e.stopPropagation();
										eliminar(foto.uid || i)
									}}>
										<img src={Quitar}/>
									</button>
								</div>
							)
						})
						}
					</div>
				</>
			}
		</>
    )
}


const MapComponent = ({ position, setPosition }) => {
	const Recenter = ({lat,lng}) => {
        const map = useMap();
        useEffect(() => {
            map.setView([lat, lng]);
        }, [lat, lng]);
        return null;
    }
    return (
		<MapContainer center={position} zoom={17} style={{ height: "100%", width: "100%" }}>
			<Recenter lat={position.lat} lng={position.lng} />
			<TileLayer
			url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
			/>
			<DraggableMarker position={position} setPosition={setPosition} />
		</MapContainer>
    );
};

const DraggableMarker = ({ position, setPosition}) => {
	const markerRef = useRef(null)

	const eventHandlers = useMemo(
	() => ({
		dragend() {
		const marker = markerRef.current
		if (marker != null) {
			setPosition(marker.getLatLng())
		}
		},
	}),
	[],
	)

	return position === null ? null : (
	  <Marker icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})} draggable={true} eventHandlers={eventHandlers} position={position} ref={markerRef}>
	  </Marker>
	);
  };
  
const HorariosAlmacen = ({almacen})=>{
	const [horarios, setHorarios] = useState(Object.keys(almacen?.hours).length ? almacen?.hours : {
		0 : {
			active: true,
			hours: [{
				start : '',
				end : '',
			}],
			duplicar: true,
		},
		1 : {
			active: true,
			hours: [{
				start : '',
				end : '',
			}],
			duplicar: true,
		},
		2 : {
			active: true,
			hours: [{
				start : '',
				end : '',
			}],
			duplicar: true,
		}
		,
		3 : {
			active: true,
			hours: [{
				start : '',
				end : '',
			}],
			duplicar: true,
		},
		4 : {
			active: true,
			hours: [{
				start : '',
				end : '',
			}],
			duplicar: true,
		},
		5 : {
			active: true,
			hours: [{
				start : '',
				end : '',
			}],
		},
		6 : {
			active: true,
			hours: [{
				start : '',
				end : '',
			}],
		}
	});

	useEffect(()=>{
		console.log(horarios);
		almacen.hours = horarios;
	},[horarios])

	const duplicar = ()=>{
		for(let k in horarios){
			if(horarios[k].duplicar){
				horarios[k] = JSON.parse(JSON.stringify(horarios[0]));
			}
		}
		setHorarios({...horarios});
	}

	return(
		<div className="horariosAlmacen">
			{Dias.map((dia, i)=>{
				return(
					<div key={i}>
						<label>
							<input type="checkbox" checked={horarios[i]?.active} onChange={(e)=>{
								horarios[i].active = e.target.checked;
								setHorarios({...horarios})
								if(i == 0){
									duplicar();
								}
								else{
									horarios[i].duplicar = false;
								}
							}}/>
							<span>{dia}</span>
						</label>
						<div className="horas">
							{horarios[i]?.hours.map((hour, j)=>{
								return(
									<div key={j}>
										<label>
											<input type="time" value={hour.start} onChange={(e)=>{
												hour.start = e.target.value;
												setHorarios({...horarios})
												if(i == 0){
													duplicar();
												}
												else{
													horarios[i].duplicar = false;
												}
											}}/>
											<span>Desde</span>
										</label>
										<span className="guion"> - </span>
										<label>
											<input type="time" value={hour.end} onChange={(e)=>{
												hour.end = e.target.value;
												setHorarios({...horarios})
												if(i == 0){
													duplicar();
												}
												else{
													horarios[i].duplicar = false;
												}
											}}/>
											<span>Hasta</span>
										</label>
										<div className="boton">
											{horarios[i]?.hours.length > 1 &&
												<button type="button" className="borrar" onClick={()=>{
													horarios[i].hours.splice(j, 1);
													setHorarios({...horarios})
													if(i == 0){
														duplicar();
													}
													else{
														horarios[i].duplicar = false;
													}
												}}>
													<img src={Quitar}/>
												</button>
											}
										</div>
									</div>
								)
							})
							}
						</div>
						<div>
							<button type="button" className="add" onClick={()=>{
								horarios[i].hours.push({start:'', end: ''})
								setHorarios({...horarios})
								if(i == 0){
									duplicar();
								}
								else{
									horarios[i].duplicar = false;
								}
							}}>
								<img src={Agregar}/>
							</button>
						</div>
					</div>
				)
			})}
		</div>
	)
}

export default InfoAlmacen;