import { initializeApp } from 'firebase/app';
import { baseUrl } from './config.js';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { fetchAuthSession } from 'aws-amplify/auth';

const vapidKey = 'BLkbfTErzT0Gko3tFr124eR2FIp75hYWBVOAUw4AZlMmB-OhVSma6ehgm1G82rVbXWKDmE-GPWGD-XyGNUJ2Dhw';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyBuopdy4VlW8GGL2JN77_hprH0sGiGSmUI",
    authDomain: "palletcloud.firebaseapp.com",
    projectId: "palletcloud",
    storageBucket: "palletcloud.appspot.com",
    messagingSenderId: "685037558444",
    appId: "1:685037558444:web:786677a5625a9e53b2dd09",
    measurementId: "G-WP04HS4YWV"
  };

const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

async function suscribir(currentToken){
  
    const token = (await fetchAuthSession()).tokens?.accessToken?.toString();
    
    fetch(baseUrl+"Notifications/Subscribe", {
    method: 'post',
    headers: {
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({token: currentToken})
    })
    .then(response=>response.json())
    .then(json=>{
    })
}

async function deviceToken(){
  return await getToken(messaging, { vapidKey }).then((currentToken) => {
    console.log(currentToken)
    if(currentToken){
        suscribir(currentToken);
    }
    else{

    }
    return currentToken
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // ...
  });
}

function unsubscribe(){
  getToken(messaging, { vapidKey }).then(async (currentToken) => {
    if (currentToken) {
      // Send the token to your server and update the UI if necessary
      // ...
      
      const token = (await fetchAuthSession()).tokens?.accessToken?.toString();

      console.log(currentToken)
      fetch(baseUrl+"Notifications/Unsusbscribe", {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({token: currentToken})
      })
      .then(response=>response.json())
      .then(json=>{
      })
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.');
      // ...
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // ...
  });
}

const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});

function requestPermission() {
    console.log('Requesting permission...');
    if (!'PushManager' in window){return false;}
    if (!'serviceWorker' in navigator){return false;}
    if (!'Notification' in window){return false;}
    return Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        suscribir();
      }
      else{
      }
      return permission;
    });
}
export {requestPermission, unsubscribe, onMessageListener, deviceToken};