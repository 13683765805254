import React, { useState, useRef, useEffect, useContext } from "react";
import { baseUrl } from '../../config.js'
import { Chart } from "react-google-charts";

import './Dashboards.css';

import useToken from "../../useToken.js";
import { formatoFecha, formatoFechaCorta, formatoMoneda, formatoHora, Meses } from '../../almacen.js';

import Created from '../../img/Created.png';
import Booked from '../../img/Booked.png';
import Canceled from '../../img/Canceled.png';
import Pending from '../../img/Pending.png';
import Partial from '../../img/Partial.png';
import FlechaGuia from '../../img/Guia.png';
import Cerrar from '../../img/Cerrar.png';

import { Link, NavLink, useLocation } from "react-router-dom";

const estatus = {
    'Created': 'Creada',
    'Booked': 'Reservada',
    'Canceled': 'Cancelada',
    'Pending': 'Pendiente',
    'Partial': 'Parcialmente pagado',
}

const Conds = {
    "0": "Exactamente",
    "1": "Entre",
    "2": "Más de",
    "3": "Menos de",
}
const CondsFecha = {
    "0": "",
    "1": "",
    "2": "A partir del",
    "3": "Antes del",
}

const DashEspacios = ({})=>{

    const location = useLocation();

    return(
        <div className="dashEspacios">
            <div className="movimientos">
                <div className="etiqueta">
                    <div>
                        <h3>Pagos</h3>
                        <Movimientos/>
                    </div>
                </div>
            </div>

        </div>
    )
}

const Movimientos = ({})=>{

    const { token } = useToken();
    const [tiempo, setTiempo] = useState('Month');
    const [data, setData] = useState([]);
    const [totales, setTotales] = useState({});
    const [filtros, setFiltros] = useState({status:'Total'});
    const [showFilter, setShowFilter] = useState('');
    const [fecha, setFecha] = useState(new Date())
    const [cargando, setCargando] = useState(false)
    const modFiltrar = useRef();

    const imgEstatus = {
        'Created': Created,
        'Booked': Booked,
        'Canceled': Canceled,
        'Pending': Pending,
        'Partial': Partial,
    }

    useEffect(()=>{
        if(token){
            getData();
        }
    },[token, filtros])

    const handleClickOutside = (event) => {
        if (modFiltrar.current && !modFiltrar.current.contains(event.target)) {
            setShowFilter('');
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const getData = ()=>{
        setCargando(true);
        fetch(baseUrl+'Info/RecentMovements',{
            method: 'post',
            headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
            body: JSON.stringify(filtros)
        })
        .then(response=>response.json())
		.then(json=>{
            setData(json?.data || [])
            setTotales(json?.total || {});
            setCargando(false);
		})
		.catch(error=>{
			console.error(error);
            setCargando(false);
		})
    }
    return(
        <>
            
            <div className="tipos pagos">
                <button
                    className={filtros?.status == 'Total' ? 'sel' : ''}
                    onClick={()=>{
                        setFiltros({...filtros, status: 'Total'});
                    }}
                >
                    <span>Todos</span>
                    <h2>{totales?.Total || 0}</h2>
                </button>
                <button
                    className={filtros?.status == 'Booked' ? 'sel' : ''}
                    onClick={()=>{
                        setFiltros({...filtros, status: 'Booked'});
                    }}
                >
                    <span>Exitosos</span>
                    <h2>{totales?.Booked || 0}</h2>
                </button>
                <button
                    className={filtros?.status == 'Pending' ? 'sel' : ''}
                    onClick={()=>{
                        setFiltros({...filtros, status: 'Pending'});
                    }}
                >
                    <span>Pendientes</span>
                    <h2>{totales?.Pending || 0}</h2>
                </button>
                <button
                    className={filtros?.status == 'Canceled' ? 'sel' : ''}
                    onClick={()=>{
                        setFiltros({...filtros, status: 'Canceled'});
                    }}
                >
                    <span>Cancelados</span>
                    <h2>{totales?.Canceled || 0}</h2>
                </button>
                <button
                    className={filtros?.status == 'Created' ? 'sel' : ''}
                    onClick={()=>{
                        setFiltros({...filtros, status: 'Created'});
                    }}
                >
                    <span>Creados</span>
                    <h2>{totales?.Created || 0}</h2>
                </button>
            </div>
            <div className="filtrosPago">
                <span>
                    <button onClick={()=>{
                        setShowFilter('Importe')
                    }}>
                        {filtros?.price?.cond !== undefined &&
                            <button className="borrar" onClick={(e)=>{
                                e.preventDefault();
                                e.stopPropagation();
                                setFiltros({...filtros, price:undefined})
                            }}>
                                <img src={Cerrar}/>
                            </button>
                        }
                        <span>Importe</span>
                        {filtros?.price?.cond !== undefined && filtros.price.cond != "1" &&
                            <span className="lblFiltro">{Conds[filtros.price.cond]+" $"+formatoMoneda(filtros.price.values[0]*1)}</span>
                        }
                        {filtros?.price?.cond !== undefined && filtros.price.cond == "1" &&
                            <span className="lblFiltro">{"$"+formatoMoneda(filtros.price.values[0]*1)+" a $"+formatoMoneda(filtros.price.values[1]*1)}</span>
                        }
                    </button>
                    {showFilter == 'Importe' &&
                        <FiltrarImporte setShowFilter={setShowFilter} filtros={filtros} setFiltros={setFiltros} modFiltrar={modFiltrar}/>
                    }
                </span>
                <span>
                    <button onClick={()=>{
                        setShowFilter('Estatus')
                    }}>
                        {filtros?.statuses &&
                            <button className="borrar" onClick={(e)=>{
                                e.preventDefault();
                                e.stopPropagation();
                                setFiltros({...filtros, statuses:undefined})
                            }}>
                                <img src={Cerrar}/>
                            </button>
                        }
                        <span>Estatus</span>
                        {filtros?.statuses &&
                            <span className="lblFiltro">
                                {filtros?.statuses?.map(x=>{
                                    return estatus[x];
                                }).join(", ")}
                            </span>
                        }
                    </button>
                    {showFilter == 'Estatus' &&
                        <FiltrarEstatus setShowFilter={setShowFilter} filtros={filtros} setFiltros={setFiltros} modFiltrar={modFiltrar}/>
                    }
                </span>
                <span>
                    <button onClick={()=>{
                        setShowFilter('Fecha')
                    }}>
                        {filtros?.dates?.cond !== undefined &&
                            <button className="borrar" onClick={(e)=>{
                                e.preventDefault();
                                e.stopPropagation();
                                setFiltros({...filtros, dates:undefined})
                            }}>
                                <img src={Cerrar}/>
                            </button>
                        }
                        <span>Fecha</span>
                        {filtros?.dates?.cond !== undefined && filtros.dates.cond != "1" &&
                            <span className="lblFiltro">{CondsFecha[filtros.dates.cond]+" "+formatoFechaCorta(new Date(filtros.dates.values[0].split("-")))}</span>
                        }
                        {filtros?.dates?.cond !== undefined && filtros.dates.cond == "1" &&
                            <span className="lblFiltro">{formatoFechaCorta(new Date(filtros.dates.values[0].split("-")))+" al "+formatoFechaCorta(new Date(filtros.dates.values[1].split("-")))}</span>
                        }
                    </button>
                    {showFilter == 'Fecha' &&
                        <FiltrarFecha setShowFilter={setShowFilter} filtros={filtros} setFiltros={setFiltros} modFiltrar={modFiltrar}/>
                    }
                </span>
            </div>
            <table className={"tabla pagos"+(cargando ? " cargandoTabla" : "")}>
            <tbody>
                {data?.length !== 0 &&
                    <tr className='header'>
                        <th>Importe (MXN)</th>
                        <th>Estatus</th>
                        <th>Almacén</th>
                        <th>Cliente</th>
                        <th>Correo</th>
                        <th>Espacios</th>
                        <th>Inicio</th>
                        <th>Fin</th>
                        <th>Fecha</th>
                    </tr>
                }
                {data?.length === 0 &&
                    <tr>
                        <td>No hay movimientos recientes</td>
                    </tr>
                }
                {data?.map((fila, i)=>{
                    let auxFecha = formatoFechaCorta(new Date(fila?.mainDate?.split("T")[0].split("-")));
                    if(auxFecha == formatoFechaCorta(new Date())){
                        auxFecha = "Hoy";
                    }
                    const hora = formatoHora(new Date(fila.mainDate));
                    if(fila.transaction>0)
                    return(
                        <tr key={i}>
                            <td>${formatoMoneda(fila.price*1)}</td>
                            <td>
                                <span className={fila.status}>
                                    <span>{estatus[fila.status]}</span>
                                    <img src={imgEstatus[fila.status]}/>
                                </span>
                            </td>
                            <td>
                                <Link to={'/host/almacenes/'+fila.idStore}>{fila.name}</Link>
                            </td>
                            <td>{fila.nameUser+ " " +fila.lastname}</td>
                            <td>{fila.email}</td>
                            <td className="mas">{fila.transaction}</td>
                            <td>{formatoFechaCorta(new Date(fila.startDate.split("T")[0].split("-")))}</td>
                            <td>{formatoFechaCorta(new Date(fila.endDate.split("T")[0].split("-")))}</td>
                            <td>
                                <span>{auxFecha} {hora}</span>
                            </td>
                        </tr>
                    )
                })
                }
            </tbody>
            </table>
        </>
    )
}

const FiltrarImporte = ({setShowFilter, filtros, setFiltros, modFiltrar})=>{
    const [tipo, setTipo] = useState(filtros?.price?.cond || 0);
    const value1 = useRef();
    const value2 = useRef();

    useEffect(()=>{
        value1.current.value = filtros?.price?.values[0];
        if(value2?.current)
            value2.current.value = filtros?.price?.values[1];
    }, [filtros])

    const aplicarFiltros = ()=>{
        if(!(
            value1?.current?.reportValidity() &&
            (!value2?.current || value2?.current?.reportValidity())
        )){
            return false;
        }

        setFiltros(filtros=> {
            return {...filtros, price: {
                cond: tipo,
                values: [value1.current.value, value2?.current?.value]
            }}
        })

        setShowFilter(false);
    }
    return(
        <div className="modFiltrar" ref={modFiltrar}>
            <h3>Filtrar por Importe</h3>
            <div>
                <select value={tipo} onChange={(e)=>{
                    setTipo(e.target.value)
                }}>
                    <option value='0'>Igual a</option>
                    <option value='1'>Entre </option>
                    <option value='2'>Mayor que</option>
                    <option value='3'>Menor que</option>
                </select>
            </div>
            <div className="defineRango">
                <label className="rango">
                    <img src={FlechaGuia}/>
                    <input type="number" ref={value1} required/>
                </label>
                {tipo == '1' &&
                    <>
                        <span className="guion"> — </span>
                        <label className="rango">
                            <input type="number" ref={value2} required/>
                        </label>
                    </>
                }
            </div>
            <div>
                <button className="btn" onClick={aplicarFiltros}>Aplicar</button>
            </div>
        </div>
    )
}

const FiltrarEstatus = ({setShowFilter, filtros, setFiltros, modFiltrar})=>{
    
    const [selected, setSelected] = useState(filtros?.statuses || []);
    
    const aplicarFiltros = ()=>{
        if(selected.length === 0){
            setFiltros(filtros=> {
                return {...filtros, statuses: undefined}
            })
        }
        else{
            setFiltros(filtros=> {
                return {...filtros, statuses: selected}
            })
        }

        setShowFilter(false);
    }

    return(
        <div className="modFiltrar" ref={modFiltrar}>
            <h3>Filtrar por Estatus</h3>
            <div className="lista">
                {Object.keys(estatus).map(x=>{
                    console.log(selected, x);
                    return(
                        <label key={x}>
                            <input type="checkbox" checked={selected.includes(x)} onChange={(e)=>{
                                if(selected.includes(x)){
                                    selected.splice(selected.indexOf(x), 1)
                                    setSelected([...selected])
                                }
                                else{
                                    selected.push(x)
                                    setSelected([...selected])
                                }
                            }}/>
                            <span>{estatus[x]}</span>
                        </label>
                    )
                })
                }
            </div>
            <div>
                <button className="btn" onClick={aplicarFiltros}>Aplicar</button>
            </div>
        </div>
    )
}
const FiltrarFecha = ({setShowFilter, filtros, setFiltros, modFiltrar})=>{
    const [tipo, setTipo] = useState(filtros?.dates?.cond || 0);
    const value1 = useRef();
    const value2 = useRef();

    useEffect(()=>{
        value1.current.value = filtros?.dates?.values[0];
        if(value2?.current)
            value2.current.value = filtros?.dates?.values[1];
    }, [filtros])

    const aplicarFiltros = ()=>{
        if(!(
            value1?.current?.reportValidity() &&
            (!value2?.current || value2?.current?.reportValidity())
        )){
            return false;
        }

        setFiltros(filtros=> {
            return {...filtros, dates: {
                cond: tipo,
                values: [value1.current.value, value2?.current?.value]
            }}
        })

        setShowFilter(false);
    }
    return(
        <div className="modFiltrar" ref={modFiltrar}>
            <h3>Filtrar por Fecha</h3>
            <div>
                <select value={tipo} onChange={(e)=>{
                    setTipo(e.target.value)
                }}>
                    <option value='0'>Igual a</option>
                    <option value='1'>Entre </option>
                    <option value='2'>Mayor que</option>
                    <option value='3'>Menor que</option>
                </select>
            </div>
            <div className="defineRango">
                <label className="rango">
                    <img src={FlechaGuia}/>
                    <input type="date" ref={value1} required/>
                </label>
                {tipo == '1' &&
                    <>
                        <span className="guion"> — </span>
                        <label className="rango">
                            <input type="date" ref={value2} required/>
                        </label>
                    </>
                }
            </div>
            <div>
                <button className="btn" onClick={aplicarFiltros}>Aplicar</button>
            </div>
        </div>
    )
}

export default DashEspacios;