// tipos almacen
import Seco from './img/defaultAlmacen.png';
import Congelado from './img/Congelador.png';
import Refrigerado from './img/Refrigerado.png';
import TemperaturaControlada from './img/TemperaturaControlada.png';
import GradoAlimenticio from './img/GradoAlimenticio.png';
import GradoFarmaceutico from './img/GradoFarmaceutico.png';
import Hazmat from './img/Hazmat.png';
import CrossDock from './img/CrossDock.png';
import Fulfillment from './img/Fulfillment.png';
import Fiscal from './img/Fiscal.png';
import Piso from './img/Piso.png';

// servicios básicos
import Oficinas from './img/Oficinas.png';
import Baño from './img/Baño.png';
import Wifi from './img/Wifi.png';
import CCTV from './img/CCTV.png';

// servicios ocupacionales
import In from './img/defaultAlmacen.png';
import Out from './img/defaultAlmacen.png';
import Surtido from './img/defaultAlmacen.png';
import Fullfilment from './img/defaultAlmacen.png';
import Maquila from './img/defaultAlmacen.png';
import Control from './img/defaultAlmacen.png';
import WMS from './img/defaultAlmacen.png';
import Logística from './img/defaultAlmacen.png';
import IMMEX from './img/defaultAlmacen.png';
import Cross from './img/defaultAlmacen.png';
import Acuerdo from './img/defaultAlmacen.png';

// transportes

import Moto from './img/Moto.png';
import Auto from './img/Auto.png';
import Van from './img/Van.png';
import VanCamioneta from './img/VanCamioneta.png';
import Camioneta from './img/Camioneta.png';
import Rabon from './img/Rabon.png';
import Torton from './img/Torton.png';
import Trailer from './img/Trailer.png';
import Full from './img/Full.png';

// rampa y anden
import Rampa from './img/Rampa.png';
import Anden from './img/Rampa.png';
import Inmediata from './img/Time.png';

// estado reservacion
import Created from './img/Created.png';
import Booked from './img/Booked.png';
import Canceled from './img/Canceled.png';
import Pending from './img/Pending.png';
import Partial from './img/Partial.png';
import Waiting from './img/Created.png';
import Checked from './img/Checked.png';
import Terminado from './img/Terminado.png';
import EnCurso from './img/EnCurso.png';
import Iniciar from './img/Iniciar.png';


export const Dias = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];


export const estatusPublicacion = {
    0 : 'Sin Publicar',
    1 : 'Publicada',
    2 : 'En Pausa',
}

export const estatus = {
    'Ongoing': 'En Curso',
    'Upcoming': 'Próxima',
    'Ended': 'Terminada',
	'Created': 'Creada',
	'Waiting': 'Esperando Aprobación',
	'Booked': 'Reservada',
	'Canceled': 'Cancelada',
	'Pending': 'Pendiente',
	'Partial': 'Parcialmente pagado',
	'Approved': 'Aprobada por el Host',
}

export const imgEstatus = {
	'Created': Created,
	'Waiting': Waiting,
	'Booked': Booked,
	'Canceled': Canceled,
	'Pending': Pending,
	'Partial': Partial,
	'Approved': Checked,
	'Ended': Terminado,
    'Ongoing': EnCurso,
	'Upcoming': Iniciar,
}

export const ImagenesTransporte = {
    1 : Moto,
    2 : Auto,
    3 : Van,
    4 : VanCamioneta,
    5 : Camioneta,
    6 : Rabon,
    7 : Torton,
    8 : Trailer,
    9 : Full,
}

export const ids = {
    "seco" : 1,
    "congelado" : 2,
    "refrigerado" : 3,
    "temperaturaControlada" : 4,
    "gradoFarmaceutico" : 5,
    "hazmat" : 6,
    "crossDock" : 7,
    "fulfillment" : 8,
    "fiscal" : 9,
    "piso": 10,
    "gradoAlimenticio" : 11,
}
export const idsR = {
    "1" : "seco",
    "2" : "congelado",
    "3" : "refrigerado",
    "4" : "temperaturaControlada",
    "5" : "gradoFarmaceutico",
    "6" : "hazmat",
    "7" : "crossDock",
    "8" : "fulfillment",
    "9" : "fiscal",
    "10" : "piso",
    "11" : "gradoAlimenticio",
}
export const idsRC = {
    "1" : "Seco",
    "2" : "Congelado",
    "3" : "Refrigerado",
    "4" : "TemperaturaControlada",
    "5" : "GradoFarmaceutico",
    "6" : "Hazmat",
    "7" : "CrossDock",
    "8" : "Fulfillment",
    "9" : "Fiscal",
    "10" : "Piso",
    "11" : "GradoAlimenticio",
}
export const nombres = {
    "1" : "Seco",
    "2" : "Congelado",
    "3" : "Refrigerado",
    "4" : "Temperatura Controlada",
    "5" : "Grado Farmacéutico",
    "6" : "Hazmat",
    "7" : "CrossDock",
    "8" : "Fulfillment",
    "9" : "Fiscal",
    "10" : "Piso",
    "11" : "Grado Alimenticio",
}
export const tipoAlmacen = {
    "1" : {
        nombre: "Seco",
        descripcion : "Perfecto para mercancías que no requieren un control de temperatura específico. El almacén seco ofrece un ambiente seguro y limpio para almacenar tus productos.",
    },
    "2" : {
        nombre: "Congelado",
        descripcion : "Ideal para productos que necesitan mantenerse a temperaturas bajo cero. El almacén congelado garantiza la frescura y calidad de tus productos.",
    },
    "3" : {
        nombre: "Refrigerado",
        descripcion : "Mantén tus productos frescos y en perfectas condiciones con el almacén refrigerado. Ideal para alimentos, medicamentos y más.",
    },
    "4" : {
        nombre: "Temperatura Controlada",
        descripcion : "Para productos que requieren una temperatura específica, se cuenta con control de temperatura preciso.",
    },
    "11" : {
        nombre: "Grado Alimenticio",
        descripcion : "Almacén especializado para productos de grado Alimenticio, garantizando que se mantengan en condiciones óptimas y seguras.",
    },
    "5" : {
        nombre: "Grado Farmacéutico",
        descripcion : "Almacén especializado para productos farmacéuticos, garantizando que se mantengan en condiciones óptimas y seguras.",
    },
    "6" : {
        nombre: "Hazmat",
        descripcion : "Almacén diseñado para el almacenamiento seguro de materiales peligrosos, con todas las medidas de seguridad necesarias.",
    },
    "7" : {
        nombre: "CrossDock",
        descripcion : "Mejora la eficiencia de tu cadena de suministro con nuestro servicio de CrossDocking, que permite la transferencia directa de productos entre vehículos de transporte.",
    },
    "8" : {
        nombre: "Fulfillment",
        descripcion : "Cuenta con servicio de cumplimiento integral que se encarga de todo el proceso de almacenamiento, empaquetado y envío de tus productos.",
    },
    "9" : {
        nombre: "Fiscal",
        descripcion : "El almacén fiscal te permite almacenar mercancías importadas sin tener que pagar impuestos hasta que se vendan o se retiren del almacén.",
    },
    "10" : {
        nombre: "Piso",
        descripcion : "Si necesitas un espacio amplio y accesible para almacenar tus productos, el almacén de piso es la solución perfecta.",
    },
}

export const idServicios = {
    "in": 1,
    "out": 2,
    "surtido": 3,
    "fullfilment": 7,
    "oficinas": 9,
    "wifi": 9,
    "wc": 10,
    "cctv": 11,
    "maquila": 12,
    "oficinas": 13,
    "control": 14,
    "wms": 15,
    "logistica": 16,
    "immex": 17,
    "crossborder": 18,
    "acuerdo": 19,
}
export const idServiciosR = {
    1 : "in",
    2 : "out",
    3 : "surtido",
    7 : "fullfilment",
    9 : "wifi",
    10 : "wc",
    11 : "cctv",
    12 : "maquila",
    13 : "oficinas",
    14 : "control",
    15 : "wms",
    16 : "logistica",
    17 : "immex",
    18 : "crossborder",
    19 : "acuerdo",
}

export const idServiciosRC = {
    1 : "In",
    2 : "Out",
    3 : "Surtido",
    7 : "Fullfilment",
    9 : "Wifi",
    10 : "Baño",
    11 : "CCTV",
    12 : "Maquila",
    13 : "Oficinas",
    14 : "Control de Inventarios",
    15 : "WMS",
    16 : "Logística Inversa",
    17 : "IMMEX",
    18 : "Cross Border",
    19 : "Acuerdo 321",
}

export const Imagenes = {
    Seco,
    Congelado,
    Refrigerado,
    TemperaturaControlada,
    GradoAlimenticio,
    GradoFarmaceutico,
    Hazmat,
    CrossDock,
    Fulfillment,
    Fiscal,
    Piso,
    Baño,
    Wifi,
    CCTV,
    In,
    Out,
    Surtido,
    Fullfilment,
    Maquila,
    Oficinas,
    Rampa,
    Anden,
    Inmediata,
    Control,
    WMS,
    Logística,
    IMMEX,
    Cross,
    Acuerdo,
}
export function formatoFecha(date){
    if(!date)
        return "";
    let day = date.getDate() < 10 ? "0"+date.getDate() : date.getDate();
    let month = date.getMonth() + 1 < 10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
    let year = date.getFullYear();
    return year + "-" + month + "-" + day;
}

export function formatoFechaTxt(date){
    const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    let day = date.getDate() < 10 ? "0"+date.getDate() : date.getDate();
    let month = meses[date.getMonth()];
    let year = date.getFullYear();
    return day + " de " + month +" de "+year;
}

export function formatoFechaCorta(date){
    const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    let day = date.getDate();
    let month = meses[date.getMonth()];
    let year = date.getFullYear();
    return day + " " + month +". "+year;
}

export function formatoMoneda(numero){
    if(!numero || isNaN(numero)){
        return "0.00";
    }
    return numero.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export function formatoHora(date){
    let hora = date.getHours() < 10 ? "0"+date.getHours() : date.getHours();
    let minutos = date.getMinutes() < 10 ? "0"+date.getMinutes() : date.getMinutes();
    let segundos = date.getSeconds() < 10 ? "0"+date.getSeconds() : date.getSeconds();
    return hora + ":" + minutos;
}

export function formatoNumero(numero){
    if(!numero){
        return '0.00';
    }
    return numero.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}
export function formatoNumeroEntero(numero){
    if(!numero){
        return 0;
    }
    const aux = numero.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    if(aux.includes(".00")){
        return aux.split(".")[0]
    }
    return aux;
}

export const Meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

export const Estados = {
    "1": {
        codigo: 'MX-AGU',
        estado: 'Aguascalientes',
    },
    "2": {
        codigo: 'MX-BCN',
        estado: 'Baja California',
    },
    "3": {
        codigo: 'MX-BCS',
        estado: 'Baja California Sur',
    },
    "4": {
        codigo: 'MX-CAM',
        estado: 'Campeche',
    },
    "9": {
        codigo: 'MX-DIF',
        estado: 'Ciudad de México',
    },
    "5": {
        codigo: 'MX-COA',
        estado: 'Coahuila',
    },
    "6": {
        codigo: 'MX-COL',
        estado: 'Colima',
    },
    "7": {
        codigo: 'MX-CHP',
        estado: 'Chiapas',
    },
    "8": {
        codigo: 'MX-CHH',
        estado: 'Chihuahua',
    },
    "10": {
        codigo: 'MX-DUR',
        estado: 'Durango',
    },
    "15": {
        codigo: 'MX-MEX',
        estado: 'México',
    },
    "11": {
        codigo: 'MX-GUA',
        estado: 'Guanajuato',
    },
    "12": {
        codigo: 'MX-GRO',
        estado: 'Guerrero',
    },
    "13": {
        codigo: 'MX-HID',
        estado: 'Hidalgo',
    },
    "14": {
        codigo: 'MX-JAL',
        estado: 'Jalisco',
    },
    "16": {
        codigo: 'MX-MIC',
        estado: 'Michoacán',
    },
    "17": {
        codigo: 'MX-MOR',
        estado: 'Morelos',
    },
    "18": {
        codigo: 'MX-NAY',
        estado: 'Nayarit',
    },
    "19": {
        codigo: 'MX-NLE',
        estado: 'Nuevo León',
    },
    "20": {
        codigo: 'MX-OAX',
        estado: 'Oaxaca',
    },
    "21": {
        codigo: 'MX-PUE',
        estado: 'Puebla',
    },
    "22": {
        codigo: 'MX-QUE',
        estado: 'Querétaro',
    },
    "23": {
        codigo: 'MX-ROO',
        estado: 'Quintana Roo',
    },
    "24": {
        codigo: 'MX-SLP',
        estado: 'San Luis Potosí',
    },
    "25": {
        codigo: 'MX-SIN',
        estado: 'Sinaloa',
    },
    "26": {
        codigo: 'MX-SON',
        estado: 'Sonora',
    },
    "27": {
        codigo: 'MX-TAB',
        estado: 'Tabasco',
    },
    "28": {
        codigo: 'MX-TAM',
        estado: 'Tamaulipas',
    },
    "29": {
        codigo: 'MX-TLA',
        estado: 'Tlaxcala',
    },
    "30": {
        codigo: 'MX-VER',
        estado: 'Veracruz',
    },
    "31": {
        codigo: 'MX-YUC',
        estado: 'Yucatán', 
    },
    "32": {
        codigo: 'MX-ZAC',
        estado: 'Zacatecas',
    },
}