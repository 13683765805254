import React, { Children, useEffect, useRef, useState } from "react";
import { appUrl, baseUrl } from '../../config.js'
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import {Elements, PaymentElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import useToken from '../../useToken';
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';

import { formatoFecha, formatoFechaTxt, estatus, imgEstatus, formatoNumero } from '../../almacen.js';
import Cargando from '../../img/Cargando.gif';
import Tarjeta from '../../img/Tarjeta.png';
import Transferencia from '../../img/Transferencia.png';
import Copiar from '../../img/Copiar.png';
import './Reservar.css';

const stripePromise = loadStripe('pk_test_mvWe2DA5Ez2FX37mx2FIaMd2003sG0WdPw');

const Reservar = ({user, setModalLogin})=>{
    const {token} = useToken();

    const { id } = useParams();
    const navigate = useNavigate();
    const [config, setConfig] = useState(localStorage.getItem('config') ? JSON.parse(localStorage.getItem('config')) : {
        idioma : 'es',
        sistema : 'internacional',
      })
    const [reserva, setReserva] = useState({TypeTime:[]});
    const [calendario, setCalendario] = useState(false);
    const [calendarioF, setCalendarioF] = useState(false);
    const hoy = new Date();
    const [fechaI, setFechaI] = useState(new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate()));
    const [fechaF, setFechaF] = useState();
    const [dias, setDias] = useState();
    const [costo, setCosto] = useState();
    const [pagar, setPagar] = useState(false);
    const [clientSecret, setClientSecret] = useState('');
    const [orden, setOrden] = useState("");
    const [espacios, setEspacios] = useState(1);
    const [error, setError] = useState("");
    const [cargando, setCargando] = useState(false);
    const [metodo, setMetodo] = useState('Transferencia');
    const [progreso, setProgreso] = useState(0);
    const [editable, setEditable] = useState(false);
    
    const initialOptions = {
        "client-id": "AarGeFRjhFzD1Fp7DPzlHm8E4_JWuJI2ZpMSs10rypLyFakDs8sufriwyi_dWxpdQNtGvhLDdqymxtpa",
        // "client-id": "AYeWKjBJ41pbIrSwdjMP1tCwQafwVdImxRyrGg_clecHD8r4HWmnr__pkOmbhC-0gTUSkk12Z5w3qVNa",
        currency: "MXN",
        intent: "capture",
    };
    
    useEffect(()=>{
        if(token)
            getData();
    },[id, token])
    
    useEffect(()=>{
        console.log(fechaI, fechaF);
        if(fechaF && fechaI){
            const intervalo = Math.ceil((fechaF.getTime() - fechaI.getTime())/86400000)+1;
            setDias(intervalo)
            
            let total = calculaPrecio();

            total = Math.round(total*100)/100;
            setCosto(total*espacios);
        }
    }, [fechaI, fechaF])
    
    useEffect(()=>{
        if(fechaF && fechaI){
            let total = calculaPrecio();
            console.log(total);
            total = Math.round(total*100)/100;
            setCosto(total*espacios);
        }
    }, [espacios])

    useEffect(()=>{
        if(orden)
            verifica();
    },[orden])
    function verifica(){
        fetch(baseUrl+"Booking/validate/"+orden, {
			headers:{
				'Authorization': `Bearer ${token}`,
			}
		})
        .then(response=> response.json())
        .then(json=>{
            if(json["status"] == "COMPLETED"){
                navigate("/completado/"+id);
            }
            else{
                setTimeout(function(){
                    verifica();
                }, 5000)
            }
            //si es true ya quedó y redirigimos, si no, llamamos un settimeout
        });
    }
    
    const getData = async ()=>{
        const result1 = await fetch(baseUrl+"Booking/"+id, {
			headers:{
				'Authorization': `Bearer ${token}`,
			}
		})
		.then(response=>response.json())
		.then(json=>{
			console.log(json);
			return json;
		})
		.catch(error=>{
			console.error(error);
		})
		setReserva(result1?.data || {TypeTime:[]});
        setFechaI(new Date(result1?.data?.startDate.split("T")[0].split("-")));
		setFechaF(new Date(result1?.data?.endDate.split("T")[0].split("-")));
        setPagar(!result1?.data.inmediate && result1?.data.inmediate != 0);
        if(result1?.data?.status == 'Approved' || result1?.data?.status == 'Booked' || result1?.data?.status == 'Partial' || result1?.data?.status == 'Waiting'){
            setEditable(false);
        }
        else{
            setEditable(true);
        }
		setEspacios(result1?.data?.spaces);
        if(result1?.data.status == 'Partial'){
            setProgreso(1)
        }
        else if(result1?.data.status == 'Approved'){
            setProgreso(2)
        }
        else if(result1?.data.status == 'Waiting'){
            setProgreso(1)
        }
        else if(result1?.data.status == 'Booked'){
            setProgreso(3)
        }
    }

    const copiar = (valor)=>{
        navigator.clipboard.writeText(valor);
    }

    function calculaPrecio(){
        const aux = new Date(fechaI.getTime());
        let tiempo = 0;

        if(reserva.TypeTime.includes("4") && fechaF.getTime() >= aux.setFullYear(fechaI.getFullYear()+1) ){
            // el costo será por años
            console.log(4)
            tiempo++;
            while(fechaF.getTime() > aux.setFullYear(aux.getFullYear()+1)){
                console.log(aux.getTime(), fechaF.getTime())
                tiempo++;
            }
            aux.setFullYear(aux.getFullYear()-1);
            const diferenciaDias = Math.ceil((fechaF.getTime() - aux)/86400000)+1;
            console.log(diferenciaDias);
            tiempo += diferenciaDias/(fechaF.getFullYear() % 4 == 0 ? 366 : 365);
            console.log(tiempo);
            return reserva.year*tiempo;
        }
        else{
            aux.setFullYear(fechaI.getFullYear())
            if(reserva.TypeTime.includes("3") && fechaF.getTime() >= aux.setMonth(fechaI.getMonth()+1)){
                // el costo será por meses
                console.log(3)
                tiempo++;
                while(fechaF.getTime() > aux.setMonth(aux.getMonth()+1)){
                    console.log(aux.getTime(), fechaF.getTime())
                    tiempo++;
                }
                aux.setMonth(aux.getMonth()-1);
                const diferenciaDias = Math.ceil((fechaF.getTime() - aux)/86400000)+1;
                console.log(diferenciaDias);
                const diasDelMes = new Date(aux.getFullYear(), aux.getMonth()+1, 0).getDate();
                tiempo += diferenciaDias/diasDelMes;
                console.log(tiempo);
                return reserva.month*tiempo;
            }
            else{
                aux.setMonth(fechaI.getMonth())
                if(reserva.TypeTime.includes("2") && fechaF.getTime() >= aux.setDate(fechaI.getDate()+7)){
                    // costo por semana
                    console.log(2)
                    tiempo++;
                    console.log(formatoFechaTxt(aux));
                    while(fechaF.getTime() > aux.setDate(aux.getDate()+7)){
                        console.log(formatoFecha(new Date(aux)));
                        tiempo++;
                    }
                    aux.setDate(aux.getDate()-7);
                    const auxFechaI = new Date(aux.getFullYear(), aux.getMonth(), aux.getDate());
                    const diferenciaDias = Math.ceil((fechaF.getTime() - aux)/86400000)+1;
                    console.log(diferenciaDias);
                    tiempo += diferenciaDias/7;
                    console.log(tiempo);
                    return reserva.week*tiempo;
                }
                else{
                    // costo por día
                    console.log(1)
                    tiempo += Math.ceil((fechaF.getTime() - fechaI.getTime())/86400000)+1;
                    console.log(tiempo);
                    return reserva.day*tiempo;
                }
            }
        }
    }
    const reservar = () =>{
        setCargando(true);
        if(espacios <= 0){
            setError("Selecciona la cantidad de espacios");
            setCargando(false);
            return false;
        }
        if(!fechaI || !fechaF){
            setError("Elige un intervalo de fechas correcto");
            setCargando(false);
            return false;
        }
        const data = {
            idBooking : id,
            startDate : fechaI || '',
            endDate : fechaF || '',
            spaces : espacios || '',
            price : costo || '',
            method : metodo == 'Tarjeta' ? 'card' : 'customer_balance',
        }
        fetch(baseUrl+"Booking", {
			method: "put",
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
			body: JSON.stringify(data),
		})
		.then(response=>response.json())
		.then(json=>{
			console.log(json);
            setPagar(true);
            setProgreso(1);
            setClientSecret(json?.clientSecret);
		})
		.catch(error=>{
            setCargando(false);
			console.error(error);
		})
    }

    const solicitarReservacion = () =>{
        setCargando(true);
        if(espacios <= 0){
            setError("Selecciona la cantidad de espacios");
            setCargando(false);
            return false;
        }
        if(!fechaI || !fechaF){
            setError("Elige un intervalo de fechas correcto");
            setCargando(false);
            return false;
        }
        const data = {
            idBooking : id,
            startDate : fechaI || '',
            endDate : fechaF || '',
            spaces : espacios || '',
            price : costo || '',
        }
        fetch(baseUrl+"AskBooking/"+id, {
			method: "put",
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
			body: JSON.stringify(data),
		})
		.then(response=>response.json())
		.then(json=>{
			console.log(json);
            setCargando(false);
            reserva.status='Waiting';
            setReserva({...reserva});
            setPagar(true);
            setProgreso(1);
		})
		.catch(error=>{
            setCargando(false);
			console.error(error);
		})
    }

    return(
        <div className={"booking" + (!reserva?.idBooking ? " placeholder" : "")}>
            <Progreso progreso={progreso} inmediate={reserva?.inmediate}/>
            {reserva?.status == 'Canceled' &&
                <div className="cancelado">Está reservación fue cancelada</div>
            }
            <h1>Detalles de tu reservación</h1>
            <div className="divEstatus">
                <span className={reserva?.status}>
                    {estatus[reserva?.status]}
                    <img src={imgEstatus[reserva?.status]}/>
                </span>
            </div>
            <div className="gridBooking">
                <div className="datosReserva">
                    {(reserva?.status == 'Pending' || reserva?.status == 'Partial') && reserva?.paymentIntent?.next_action &&
                        <div className="etiqueta">
                            <h4>Completa tu pago por:
                                <h2>${formatoNumero(reserva.paymentIntent.next_action.display_bank_transfer_instructions.amount_remaining/100)} ({reserva.paymentIntent.next_action.display_bank_transfer_instructions.currency.toUpperCase()})</h2>
                            </h4>
                            <div className="completarPago">
                                <div>
                                    <span>CLABE</span>
                                    <span>{reserva.paymentIntent.next_action.display_bank_transfer_instructions.financial_addresses[0].spei.clabe}</span>
                                    <span onClick={()=>copiar(reserva.paymentIntent.next_action.display_bank_transfer_instructions.financial_addresses[0].spei.clabe)}>
                                        <img src={Copiar}/>
                                    </span>
                                </div>
                                <div>
                                    <span>Beneficiario</span>
                                    <span>PalletCloud</span>
                                    <span onClick={()=>copiar('PalletCloud')}>
                                        <img src={Copiar}/>
                                    </span>
                                </div>
                                <div>
                                    <span>Referencia</span>
                                    <span>{reserva.paymentIntent.next_action.display_bank_transfer_instructions.reference}</span>
                                    <span onClick={()=>copiar(reserva.paymentIntent.next_action.display_bank_transfer_instructions.reference)}>
                                        <img src={Copiar}/>
                                    </span>
                                </div>
                                <div>
                                    <span>Nombre del Banco</span>
                                    <span>{reserva.paymentIntent.next_action.display_bank_transfer_instructions.financial_addresses[0].spei.bank_name}</span>
                                    <span onClick={()=>copiar(reserva.paymentIntent.next_action.display_bank_transfer_instructions.financial_addresses[0].spei.bank_name)}>
                                        <img src={Copiar}/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
                    <h2>Resumen de Renta</h2>
                    <div className="resumenRenta">
                        {reserva?.status == "Booked" &&
                            <div>
                                <span>

                                    <div><b>Ubicación</b></div>
                                    <div>
                                        {reserva?.street ? reserva.street+" " : ""}
                                        {reserva?.number ? reserva.number+", " : ""}
                                        {reserva?.zipCode ? reserva.zipCode+", " : ""}
                                        {reserva?.Town}, {reserva?.State}
                                    </div>
                                </span>
                            </div>
                        }
                        <div>
                            <span>
                                <div><b>Fechas</b></div>
                                {fechaI && fechaF &&
                                    <div>Del {formatoFechaTxt(fechaI)} al {formatoFechaTxt(fechaF)}</div>
                                }
                            </span>
                            {(!pagar && editable) &&
                                <button className="editar">Editar</button>
                            }
                        </div>
                        <div>
                            <span>
                                <div><b>Espacios</b></div>
                                <div>
                                    {espacios} espacio{espacios > 1 ? "s" : ""}
                                </div>
                                <div className="medidas descAlmacen">
                                    <span>Ancho: {config.sistema == 'ingles' ? formatoNumero(reserva?.width*(3.2808399)/100)+'ft' : formatoNumero(reserva?.width/100)+'m'}</span>
                                    <span>Largo: {config.sistema == 'ingles' ? formatoNumero(reserva?.length*(3.2808399)/100)+'ft' : formatoNumero(reserva?.length/100)+'m'}</span>
                                    <span>Alto: {config.sistema == 'ingles' ? formatoNumero(reserva?.height*(3.2808399)/100)+'ft' : formatoNumero(reserva?.height/100)+'m'}</span>   
                                </div>
                                <div className="descAlmacen medidas">
                                    <span>Capacidad de carga: {config.sistema == 'ingles' ? formatoNumero(reserva?.weight*(2.20462262)/100)+'lb' : formatoNumero(reserva?.weight/100)+'kg'}</span>
                                </div>
                            </span>
                            {(!pagar && editable) &&
                                <button className="editar">Editar</button>
                            }
                        </div>
                    </div>
                    {(!reserva?.inmediate && reserva.status == 'Created') &&
                        <button className="btn btnReservar" onClick={()=>{
                            if(user.sub){
                                if(window.confirm('Una vez creada la solicitud ya no podrá ser editada. ¿Deseas continuar?'))
                                    solicitarReservacion();
                            }
                            else{
                                setModalLogin(true);
                            }
                        }}>
                            {cargando &&
                                <img src={Cargando}/>
                            }
                            {!cargando &&
                                <span>Solicitar Reservación</span>
                            }
                        </button>
                    }
                    {((reserva?.inmediate != 0 && reserva?.status != 'Booked' && reserva?.status != 'Partial') || reserva?.status == 'Approved') &&
                        <>
                            <h4>Selecciona la forma de Pago</h4>
                            <div className={'metodoPago'+( pagar ? ' selected' : '')}>
                                <button className={"btnPagar" + (metodo == 'Transferencia' ? ' sel' : '')} onClick={()=>{
                                    setMetodo('Transferencia');
                                }}>
                                    <span className="imgPagar">
                                        <img src={Transferencia}/>
                                    </span>
                                    <span>Transferencia Bancaria</span>
                                </button>
                                <button className={"btnPagar" + (metodo == 'Tarjeta' ? ' sel' : '')} onClick={()=>{
                                    setMetodo('Tarjeta');
                                }}>
                                    <span className="imgPagar">
                                        <img src={Tarjeta}/>
                                    </span>
                                    <span>Tarjeta de Crédito/Débito</span>
                                </button>
                            </div>
                            {metodo == 'Tarjeta' &&
                                <div>
                                    <i>Este método de pago cobra un 3.6% por transacción</i>
                                    <br></br>
                                    <br></br>
                                </div>
                            }
                            <div>
                                {!pagar &&
                                    <button className="btn btnReservar" onClick={()=>{
                                        if(user.sub){
                                            reservar();
                                        }
                                        else{
                                            setModalLogin(true);
                                        }
                                    }}>
                                        {cargando &&
                                            <img src={Cargando}/>
                                        }
                                        {!cargando &&
                                            <span>Continuar con el Pago</span>
                                        }
                                    </button>
                                }
                                {pagar && clientSecret &&
                                    <Elements stripe={stripePromise} options={{
                                        clientSecret: clientSecret,
                                        appearance: {/*...*/},
                                    }}>
                                        <CheckoutForm />
                                    </Elements>
                                }
                            </div>
                        </>
                    }
                    {error &&
                        <div className="error">
                            {error}
                        </div>
                    }
                </div>
                <div className="reservar">
                    <div>
                        <div className="resumenAlmacen">
                            {reserva?.Photos && reserva?.Photos[0] &&
                                <div className="miniAlmacen">
                                    <img src={reserva?.Photos[0].url}/>
                                </div>
                            }
                            <div>
                                <div>
                                    <div className="nombreAlmacen">{reserva?.name}</div>
                                    <div className="ubicacionAlmacen">{reserva?.Town+", "+ reserva?.State}</div>
                                </div>
                                <div className="puntua">
                                    <span className="puntuacionAlmacen">{reserva?.puntuacion || 4.93}</span>
                                    <span className="comentariosAlmacen">{reserva?.comentarios?.length || 15} comentarios</span>
                                </div>
                            </div>
                        </div>
                        <div className="resumenPrecio">
                            <h2>Detalles del precio</h2>
                            {fechaI &&
                                <div className="intervalo">
                                    <div>
                                        <span>Del</span>
                                        <span>{formatoFechaTxt(fechaI)}</span>
                                    </div>
                                    {fechaF &&
                                    <>
                                        <div>
                                            <span>Hasta</span>
                                            <span>{formatoFechaTxt(fechaF)}</span>
                                        </div>
                                        {metodo == 'Tarjeta' &&
                                            <div>
                                                <span>Comisión (3.6%)</span>
                                                <span>${(costo*.036 || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} MXN</span>
                                            </div>
                                        }
                                        <div className="total">
                                            <span>Total (MXN)</span>
                                            {metodo == 'Transferencia' &&
                                                <span>${(costo || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} MXN</span>
                                            }
                                            {metodo == 'Tarjeta' &&
                                                <span>${(costo*.036 + costo*1 || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} MXN</span>
                                            }
                                        </div>
                                    </>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {false &&
            <div className="datosAlmacen">
                <div>
                    {/* <h2 className="descripcionAlmacen">{reserva?.descripcion || "Descripción del almacén"}</h2> */}
                    <h2 className="ubicacionAlmacen">{reserva?.Town+", "+ reserva?.State}</h2>
                </div>
                <div className="reservar">
                    <div>
                        <div className="descAlmacen">{reserva.assignedSpaces} espacio{reserva.assignedSpaces == 1 ? "" : "s"} disponibles</div>
                        <div className="descAlmacen">{reserva.length/100}m x {reserva.width/100}m x {reserva.height/100}m</div>
                        <div className="periodo">
                            {reserva.TypeTime.includes("1") &&
                                <span>
                                    <span>${(reserva?.day*1 || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} MXN</span>
                                    <span>Día</span>
                                </span>
                            }
                            {reserva.TypeTime.includes("2") &&
                                <span>
                                    <span>${(reserva?.week*1 || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} MXN</span>
                                    <span>Semana</span>
                                </span>
                            }
                            {reserva.TypeTime.includes("3") &&
                                <span>
                                    <span>${(reserva?.month*1 || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} MXN</span>
                                    <span>Mes</span>
                                </span>
                            }
                            {reserva.TypeTime.includes("4") &&
                                <span>
                                    <span>${(reserva?.year*1 || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} MXN</span>
                                    <span>Año</span>
                                </span>
                            }
                        </div>
                        <form className="resv">
                            <label>
                                <input type="number" min={1} value={espacios} onChange={(e)=>{
                                    setEspacios(e.target.value);
                                }} max={reserva?.assignedSpaces}/>
                                <span>Cantidad de Espacios</span>
                            </label>
                            <label onClick={()=>{
                                setCalendario(true);
                                setCalendarioF(false);
                            }}>
                                <input type="text" value={formatoFecha(fechaI)} readOnly={true}/>
                                <span>Desde</span>
                            </label>
                            {fechaF &&
                                <label onClick={()=>{
                                    setCalendario(false);
                                    setCalendarioF(true);
                                }}>
                                    <input type="text" value={formatoFecha(fechaF)} readOnly={true}/>
                                    <span>Hasta</span>
                                </label>
                            }
                            <label>
                                <input type="number" min={1} value={dias} onChange={(e)=>{
                                    // setDias(e.target.value);
                                    console.log(e.target.value);
                                    setFechaF(new Date(fechaI.getTime()+86400000*(e.target.value-1)));
                                }}/>
                                <span>Días</span>
                            </label>

                            {calendario &&
                                <Calendar className="calendario"
                                    defaultActiveStartDate={fechaI} 
                                    tileClassName={({date})=>{
                                        if(date.getTime() == (new Date("2024-05-24".split("-"))).getTime()){
                                            return "highlight";
                                            console.log(date.getTime() == (new Date("2024-05-24".split("-"))).getTime());
                                        }
                                    }}
                                    tileDisabled={
                                        ({date})=>{
                                            if(date.getTime() == (new Date("2024-05-24".split("-"))).getTime()){
                                                return true;
                                                console.log(date.getTime() == (new Date("2024-05-24".split("-"))).getTime());
                                            }
                                        }   
                                    }
                                    allowPartialRange={fechaF}
                                    value={[fechaI, fechaF]} 
                                    onChange={(value)=>{
                                        setFechaI(value[0]);
                                        if(fechaF > value[0]){
                                            setCalendario(false);
                                            return false;
                                        }
                                        else{
                                            setFechaF(value[0]);
                                        }
                                        if(value[1]){
                                            setFechaF(new Date(value[1].getFullYear(), value[1].getMonth(), value[1].getDate()));
                                            setCalendario(false);
                                        }
                                    }}
                                    selectRange={true}
                                    minDate={hoy}/>
                            }
                            {calendarioF &&
                                <Calendar className="calendario"
                                    defaultActiveStartDate={fechaF} 
                                    tileClassName={(date)=>{

                                    }}
                                    value={[fechaI, fechaF]} 
                                    onChange={(value)=>{
                                        setFechaF(value);
                                        setCalendarioF(false);
                                    }}
                                    minDate={hoy}/>
                            }
                        </form>
                        {fechaI &&
                            <div className="intervalo">
                                <div>
                                    <span>Del</span>
                                    <span>{formatoFechaTxt(fechaI)}</span>
                                </div>
                                {fechaF &&
                                <>
                                    <div>
                                        <span>Hasta</span>
                                        <span>{formatoFechaTxt(fechaF)}</span>
                                    </div>
                                    <div className="total">
                                        <span>Total (MXN)</span>
                                        <span>${(costo || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} MXN</span>
                                    </div>
                                </>
                                }
                            </div>
                        }

                    </div>
                </div>
            </div>
            }
        </div>
    )
}

const CheckoutForm = () => {
    const { id } = useParams();
    const stripe = useStripe();
    const elements = useElements();

    const [errorMessage, setErrorMessage] = useState(null);

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
        }

        const {error} = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
            return_url: appUrl+'completado/'+id,
        },
        });


        if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        setErrorMessage(error.message);
        } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button className="btn continuar" disabled={!stripe}>Continuar</button>
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  )
};

const Progreso = ({progreso, inmediate})=>{
    if(inmediate)
        return(
            <div className="progresoReserva">
                <Paso completo={progreso >= 0} texto='Confirmar Datos'/>
                <Paso completo={progreso >= 1} texto='Completar Pago'/>
                <Paso completo={progreso >= 2} texto='Reserva Completa'/>
            </div>
        )
    return(
        <div className="progresoReserva">
            <Paso completo={progreso >= 0} texto='Confirmar Datos'/>
            <Paso completo={progreso >= 1} texto='Esperar Aprobación'/>
            <Paso completo={progreso >= 2} texto='Completar Pago'/>
            <Paso completo={progreso >= 3} texto='Reserva Completa'/>
        </div>
    )
}

const Paso = ({texto, completo})=>{
    return (
        <div className={"paso"+(completo ? ' completo' : '')}>
            <div className="circulo"></div>
            <div className="linea izq"></div>
            <div>{texto}</div>
        </div>
    )
}

export default Reservar;