import React, {useState, useEffect} from "react";
import { baseUrl } from '../../config.js'
import { Link } from "react-router-dom";

import Etiquetas from '../Etiquetas/Etiquetas.js';
import Almacenes from '../Host/Publicaciones.js';
import Agregar from '../../img/Agregar.png';

import Filtros from "../Filtros/Filtros.js";
import useToken from "../../useToken.js";

const Inicio = ({modalFiltro, setModalFiltro})=>{

    const {token} = useToken();
    const [datos, setDatos] = useState([]);
    const [filtros, setFiltros] = useState({});
    const [scrollTop, setScrollTop] = useState(window.scrollY);

    useEffect(()=>{
        document.querySelector(".nav").classList.add("inicio");
        return ()=>{
            document.querySelector(".nav").classList.remove("inicio");
        }
    }, [])

    useEffect(()=>{
        const handleScroll = event => {
          setScrollTop(window.scrollY);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, [])
    
    useEffect(()=>{
        if(token)
            getData();
    }, [filtros, token])

    const getData = ()=>{
        // fetch data almacenes
        
    }
    
    return(
        <>
            {modalFiltro &&
                <Filtros setModalFiltro={setModalFiltro} setFiltros={setFiltros}/>
            }
            {/* <Etiquetas setModalFiltro={setModalFiltro}/> */}
            <h1 className="favoritos"></h1>
            <Almacenes propias={true}/>
        </>
    )
}

export default Inicio;