export const config = {
    Auth: {
        Cognito: {
          userPoolClientId: "5t7d1ptr1ng3r3osko0ceuinkc",
          userPoolId: "us-east-2_G1F3OwIEx",
          // mía
        //   userPoolClientId: "70jeqmk8qhkoml1ukm9m44p0r8",
        //   userPoolId: "us-west-2_1nM2tfRvm",
          loginWith: {
            oauth: {
              domain: "palletcloud.auth.us-east-2.amazoncognito.com",
              redirectSignIn: ["http://localhost:3000/", "https://www.palletcloud.com.mx/", "https://productionaws.doy93kwtxmlki.amplifyapp.com/"],
              redirectSignOut: ["http://localhost:3000", "https://www.palletcloud.com.mx/", "https://productionaws.doy93kwtxmlki.amplifyapp.com/"],
              responseType: "code",
              scopes: ['openid', 'email', 'aws.cognito.signin.user.admin'],
            }
          }
        }
    }
}