import React, {useState, useEffect} from "react";
import { baseUrl } from '../../config.js'

import Etiquetas from '../Etiquetas/Etiquetas.js';
import Almacenes from '../Almacenes/Almacenes.js';

import './Inicio.css';
import Filtros from "../Filtros/Filtros.js";
import useToken from "../../useToken.js";

const Inicio = ({modalFiltro, setModalFiltro, favoritos, setModalLogin})=>{
    const {token} = useToken();
    const [impuestos, setImpuestos] = useState(false);
    const [filtros, setFiltros] = useState({});
    const [scrollTop, setScrollTop] = useState(window.scrollY);

    useEffect(()=>{
        document.querySelector(".nav")?.classList.add("inicio");
        return ()=>{
            document.querySelector(".nav")?.classList.remove("inicio");
        }
    }, [])

    useEffect(()=>{
        const handleScroll = event => {
          setScrollTop(window.scrollY);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, [])

    useEffect(()=>{
        if(scrollTop > 0)
            document.querySelector(".nav")?.classList.add('min')
        else
            document.querySelector(".nav")?.classList.remove('min')
    }, [scrollTop])
    
    useEffect(()=>{
        if(token)
            getData();
    }, [filtros, token])

    const getData = ()=>{
        // fetch data almacenes
        
    }
    
    return(
        <>
            {modalFiltro &&
                <Filtros setModalFiltro={setModalFiltro} setFiltros={setFiltros}/>
            }
            <Etiquetas impuestos={impuestos} setImpuestos={setImpuestos} setModalFiltro={setModalFiltro}/>
            {favoritos &&
                <h1 className="favoritos">Almacenes Favoritos</h1>
            }
            <Almacenes impuestos={impuestos} favoritos={favoritos} setModalLogin={setModalLogin}/>
        </>
    )
}

export default Inicio;