import React, { useState, useRef, useEffect, useContext } from "react";
import { baseUrl } from '../../config.js'
import { Chart } from "react-google-charts";
import { Link } from "react-router-dom";

import './Dashboards.css';

import useToken from "../../useToken.js";
import { formatoFecha, formatoMoneda, formatoHora, Meses, formatoFechaCorta } from '../../almacen.js';

const DashEspacios = ({})=>{

    const {token} = useToken();
    const [spaces, setSpaces] = useState({});
    const [totales, setTotales] = useState({});

    useEffect(()=>{
        if(token){
            getData();
        }
    }, [token])
    
    const getData = ()=>{
        fetch(baseUrl+'Info/Spaces',{
            headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
        })
        .then(response=>response.json())
		.then(json=>{
            setSpaces({booked : json?.data?.booked || 0, total: json?.data?.total || 0})
		})
		.catch(error=>{
			console.error(error);
		})
    }
    

    return(
        <div className="dashEspacios">
            <div className="infoEspacios">
                <div className="upper">
                    <div className="resumen">
                        <div className={"etiqueta"+(spaces?.booked === undefined ? " placeholder" : "")}>
                            <h4>Total de espacios</h4>
                            {spaces?.booked === 0 &&
                                <div>No hay espacios ocupados</div>
                            }
                            {!spaces?.booked === undefined &&
                            <div className="informacion placeGrafica"></div>
                            }
                            {spaces?.booked !== undefined &&
                                <div className="informacion placeGrafica">
                                    <GraficaTotales spaces={spaces}/>
                                </div>
                            }
                        </div>
                        <div className={"etiqueta"+(spaces?.booked === undefined ? " placeholder" : "")}>
                            <h4>Ocupación de Almacenes</h4>
                            {spaces?.booked === 0 &&
                                <div>No hay espacios ocupados</div>
                            }
                            {!spaces?.booked === undefined &&
                            <div className="informacion placeGrafica"></div>
                            }
                            {spaces?.booked !== undefined &&
                                <div className="informacion placeGrafica">
                                    <TablaMejores totales={totales} setTotales={setTotales}/>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="lower">
                    <div className="etiqueta">
                        <h4>Espacios ocupados</h4>
                        <GraficaEspacios/>
                    </div>
                </div>
            </div>
            <div className="movimientos">
                <div className="etiqueta">
                    <div>
                        <h3>Rentas recientes</h3>
                        <Movimientos totales={totales}/>
                    </div>
                </div>
            </div>

        </div>
    )
}

const GraficaTotales = ({spaces})=>{

    const [data, setData] = useState([])

    const options={
        legend: {
            position: 'bottom'
        },
        chartArea: {'width': '90%', 'height': '80%'},
        pieHole: 0.4,
    }

    useEffect(()=>{
        const aux =[
            ['Espacios', 'Total'],
            ['Disponibles', spaces?.total - spaces?.booked],
            ['Ocupados', spaces?.booked*1]
          ];
        setData(aux);
    },[spaces])

    return(
        <Chart
            chartType="PieChart"
            data={data}
            options={options}
            width="100%"
            height="100%"
            legendToggle
        />
    )
}

const GraficaEspacios = ({})=>{
    
    const {token} = useToken();
    const [tiempo, setTiempo] = useState('Month');
    const [data, setData] = useState([]);
    const [fecha, setFecha] = useState(new Date());

    const options ={
        legend: 'bottom',
        chartArea: {'width': '90%'},
        hAxis : { 
            slantedText: false,
            maxAlternation: 1,
            textStyle : {
                fontSize: 11
            }
    
        }
    
    }

    useEffect(()=>{
        if(token){
            getData();
        }
    },[token, fecha, tiempo])

    const getData = ()=>{
        const body = {time : tiempo, date: fecha};
        fetch(baseUrl+'Info/SpacesTime/',{
            method: 'post',
            headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
            body: JSON.stringify(body)
        })
        .then(response=>response.json())
		.then(json=>{
            setData(formatData(json?.data || {}))
		})
		.catch(error=>{
			console.error(error);
		})
    }
    const formatData = (datos)=>{
        const events = datos?.bookings;
        const assigned = datos?.totalSpaces;
        const aux = [];
        if(tiempo == 'Month'){
            aux.push(["Fecha", "Total Disponibles", "Espacios Ocupados"])
            const auxFecha = new Date(fecha.getFullYear(), fecha.getMonth()+1, 0);
            const diasMes = auxFecha.getDate();

            const auxContador = {};
            for(let i = 0; i < diasMes; i++){
                const dia = new Date(fecha.getFullYear(), fecha.getMonth(), i+1);
                auxContador[i+1] = 0;
                events.forEach(event=>{
                    const inicio = new Date(event.startDate.split("T")[0].split("-"))
                    inicio.setHours(0,0,0,0)
                    const fin = new Date(event.endDate.split("T")[0].split("-"))
                    fin.setHours(0,0,0,0)
                    if(dia >= inicio && dia <= fin){
                        auxContador[i+1] += event.spaces*1;
                    }
                })
            }
            let inicio = 0;
            const auxTotales = {};
            assigned.forEach(x=>{
                const changeDate = new Date(x?.date?.split("T"[0]?.split("-")));
                auxFecha.setDate(1);
                if(changeDate < auxFecha){
                    if(x.status == 'Published'){
                        inicio += x.assignedSpaces*1;
                    }
                    else{
                        inicio -= x.assignedSpaces*1;
                    }
                }
                else{
                    for(let i = changeDate.getDate(); i <= diasMes; i++){
                        if(!auxTotales[i]){
                            auxTotales[i] = 0;
                        }
                        if(x.status=='Published'){
                            auxTotales[i] += x.assignedSpaces*1;
                        }
                        else{
                            auxTotales[i] -= x.assignedSpaces*1;
                        }
                    }
                }
            })
            for(let dia in auxContador){
                auxTotales[dia] = auxTotales[dia] ? auxTotales[dia]+inicio : inicio;
                aux.push([dia, auxContador[dia] > auxTotales[dia] ? auxContador[dia] : auxTotales[dia], auxContador[dia]])
            }
        }
        else if(tiempo == "Year"){
            aux.push(["Mes", "Espacios Ocupados"])
            
            const auxContador = {};
            Array(12).fill(0).map((x,i)=>{
                const inicioMes = new Date(fecha.getFullYear(), i, 1);
                const finMes = new Date(fecha.getFullYear(), i+1, 0);
                auxContador[i] = 0;
                events.forEach(event=>{
                    const inicio = new Date(event.startDate.split("T")[0].split("-"))
                    inicio.setHours(0,0,0,0)
                    const fin = new Date(event.endDate.split("T")[0].split("-"))
                    fin.setDate(fin.getDate()+1)
                    fin.setHours(0,0,0,0)
                    if(inicio <= finMes && fin >= inicioMes){
                        auxContador[i] += event.spaces*1;
                    }
                })
            })
            for(let mes in auxContador){
                aux.push([Meses[mes], auxContador[mes]])
            }
        }
        else{
            aux.push(["Mes", "Espacios Ocupados"])
            const startDates = events.map(event=>{
                return new Date(event.startDate);
            })
            const endDates = events.map(event=>{
                return new Date(event.endDate);
            })
            const minDate = new Date(Math.min(...startDates));
            const maxDate = new Date(Math.max(...endDates));
            
            const auxContador = {};
            const auxFecha = new Date(minDate.getTime());
            auxFecha.setDate(1);
            while(auxFecha <= maxDate){
                const inicioMes = new Date(auxFecha.getFullYear(), auxFecha.getMonth(), 1);
                const finMes = new Date(auxFecha.getFullYear(), auxFecha.getMonth()+1, 0);
                auxContador[(inicioMes.getMonth())+"-"+inicioMes.getFullYear()] = 0;
                events.forEach(event=>{
                    const inicio = new Date(event.startDate.split("T")[0].split("-"))
                    inicio.setHours(0,0,0,0)
                    const fin = new Date(event.endDate.split("T")[0].split("-"))
                    fin.setDate(fin.getDate()+1)
                    fin.setHours(0,0,0,0)
                    if(inicio <= finMes && fin >= inicioMes){
                        auxContador[(inicioMes.getMonth())+"-"+inicioMes.getFullYear()] += event.spaces*1;
                    }
                })
                auxFecha.setMonth(auxFecha.getMonth()+1);
            }
            for(let mes in auxContador){
                aux.push([Meses[mes.split("-")[0]]+"-"+mes.split("-")[1], auxContador[mes]])
            }
        }
        return aux;
    }
    
    return(
        <div className="grafica">
            <div className="tabs">
                <button className={tiempo == 'Month' ? 'sel' : ''}
                    onClick={()=>{
                        setTiempo('Month');
                    }}
                >Mes</button>
                <button className={tiempo == 'Year' ? 'sel' : ''}
                    onClick={()=>{
                        setTiempo('Year');
                    }}
                >Año</button>
                <button className={tiempo == 'All' ? 'sel' : ''}
                    onClick={()=>{
                        setTiempo('All');
                    }}
                >Todo</button>
            </div>
            {tiempo != 'All' &&
                <div className="tipos">
                    <button onClick={()=>{
                        const auxFecha = new Date(fecha.getTime())
                        auxFecha.setFullYear(auxFecha.getFullYear()-1)
                        setFecha(auxFecha);
                    }}>{"<<"}</button>
                    
                    {tiempo == "Month" &&
                        <button onClick={()=>{
                            const auxFecha = new Date(fecha.getTime())
                            auxFecha.setMonth(auxFecha.getMonth()-1)
                            setFecha(auxFecha);
                        }}>{"<"}</button>
                    }
                    <span className="actual">
                        {tiempo == "Month" &&
                            <div>{Meses[fecha.getMonth()]}</div>
                        }
                        <div>{fecha.getFullYear()}</div>
                    </span>
                    {tiempo == "Month" &&
                        <button onClick={()=>{
                            const auxFecha = new Date(fecha.getTime())
                            auxFecha.setMonth(auxFecha.getMonth()+1)
                            setFecha(auxFecha);
                        }}>{">"}</button>
                    }
                    <button onClick={()=>{
                        const auxFecha = new Date(fecha.getTime())
                        auxFecha.setFullYear(auxFecha.getFullYear()+1)
                        setFecha(auxFecha);
                    }}>{">>"}</button>
                </div>
            }
            <div className={"contGrafica"+(data.length === 0 ? " placeholder" : "")}>
                {data.length === 0 &&
                    <div className="informacion placeGrafica"></div>
                }
                {data.length !== 0 &&
                    <Chart
                        chartType="SteppedAreaChart"
                        data={data}
                        options={options}
                        width="100%"
                        height="100%"
                        legendToggle
                    />
                }
            </div>
        </div>
    )
}

const TablaMejores = ({totales, setTotales})=>{

    const { token } = useToken();
    const [tiempo, setTiempo] = useState('Month');
    const [data, setData] = useState([]);
    const [fecha, setFecha] = useState(new Date())

    useEffect(()=>{
        if(token){
            getData();
        }
    },[token])

    const getData = ()=>{
        const body = {time : tiempo, date: fecha};
        fetch(baseUrl+'Info/BestStores',{
            method: 'post',
            headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
            body: JSON.stringify(body)
        })
        .then(response=>response.json())
		.then(json=>{
            setData(json?.data)
            setTotales({
                "espacios": json?.data.reduce((a,b)=>{
                        return a+b.spaces*1;
                    }, 0),
                "espaciosTotales": json?.data.reduce((a,b)=>{
                        return a+b.assignedSpaces*1;
                    }, 0)
            })
		})
		.catch(error=>{
			console.error(error);
		})
    }
    
    return(
        <table className="tabla mejores">
        <tbody>
            {data.length !== 0 &&
                <tr className='header'>
                    <th>Almacén</th>
                    <th>Ocupados</th>
                    <th>Total Espacios</th>
                    <th>%</th>
                </tr>
            }
            {data.map((fila, i)=>{
                return(
                    <tr key={i}>
                        <td>{fila.name}</td>
                        <td>{fila.spaces}</td>
                        <td>{fila.assignedSpaces}</td>
                        <td>{Math.round(fila.spaces*10000/fila.assignedSpaces)/100}%</td>
                    </tr>
                )
            })
            }
            {data.length !== 0 &&
                <tr className="granTotal">
                    <th>Total</th>
                    <th>{totales?.espacios || 0}</th>
                    <th>{totales?.espaciosTotales || 0}</th>
                    <th>{Math.round(totales?.espacios*10000/totales.espaciosTotales)/100}%</th>
                </tr>
            }
        </tbody>
        </table>
    )
}
const Movimientos = ({totales})=>{

    const { token } = useToken();
    const [tiempo, setTiempo] = useState('Month');
    const [data, setData] = useState([]);
    const [fecha, setFecha] = useState(new Date())

    useEffect(()=>{
        if(token){
            getData();
        }
    },[token])

    const getData = ()=>{
        const body = {time : tiempo, date: fecha};
        fetch(baseUrl+'Info/RecentMovements',{
            method: 'post',
            headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
            body: JSON.stringify(body)
        })
        .then(response=>response.json())
		.then(json=>{
            setData(json?.data || [])
		})
		.catch(error=>{
			console.error(error);
		})
    }
    return(
        <table className="tabla recientes">
        <tbody>
            {data?.length !== 0 &&
                <tr className='header'>
                    <th>Operación</th>
                    <th>Almacén</th>
                    <th>Rentados</th>
                    <th>Inicio</th>
                    <th>Fin</th>
                    <th>Importe</th>
                </tr>
            }
            {data?.length === 0 &&
                <tr>
                    <td>No hay movimientos recientes</td>
                </tr>
            }
            {data?.map((fila, i)=>{
                let auxFecha = formatoFechaCorta(new Date(fila.mainDate?.split("T")[0]?.split("-")));
                if(auxFecha == formatoFechaCorta(new Date())){
                    auxFecha = "Hoy";
                }
                const hora = formatoHora(new Date(fila.mainDate));
                if(fila.transaction>0 && fila.status == 'Booked')
                return(
                    <tr key={i}>
                        <td>
                            <div>{auxFecha}</div>
                            <div>{hora}</div>
                        </td>
                        <td>
                            <Link to={'/host/almacenes/'+fila.idStore}>{fila.name}</Link>
                        </td>
                        <td className="mas">{fila.transaction}</td>
                        <td>{formatoFechaCorta(new Date(fila.startDate.split("T")[0].split("-")))}</td>
                        <td>{formatoFechaCorta(new Date(fila.endDate.split("T")[0].split("-")))}</td>
                        <td>${formatoMoneda(fila.price*1)}</td>
                    </tr>
                )
            })
            }
        </tbody>
        </table>
    )
}

const MapaEspacios = ()=>{
    const data = [
        ['States', 'Estado', 'Espacios'],
        ['MX-AGU', 'Aguascalientes', 0],
        ['MX-BCN', 'Baja California', 0],
        ['MX-BCS', 'Baja California Sur', 0],
        ['MX-CAM', 'Campeche', 0],
        ['MX-DIF', 'Ciudad de México', 100],
        ['MX-COA', 'Coahuila', 0],
        ['MX-COL', 'Colima', 0],
        ['MX-CHP', 'Chiapas', 0],
        ['MX-CHH', 'Chihuahua', 0],
        ['MX-DUR', 'Durango', 0],
        ['MX-GUA', 'Guanajuato', 0],
        ['MX-GRO', 'Guerrero', 0],
        ['MX-HID', 'Hidalgo', 0],
        ['MX-JAL', 'Jalisco', 0],
        ['MX-MEX', 'México', 50],
        ['MX-MIC', 'Michoacán', 0],
        ['MX-MOR', 'Morelos', 0],
        ['MX-NAY', 'Nayarit', 0],
        ['MX-NLE', 'Nuevo León', 0],
        ['MX-OAX', 'Oaxaca', 0],
        ['MX-PUE', 'Puebla', 0],
        ['MX-QUE', 'Querétaro', 0],
        ['MX-ROO', 'Quintana Roo', 0],
        ['MX-SLP', 'San Luis Potosí', 0],
        ['MX-SIN', 'Sinaloa', 0],
        ['MX-SON', 'Sonora', 0],
        ['MX-TAB', 'Tabasco', 0],
        ['MX-TAM', 'Tamaulipas', 0],
        ['MX-TLA', 'Tlaxcala', 0],
        ['MX-VER', 'Veracruz', 0],
        ['MX-YUC', 'Yucatán',  0],
        ['MX-ZAC', 'Zacatecas', 0],
      ];
      const options = {
        region: "MX", // Africa
        displayMode: "regions",
        resolution: "provinces"
      };
    return(
        <div>
            <Chart
                chartType="ScatterChart"
                data={[["Age", "Weight"], [4, 5.5], [8, 12]]}
                width="100%"
                height="400px"
                legendToggle
            />
            <Chart
                chartEvents={[
                    {
                    eventName: "select",
                    callback: ({ chartWrapper }) => {
                        const chart = chartWrapper.getChart();
                        const selection = chart.getSelection();
                        if (selection.length === 0) return;
                        const region = data[selection[0].row + 1];
                        console.log("Selected : " + region[0]);
                    },
                    },
                ]}
                chartType="GeoChart"
                width="100%"
                height="400px"
                data={data}
                options={options}
                />
        </div>
    )
}

export default DashEspacios;