import React, { useEffect, useRef, useState } from "react";
import { baseUrl } from '../../config.js'
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import Modal from '../Modal/Modal';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import Cerrar from '../../img/Cerrar.png'
import './Fotos.css';

const VideoItem = ({url, titulo})=>{
    const videoRef = useRef();
    useEffect(()=>{
        videoRef?.current?.play();
    })
    return(
        <>
            <video ref={videoRef} className="image-gallery-image" src={url+"#t=0.01"} autoPlay muted></video>
            <span class="image-gallery-description">{titulo}</span>
        </>
    )
}

const Fotos = ({})=>{
    const { id, idPhoto } = useParams();
    const { publicacion } = useOutletContext();
    const navigate = useNavigate();
    const [fotos, setFotos] = useState({});
    const [items, setItems] = useState([]);
    const [carrusel, setCarrusel] = useState(false);
    const [index, setIndex] = useState(null);
    const refSecciones = useRef([]);
    const refFotos = useRef([]);

    const secciones = [
        {
            "id" : "1",
            "nombre":"Fachada",
        },
        {
            "id" : "2",
            "nombre":"Andenes de carga y descarga",
        },
        {
            "id" : "3",
            "nombre":"Rampa de carga y descarga",
        },
        {
            "id" : "4",
            "nombre":"Interior",
        },
        {
            "id" : "5",
            "nombre":"Fullfilment (Pick & Pack)",
        },
        {
            "id" : "6",
            "nombre":"Servicio de valor agregado (maquila)",
        },
        {
            "id" : "7",
            "nombre":"Área de oficinas",
        },
        {
            "id" : "8",
            "nombre":"Recepción",
        },
        {
            "id" : "9",
            "nombre":"Vídeo",
        },
    ]

    useEffect(()=>{
        if(idPhoto == "video"){
            if(refFotos.current[refFotos.current.length-1]){
                refFotos.current[refFotos.current.length-1].scrollIntoView();
                // refFotos.current[refFotos.current.length-1].click();
                setIndex(fotos[9][0].index)
            }
        }
        console.log(idPhoto, refFotos.current[idPhoto])
        if(refFotos.current[idPhoto]){
            refFotos.current[idPhoto].scrollIntoView();
        }
    }, [idPhoto, fotos])

    useEffect(()=>{
        const aux = {};
        const auxItems = [];
        var j = 0;
        publicacion?.Photos?.forEach(foto=>{
            if(!aux[foto.idCategoryPhoto])
                aux[foto.idCategoryPhoto] = [];
            aux[foto.idCategoryPhoto].push(foto);
        })
        secciones.forEach(seccion=>{
            if(seccion.id == "9"){
                if(aux[seccion.id]){
                    aux[seccion.id].forEach(x=>{
                        auxItems.push({
                            original: x.url,
                            description: seccion.nombre,
                            originalTitle: seccion.nombre,
                            renderItem: ({ original }) => <VideoItem url={original} titulo={seccion.nombre} />
                        })
                        x.index = j++;
                    })
                }
            }
            else
            if(aux[seccion.id]){
                aux[seccion.id].forEach(x=>{
                    auxItems.push({
                        original: x.url,
                        description: seccion.nombre,
                        originalTitle: seccion.nombre,
                    })
                    x.index = j++;
                })
            }
        })
        console.log(aux);
        setFotos(aux);
        setItems(auxItems);
        console.log(auxItems)
    }, [publicacion])

    useEffect(()=>{
        if(index !== null)
            setCarrusel(true);
    }, [index])
    
    useEffect(()=>{
        if(carrusel){
            window.onkeydown = (e)=>{
                if(e.key==="Escape"){
                    setIndex(null)
                    setCarrusel(false);
                }
            }
        }
        else{
            window.onkeydown = undefined;
        }
        return ()=>{
            window.onkeydown = undefined;
        }
    },[carrusel])

    return(
        <>
            {carrusel &&
                <div className="modal carrusel">
                    <button className="lblCerrar" onClick={()=>{
                        setIndex(null)
                        setCarrusel(false);
                    }}>Cerrar</button>
                    <ImageGallery items={items} startIndex={index} showIndex={true} onKeyDown={(e)=>{
                        console.log(e);
                    }}/>
                </div>
            }
            <div className={"modal galeria"}>
                <div>
                    <div className="opcModal">
                        <button type="button" onClick={()=>{
                            navigate('/almacen/'+id, {replace:true});
                        }}>
                            <img src={Cerrar}/>
                        </button>
                        <h3>Galería</h3>
                    </div>
                    <div className="secciones">
                        {secciones.map((seccion, i)=>{
                            if((seccion.id != 5 && seccion.id != 6) || 
                                (seccion.id == 5 && publicacion?.operativeServices?.includes("7")) ||
                                (seccion.id == 6 && publicacion?.operativeServices?.includes("12"))
                            )
                            if(seccion.id != 9 && fotos[seccion.id])
                            return(
                                <button onClick={()=>{
                                    if(refSecciones?.current[i])
                                        refSecciones?.current[i].scrollIntoView({ behavior: "smooth" });
                                }}>
                                    <div className="miniFoto">
                                        {fotos[seccion.id] &&
                                            <img src={fotos[seccion.id][0].url}/>
                                        }
                                    </div>
                                    <div>{seccion.nombre}</div>
                                </button>
                                )
                            })
                        }
                    </div>
                    {secciones.map((seccion, i)=>{
                        if((seccion.id != 5 && seccion.id != 6) || 
                            (seccion.id == 5 && publicacion?.operativeServices?.includes("7")) ||
                            (seccion.id == 6 && publicacion?.operativeServices?.includes("12"))
                        )
                        if(fotos[seccion.id])
                        return(
                            <>
                            <h3 ref={ref=>{refSecciones.current[i] = ref; return ref;}}>{seccion.nombre}</h3>
                            {fotos[seccion.id] && fotos[seccion.id][0] &&
                                <>
                                    <div className="primera" ref={ref=>{refFotos.current[fotos[seccion.id][0].index] = ref; return ref}}>
                                        {fotos[seccion.id].length != 2 && seccion.id != 9 &&
                                            <img src={fotos[seccion.id][0].url} onClick={()=>{
                                                setIndex(fotos[seccion.id][0].index)
                                            }}/>
                                        }
                                        {fotos[seccion.id].length != 2 && seccion.id == 9 &&
                                            <video src={fotos[seccion.id][0].url+"#t=1.0"} onClick={()=>{
                                                setIndex(fotos[seccion.id][0].index)
                                            }}/>
                                        }
                                        {fotos[seccion.id].length == 2 &&
                                            <>
                                                <div className="mitad">
                                                    <img src={fotos[seccion.id][0].url} onClick={()=>{
                                                        setIndex(fotos[seccion.id][0].index)
                                                    }}/>
                                                </div>
                                                <div className="mitad">
                                                    <img src={fotos[seccion.id][1].url} onClick={()=>{
                                                        setIndex(fotos[seccion.id][1].index)
                                                    }}/>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {fotos[seccion.id].length >= 3 &&
                                        <div className="resto">
                                            {fotos[seccion.id]?.slice(1)?.map(foto=>{
                                                return(
                                                    <div ref={ref=>{refFotos.current[foto.index] = ref; return ref}}>
                                                        <img src={foto.url} onClick={()=>{
                                                            setIndex(foto.index)
                                                        }}/>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    }
                                </>
                            }
                            </>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default Fotos;