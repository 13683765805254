import React, { useContext, useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import { baseUrl } from '../../config.js';
import useToken from '../../useToken';
import Modal from "../Modal/Modal";
import { BuscadorContext } from "./BuscadorContext.js";

import './Buscador.css';
import 'react-calendar/dist/Calendar.css';
import Cerrar from '../../img/Cerrar.png'
import imgBuscar from '../../img/Buscar.png';
import { formatoFechaTxt } from "../../almacen.js";
import { useNavigate } from "react-router-dom";


export const Buscador = ({focused, setFocused, modal, setModalBuscador})=>{
    const navigate = useNavigate();
    const {token} = useToken();
    const {filtros, setFiltros} = useContext(BuscadorContext);
    const [buscador, setBuscador] = useState(false);
    const [buscar, setBuscar] = useState("");
    const [resultados, setResultados] = useState([])
    const [showResultados, setShowResultados] = useState(false)
    const [parametros, setParametros] = useState({})
    const [espacios, setEspacios] = useState("")
    
    const [calendario, setCalendario] = useState(false);
    const [calendarioF, setCalendarioF] = useState(false);
    
    const hoy = new Date();
    const [fechaI, setFechaI] = useState(null);
    const [fechaF, setFechaF] = useState(null);

    const [sel, setSel] = useState(-1);
    const selected = useRef(sel);
    const lugar = useRef();
    const fechaInicio = useRef();
    const fechaFinal = useRef();
    const refEspacios = useRef();
    const refCalendario = useRef();
    const refCalendarioF = useRef();
    const lblLugar = useRef();
    const lblCalendario = useRef();
    const lblCalendarioF = useRef();
    const lblEspacios = useRef();
    const lblCerrar = useRef();
    
    useEffect(() => {
        function handleClickFuera(event) {
            if(lblCalendario.current && !lblCalendario.current.contains(event.target)){
                setCalendario(false);
            }
            if(lblCalendarioF.current && !lblCalendarioF.current.contains(event.target)){
                setCalendarioF(false);
            }
            if(lblLugar.current && !lblLugar.current.contains(event.target)){
                setShowResultados(false)
            }
        }
        function handleClickDentro(event) {
            if(lblCalendario.current && lblCalendario.current.contains(event.target)){
                setFocused(true);
            }
            else if(lblCalendarioF.current && lblCalendarioF.current.contains(event.target)){
                setFocused(true);
            }
            else if(lblLugar.current && lblLugar.current.contains(event.target)){
                setFocused(true);
            }
            else if(lblEspacios.current && lblEspacios.current.contains(event.target)){
                setFocused(true);
            }
            else if(lblCerrar.current && lblCerrar.current.contains(event.target)){
                setFocused(true);
            }
            else{
                setFocused(false);
            }
        }
        document.addEventListener('click', handleClickFuera);
        if(focused!==undefined){
            
            document.addEventListener('click', handleClickDentro);
        }
        return () => {
          document.removeEventListener('click', handleClickFuera);
          document.removeEventListener('click', handleClickDentro);
        };
      }, []);

    useEffect(()=>{
        setSel(-1);
        if(resultados.length){
            setShowResultados(true);
            window.onkeydown = handleKeyDown;
        }
        else{
            setShowResultados(false);
            window.onkeydown = null;
        }
        return ()=>{
            window.onkeydown = null;
        }
    }, [resultados])
    useEffect(()=>{
        selected.current = sel;
    }, [sel])
    useEffect(()=>{
        if(buscar){
            const data = {
                search: buscar
            }
            fetch(baseUrl+"Locations/Search/"+buscar,{
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            }).then(response=> response.json())
            .then(json=>{
                setResultados(json?.data || []);
            })
            .catch(error=>{
                console.log(error);
            })
        }
    }, [buscar])
    useEffect(()=>{
        if(Object.keys(filtros).length===0){
            setParametros({});
            lugar.current.value = "";
            setFechaI(null)
            setFechaF(null)
            setEspacios("");
        }
    }, [filtros])
    useEffect(()=>{
        if(Object.keys(parametros).length!=0){
            console.log(resultados, sel, resultados[sel])
            const data = {
                ...filtros,
                spaces : espacios || 0,
            }
            if(resultados && resultados[sel]){
                data["idLocation"] = resultados[sel].id;
                data["typeLocation"] = resultados[sel].type;
            }
            console.log(data);
            setFiltros(data);
        }
    }, [parametros])

    const handleKeyDown = (e)=>{
        if(e.key == "ArrowDown"){
            setSel(sel=>{
                if(sel+1 < resultados.length)
                    return sel+1
                return sel;
            });
        }
        else if(e.key == "ArrowUp"){
            setSel(sel=>{
                if(sel != -1)
                    return sel-1;
                return sel;
            });
        }
        else if(e.key == "Enter"){
            console.log(resultados, selected.current)
            lugar.current.value = resultados[selected.current].name;
            fechaInicio.current.focus();
            setShowResultados(false)
        }
    }
    return(
        <nav className={"buscador"+(modal ? " modalBuscador" : "")}>
            <div>
                <label ref={lblLugar} tabIndex={-1}>
                    <input 
                        type="text" 
                        ref={lugar} 
                        placeholder="Buscar un sitio" 
                        onChange={(e)=>{
                            setBuscar(e.target.value);
                        }}
                        onFocus={()=>{
                            setCalendario(false);
                            setCalendarioF(false);
                        }}
                    />
                    <span className="label">{parametros.lugar}</span>
                    <span className="title">Lugar</span>
                    {showResultados &&
                        <div className="resultados">
                            {resultados?.map((resultado, i)=>{
                                return (
                                <button 
                                    key={resultado.id}
                                    className={sel == i ? "sel" : ""}
                                    onClick={(e)=>{
                                        e.preventDefault();
                                        e.stopPropagation();
                                        lugar.current.value = resultado.name;
                                        setShowResultados(false);
                                        fechaInicio.current.focus();
                                    }}
                                    onMouseEnter={()=>{
                                        setSel(i);
                                    }}
                                    >
                                    {resultado.name}
                                </button>)
                            })
                            }
                        </div>
                    }
                </label>
                <label ref={lblCalendario} tabIndex={-1}>
                    <input type="text" 
                        ref={fechaInicio} 
                        value={fechaI ? formatoFechaTxt(fechaI) : ""}
                        readOnly={true}
                        placeholder="Fecha de Inicio" 
                        onFocus={()=>{
                            setShowResultados(false)
                            setCalendario(true);
                            setCalendarioF(false);
                        }}
                    />
                    <span className="label">{parametros.fechaI}</span>
                    <span className="title">Fecha Inicial</span>
                    {calendario &&
                        <div ref={refCalendario} className="contCalendario">
                            {calendario &&
                                <Calendar className="calendarioBuscador"
                                    defaultActiveStartDate={fechaI} 
                                    tileClassName={({date})=>{
                                    }}
                                    tileDisabled={
                                        ({date})=>{
                                        }   
                                    }
                                    allowPartialRange={Boolean(fechaF)}
                                    value={[fechaI, fechaF]} 
                                    onChange={(value)=>{
                                        setFechaI(value[0]);
                                        if(fechaF > value[0]){
                                            return false;
                                        }
                                        else{
                                            setFechaF(value[0]);
                                        }
                                        if(value[1]){
                                            setFechaF(new Date(value[1].getFullYear(), value[1].getMonth(), value[1].getDate()));
                                        }
                                    }}
                                    selectRange={true}
                                    minDate={hoy}/>
                            }
                        </div>
                    }
                </label>
                <label ref={lblCalendarioF} tabIndex={-1}>
                    <input type="text" 
                        ref={fechaFinal} 
                        value={fechaF ? formatoFechaTxt(fechaF) : ""}
                        readOnly={true}
                        placeholder="Fecha Final" 
                        onFocus={()=>{
                            setShowResultados(false)
                            setCalendario(false);
                            setCalendarioF(true);
                        }}
                    />
                    <span className="label">{parametros.fechaF}</span>
                    <span className="title">Fecha Final</span>
                    {calendarioF &&
                        <div ref={refCalendarioF} className="contCalendario fechaF">
                            {calendarioF &&
                                <Calendar className="calendarioBuscador"
                                defaultActiveStartDate={fechaF} 
                                tileClassName={(date)=>{

                                }}
                                value={[fechaI, fechaF]} 
                                onChange={(value)=>{
                                    setFechaF(value);
                                }}
                                minDate={hoy}/>
                            }
                        </div>
                    }
                </label>
                <label ref={lblEspacios} tabIndex={-1}>
                    <input type="number" placeholder="Número de espacios" value={espacios}
                    onChange={(e)=>{
                        setEspacios(e.target.value);
                    }}
                    onFocus={()=>{
                        setCalendario(false);
                        setCalendarioF(false);
                    }}/>
                    <span className="label">{parametros.espacios}</span>
                    <span className="title">Espacios</span>
                </label>
                <button className="cancelarBusqueda" onClick={()=>{
                    setModalBuscador(false);
                }}>Cancelar</button>
                <button className="buscar" tabIndex={-1} onClick={(e)=>{
                    navigate("/");
                    setParametros({
                        lugar: resultados[sel]?.label,
                        fechaI: fechaI ? formatoFechaTxt(fechaI) : "",
                        fechaF: fechaF ? formatoFechaTxt(fechaF) : "",
                        espacios: espacios,
                    })
                    setShowResultados(false);
                    setCalendario(false);
                    setCalendarioF(false);
                    if(focused!==undefined)
                        setFocused(false);
                    document.querySelector(".nav").classList.add("min");
                }}>
                    <span>Buscar</span>
                    <img src={imgBuscar}/>
                </button>
                {(lugar.current?.value != "" ||
                    fechaI ||
                    fechaF ||
                    espacios
                    ) &&
                    <button className="limpiar" ref={lblCerrar} onClick={()=>{
                        lugar.current.value = "";
                        setResultados([]);
                        setFechaI("")
                        setFechaF("")
                        setEspacios("");
                        setParametros({})
                        setShowResultados(false);
                        lugar.current.focus();
                        setFiltros({...filtros, spaces: 0, idLocation: undefined, typeLocation: undefined});
                    }}>
                        <img src={Cerrar}/>
                    </button>
                }
            </div>
        </nav>
    )
}

export default Buscador;