import React, { useEffect, useRef, useState } from "react";
import { baseUrl } from '../../config.js'
import { Link, useParams } from 'react-router-dom'
import useToken from '../../useToken';
import { formatoNumero, idsR, idServiciosR } from "../../almacen.js";

import './Publicaciones.css';

import Agregar from '../../img/Agregar.png';
import Flecha from '../../img/Flecha.png';

const Publicaciones = ({id_almacen})=>{
	
	const {token} = useToken();
	const { id = id_almacen } = useParams();
	const [almacen, setAlmacen] = useState({idCompany:0, direccion:{}});
	const [datos, setDatos] = useState([]);
	const [photos, setPhotos] = useState({});

	useEffect(()=>{
		if(token)
            getData();
	}, [token])

	const getData = async ()=>{
		const result = await fetch(baseUrl+"Store/"+id,{
			headers:{
				'Authorization': `Bearer ${token}`,
			}
		})
		.then(response=>response.json())
		.then(json=>{
			console.log(json);
			return json;
		})
		.catch(error=>{
			console.error(error);
		})
		console.log(result)
		result.tipos = {};
		result?.TypeStore?.forEach(x=>{
			result.tipos[idsR[x]] = true;
		})
		result.servicios = {};
		result?.OperativeService?.forEach(x=>{
			result.servicios[idServiciosR[x]] = true;
		})
		setAlmacen({idCompany:0, direccion:{}, tipos:{}, servicios:{}, ...result} || {idCompany:0, direccion:{}, tipos:{}, servicios:{}});
		const auxPhotos = {};
		result.Photos.forEach(photo=>{
			if(!auxPhotos[photo.idCategoryPhoto])
				auxPhotos[photo.idCategoryPhoto] = [];
			auxPhotos[photo.idCategoryPhoto].push(photo);
		})
		setPhotos(auxPhotos);
		const result1 = await fetch(baseUrl+"Store/"+id+"/Posts/",{
			headers:{
				'Authorization': `Bearer ${token}`,
			}
		})
		.then(response=>response.json())
		.then(json=>{
			console.log(json);
			return json;
		})
		.catch(error=>{
			console.error(error);
		})
		setDatos(result1?.data || []);
	}

	return(
		<div className="almacenes publicaciones">
			<div className="divAgregar">
				<Link className="agregar btn black" to='nueva'>
					<img src={Agregar}/>
					<span>Agregar Publicación</span>
				</Link>
			</div>
			{datos.map(publicacion=>{
				return(<Publicacion publicacion={publicacion} almacen={almacen} key={publicacion.idPost}/>)
			})
			}
		</div>
	);
}

const Publicacion = ({ publicacion, almacen })=>{
	const [indice, setIndice] = useState(0);
	const [config, setConfig] = useState(localStorage.getItem('config') ? JSON.parse(localStorage.getItem('config')) : {
	  idioma : 'es',
	  sistema : 'internacional',
	})
	const wrapper = useRef();
    
    useEffect(()=>{
        if(wrapper.current){
            wrapper.current.scrollLeft = indice*wrapper.current.offsetWidth;
        }
    }, [indice])

	const cambiaIndice = (dir)=>{
        setIndice(indice+dir);
    }

	let desde = 0;
	const aux = []
	if(publicacion?.TypeTime?.includes("1")){
		aux.push(publicacion.day)
	}
	if(publicacion?.TypeTime?.includes("2")){
		aux.push(publicacion.week/7)
	}
	if(publicacion?.TypeTime?.includes("3")){
		aux.push(publicacion.month/30)
	}
	if(publicacion?.TypeTime?.includes("4")){
		aux.push(publicacion.year/365)
	}
	desde = Math.min(...aux);
	desde = Math.round(desde*100)/100;
	desde = isNaN(desde) || !isFinite(desde) ? 0 : desde;
	return(
		<Link to={'/host/publicaciones/'+almacen.idStore+'/'+publicacion.idPost} key={publicacion.id}>
			<div className="imgAlmacen">
				{almacen?.Photos?.length > 1 && indice != 0 &&
					<div className="mover izq" onClick={(e)=>{
						e.preventDefault();
						cambiaIndice(-1)
						}}>
						<img src={Flecha}/>
					</div>
				}
				{almacen?.Photos?.length > 1 && indice != almacen?.Photos?.length-1 &&
					<div className="mover der" onClick={(e)=>{
						e.preventDefault();
						cambiaIndice(1)
						}}>
						<img src={Flecha}/>
					</div>
				}
				<div className="wrapperImg" ref={wrapper}>
					{
						almacen?.Photos?.map((imagen, i)=>{
							return(
								<div key={i}>
								<img src={imagen.url}/>
							</div>
							)
						})
					}
				</div>
			</div>
			<div className="nombreAlmacen">{almacen?.Town+", "+ almacen?.State}</div>
			<div className="">{publicacion?.name}</div>
			<div className="tipo">
				<div className="img">
					{/* <img src={iconos[publicacion.tipo]}/> */}
				</div>
				{/* <span className="descripcion">{publicacion.tipo}</span> */}
			</div>
			<div className="descAlmacen">{publicacion?.assignedSpaces} espacio{publicacion?.assignedSpaces == 1 ? "" : "s"}</div>
			<div className="descAlmacen medidas">
				<span>Ancho: {config.sistema == 'ingles' ? formatoNumero(publicacion?.width*(3.2808399)/100)+'ft' : formatoNumero(publicacion?.width/100)+'m'}</span>
				<span>Largo: {config.sistema == 'ingles' ? formatoNumero(publicacion?.length*(3.2808399)/100)+'ft' : formatoNumero(publicacion?.length/100)+'m'}</span>
				<span>Alto: {config.sistema == 'ingles' ? formatoNumero(publicacion?.height*(3.2808399)/100)+'ft' : formatoNumero(publicacion?.height/100)+'m'}</span>
			</div>
			<div className="descAlmacen medidas">
				<span>Capacidad de carga: {config.sistema == 'ingles' ? formatoNumero(publicacion?.weight*(2.20462262)/100)+'lb' : formatoNumero(publicacion?.weight/100)+'kg'}</span>
			</div>
			<div><b>${desde} MXN</b> <i>espacio</i></div>
		</Link>
	)
}

export default Publicaciones;