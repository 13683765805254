import React, { useState, useRef, useEffect, useContext } from "react";
import { baseUrl } from '../../config.js'
import { Link, NavLink, Outlet, useLocation } from 'react-router-dom';

import './NavBarCuenta.css';

import useToken from "../../useToken.js";

import Flecha from '../../img/Flecha.png';
import defaultAlmacen from '../../img/defaultAlmacen.png';
import defaultPersona from '../../img/moral.png';
import Finanzas from '../../img/Finanzas.png';

const NavBar = ({})=>{

    const {token} = useToken();
    const location = useLocation();
    const [subMenu, setSubMenu] = useState({});
    
    useEffect(()=>{
        console.log(location.pathname.split('/'))
    }, [location])

    return(
        <div className="dashCuenta">
            <nav>
                <div>
                    <NavLink to='/host/gestion/personas' className={!location.pathname.split("/")[3] ? 'active' : ''}>
                        <div className="iconoCuenta">
                            <img src={defaultPersona}/>
                        </div>
                        <div>Personas</div>
                    </NavLink>
                    <NavLink to='/host/gestion/almacenes'>
                        <div className="iconoCuenta">
                            <img src={defaultAlmacen}/>
                        </div>
                        <div>Almacenes</div>
                    </NavLink>
                    <NavLink to='/host/gestion/publicaciones'>
                        <div className="iconoCuenta">
                            <img src={defaultAlmacen}/>
                        </div>
                        <div>Publicaciones</div>
                    </NavLink>
                </div>
            </nav>
            <Outlet/>
        </div>
    )
}

export default NavBar;