import React, { useEffect, useRef, useState } from "react";
import { baseUrl } from '../../config.js'
import { Link, useParams, useNavigate } from 'react-router-dom';
import useToken from '../../useToken';
import './Persona.css';

import Correcto from '../../img/Correcto.png';
import Incorrecto from '../../img/Incorrecto.png';
import Revisando from '../../img/Revisando.png';
import Descargar from '../../img/Descargar.png';
import ID from '../../img/ID.png';
import Domicilio from '../../img/ComprobanteDomicilio.png';
import EstadoCuenta from '../../img/EstadoCuenta.png';
import Constancia from '../../img/Constancia.png';
import Acta from '../../img/Acta.png';
import Checked from '../../img/Checked.png';
import Quitar from '../../img/Cerrar.png';
import { Imagenes } from "../../almacen.js";

const ImagenesCompany = {
	39: ID,
	36: Domicilio,
	41: EstadoCuenta,
	38: Constancia,
	37: Acta,
}

const Persona = ({user, id_persona})=>{

	const navigate = useNavigate();
	const {token} = useToken();
	const { id = id_persona } = useParams();
	const [idCompany, setIdCompany] = useState(id);
	const [persona, setPersona] = useState({addresses:[{},{}]});
	const [misma, setMisma] = useState({"1":false, "2":false});
	const [foto, setFoto] = useState({});
	const [tipo, setTipo] = useState('Física');
	const [paso, setPaso] = useState('Información');
	const [float, setFloat] = useState({});
	const [disabled, setDisabled] = useState(false);

	const nombre = useRef();
	const rfc = useRef();
	const clabe = useRef();

	useEffect(()=>{
		if(token)
            getData();
	},[id, token])

	useEffect(()=>{
		if(id){
			setDisabled(persona.estado == "Activo");
			setTipo(persona.idtypeCompany == 1 ? "Física" : "Moral")
		}
	},[persona])

	const getData = async ()=>{
		const result = await fetch(baseUrl+"Company/"+(id || ''), {
			headers:{
				'Authorization': `Bearer ${token}`,
			}
		})
		.then(response=>response.json())
		.then(json=>{
			console.log(json);
			return json;
		})
		.catch(error=>{
			console.error(error);
		})
		if(result.success){
			setPersona({...persona, ...result.data});
			setMisma({
				"1" : result.data.sameOffice == "1",
				"2" : result.data.sameStore == "1",
			})
			setFoto({url:result.data?.url})
		}
	}

	const validarRFC = ()=>{
		const dias = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
		const rfcTxt = rfc.current.value;
		if(tipo == "Física"){
			const regex = new RegExp(/^[A-Za-z]+$/)
			const mes = rfcTxt.substring(6,8)*1;
			const dia = rfcTxt.substring(8,10)*1;
			if(rfcTxt.length!=13 || 
				!regex.test(rfcTxt.substring(0,4)) || 
				isNaN(rfcTxt.substring(4,10)) || 
				mes > 12 || mes <= 0 || 
				dia > dias[mes-1] || dia <= 0
			){
				return false;
			}
		}
		else{
			const regex = new RegExp(/^[A-Za-z]+$/)
			const mes = rfcTxt.substring(5,7)*1;
			const dia = rfcTxt.substring(7,9)*1;
			if(rfcTxt.length!=12 ||
				!regex.test(rfcTxt.substring(0,3)) ||
				isNaN(rfcTxt.substring(3,9)) ||
				mes > 12 || mes <= 0 ||
				dia > dias[mes-1] || dia <= 0
			){
				return false;
			}
		}
		return true;
	}

	const guardar = async (e, boton)=>{
		if(boton)
			e.target.disabled = true;
		setFloat({estado:'guardando', mensaje:'Guardando...'})
		console.log(nombre.current.value.trim())
		if(nombre.current.value.trim() == ""){
			setFloat({estado:'error', mensaje:'Ingresa '+(tipo == "Física" ? "el nombre" : "la razón social")+'.'})
			setTimeout(()=>{
				if(boton)
					e.target.disabled = false;
				setFloat({})
			},5000)
			return false;
		}
		if(!validarRFC()){
			setFloat({estado:'error', mensaje:'El RFC ingresado no es válido para una persona '+tipo+'.'})
			setTimeout(()=>{
				if(boton)
					e.target.disabled = false;
				setFloat({})
			},5000)
			return false;
		}
		if(!persona.addresses[0]?.street?.trim() ||
			!persona.addresses[0]?.number?.trim()||
			!persona.addresses[0]?.zipcode?.trim()){
				setFloat({estado:'error', mensaje:'Por favor, ingresa un Domicilio fiscal válida.'})
			setTimeout(()=>{
				if(boton)
					e.target.disabled = false;
				setFloat({})
			},5000)
			return false;
		}
		const data = {
			"idtypeCompany": tipo == "Física" ? 1 : 2, //1 fisica, 2 moral
			"key": "C-TEST--T2YL-00",
			"name": nombre.current.value,
			"rfc": rfc.current.value,
			"clabe": clabe.current.value,
			"sameOffice" : misma["1"],
			"sameStore" : misma["2"],
		}
		const accion = idCompany ? "put" : "post";
		if(idCompany){
			data["idCompany"] = idCompany;
		}
		var results = [];
		const result = await fetch(baseUrl+"Company", {
			method: accion,
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
			body: JSON.stringify(data),
		})
		.then(response=>response.json())
		.then(json=>{
			console.log(json)
			return json;
		})
		.catch(error=>{
			console.error(error);
			setFloat({})
		})
		if(result.success){
			if(accion == "post")
				setIdCompany(result.id);
			const addresses = persona.addresses.map((direccion, i)=>{
				const indice = i == 0 ? i : misma[i] ? 0 : i;
				const dir = {
					"idCountry": "1",
					"street": persona.addresses[indice]?.street,
					"number": persona.addresses[indice]?.number,
					"zipCode": persona.addresses[indice]?.zipcode,
					"idTypeAddress": i+1,
					"idCompany": idCompany || result.id,
					"idColony": persona.addresses[indice]?.idColony,
				}
				if(direccion.idAddress){
					dir["idAddress"] = direccion.idAddress;
				}
				return dir;
			})
			results.push(fetch(baseUrl+"Address", {
				method: accion,
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					'Authorization': `Bearer ${token}`,
				},
				body: JSON.stringify({addresses}),
			})
			.then(response=>response.json())
			.then(json=>{
				console.log(json);
			})
			.catch(error=>{
				console.error(error);
				if(boton)
					e.target.disabled = false;
			}))
			console.log(foto);
			if(foto.file){
				const data = new FormData();
				data.append('idCompany', idCompany || result.id);
				data.append('file', foto.file);
				results.push(
					fetch(baseUrl+"Company/Photo", {
						method: "post",
						headers: {
							'Accept': 'application/json',
							'Authorization': `Bearer ${token}`,
						},
						body: data,
					})
					.then(response=>response.json())
					.then(json=>{
						// console.log(json);
						return json;
					})
					.catch(error=>{
						console.error(error);
					})
				)
			}
			else if(!foto.url && persona.url){
				results.push(
					fetch(baseUrl+"Company/Photo/"+(idCompany || result.id), {
						method: "delete",
						headers: {
							'Accept': 'application/json',
							'Authorization': `Bearer ${token}`,
						},
					})
					.then(response=>response.json())
					.then(json=>{
						// console.log(json);
						return json;
					})
					.catch(error=>{
						console.error(error);
					})
				)
			}
		}
		else{
			setFloat({estado:'error', mensaje:result.error})
			setTimeout(()=>{
				setFloat({})
			}, 5000)
			if(boton)
				e.target.disabled = false;
			return false;
		}
		console.log(results);
		return Promise.all(results)
		.then(()=>{
			setPaso("Documentos")
			setFloat({estado:'listo', mensaje:'¡Listo! Se han guardado los cambios con éxito'})
			if(boton && !id){
				e.target.disabled = false;
				navigate('/host/personas/'+result.id, {replace: true})
			}
			else{
				if(boton)
					e.target.disabled = false;
			}
			setTimeout(() => {
				setFloat({});
			}, 5000);
			return true;
		})
	}
	return(
		<div className="persona">
			{float?.estado &&
				<div className={"float"+(" "+float.estado)}>
					<div className="mensaje">{float.mensaje}</div>
				</div>
			}
			<div className="opciones">
				<button className="btn" onClick={(e)=>{
					guardar(e, true)
				}}>Guardar</button>
			</div>
			<form>
				<div className="pasos">
					<button type="button" className={paso == 'Información' ? 'selected' : ''} onClick={()=>{
						setPaso('Información')
					}}>Información</button>
					<button type="button" className={paso == 'Documentos' ? 'selected' : ''} onClick={()=>{
						if(id){
							setPaso('Documentos')
						}
						else{
							guardar();
						}
					}}>Documentos</button>
				</div>
				<div>
					<label className="select">
						<span>Tipo de Persona</span>
						<select value={tipo} onChange={(e)=>{
							setTipo(e.target.value);
						}}>
							<option value={'Física'}>Física</option>
							<option value={'Moral'}>Moral</option>
						</select>
					</label>
					<label>
						<input type="text" placeholder="" ref={nombre} defaultValue={persona?.name} disabled={disabled}/>
						<span>{tipo == "Física" ? "Nombre" : "Razón Social"}</span>
					</label>
					<label>
						<input type="text" placeholder="" minLength={tipo == "Física" ? 13 : 12} maxLength={tipo == "Física" ? 14 : 13} ref={rfc} defaultValue={persona?.RFC} disabled={disabled}/>
						<span>RFC</span>
					</label>
					<label>
						<input type="text" placeholder="" minLength={18} ref={clabe} defaultValue={persona?.clabe} disabled={disabled}/>
						<span>CLABE</span>
					</label>
				</div>
				{paso == "Información" &&
				<>
					<h3>{tipo == "Física" ? "Foto" : "Logotipo"}</h3>
					<Foto foto={foto} setFoto={setFoto}/>
					<h3>Domicilio Fiscal</h3>
					<Direccion persona={persona} i={0}/>
					<h3>Domicilio Oficina <i>(Opcional)</i></h3>
					<label className="misma switch">
						<div>Es la misma que el domicilio fiscal</div>
						<input type="checkbox" checked={misma["1"]} onChange={(e)=>{
							console.log({...misma, "1": e.target.checked});
							setMisma({...misma, "1": e.target.checked})
						}}
						/>
						<span>
							<span className="bola">
								<img src={Checked}/>
							</span>
						</span>
					</label>
					{!misma["1"] &&
						<Direccion persona={persona} i={1}/>
					}
				</>
				}
				{paso == "Documentos" &&
					<Documentos tipo={tipo} persona={persona} idCompany={idCompany} guardar={guardar}/>
				}
			</form>
		</div>
	);
}

export const Documentos = ({ tipo, persona, idCompany, guardar })=>{
	console.log(tipo, persona)
	return(
		<div className="documentos">
			<div><h2>Documentos</h2></div>
			<div className="docs">
				{persona?.documentsData?.map((documento, i)=>{
					documento.imagen = ImagenesCompany[documento.idDocument];
					if(documento.idDocument == 37 && tipo == 'Física'){
						return false;
					}
					return(
						<Documento doc={documento} key={i} idCompany={idCompany} guardar={guardar} persona={persona}/>
					)
					})
				}
			</div>
		</div>
	)
}

const Direccion = ({persona, i})=>{
	const [colonias, setColonias] = useState([]);
	const [info, setInfo] = useState({State: persona.addresses[i].State, Town: persona.addresses[i].Town});
	const calle = useRef();
	const numero = useRef();
	const cp = useRef();
	const state = useRef();
	const town = useRef();
	const colony = useRef();

	useEffect(()=>{
		setInfo({State: persona.addresses[i].State, Town: persona.addresses[i].Town})
		if(persona.addresses[i]?.idColony && persona.addresses[i]?.zipcode){
			getColonias(persona.addresses[i].zipcode)
		}
	},[persona])

	function getDireccion(e){
		const cp = e.target.value;
		getColonias(cp, true);
	}
	
	function getColonias(cp, cambio){
		fetch(baseUrl+'Zipcode/'+cp)
		.then(response=>response.json())
		.then(json=>{
			console.log(json);
			if(cambio){
				persona.addresses[i].idColony = json.data[0].id;
				console.log(persona)
				setInfo({State: json.address.State, Town: json.address.Town})
			}
			setColonias(json.data ||[]);
		})
		.catch(error=>{
			console.log(error);
		})
	}

	return (<div>
		<label>
			<input type="text" placeholder="" onChange={(e)=>{
				persona.addresses[i].street = e.target.value;
			}} ref={calle} defaultValue={persona?.addresses[i]?.street}/>
			<span>Calle</span>
		</label>
		<label className="mitad">
			<input type="text" placeholder="" onChange={(e)=>{
				persona.addresses[i].number = e.target.value;
			}} ref={numero} defaultValue={persona?.addresses[i]?.number}/>
			<span>Número</span>
		</label>
		<label className="mitad">
			<input type="number" placeholder="" max={99999} onChange={(e)=>{
				if(e.target.value.length == 5){
					getDireccion(e);
				}
				persona.addresses[i].zipcode = e.target.value;
			}} ref={cp} defaultValue={persona?.addresses[i]?.zipcode}/>
			<span>Código Postal</span>
		</label>
		<label>
			<input type="text" placeholder="" ref={state} value={info?.State} disabled={true}/>
			<span>Estado</span>
		</label>
		<label>
			<input type="text" placeholder="" ref={town} value={info?.Town} disabled={true}/>
			<span>Municipio</span>
		</label>
		{colonias[0] && 
			<label className="select">
				<span>Colonia</span>
				<select defaultValue={persona.addresses[i]?.idColony} onChange={(e)=>{
					persona.addresses[i].idColony = e.target.value;
				}}>
					{colonias?.map(colonia=>{
						return(
							<option key={colonia.id} value={colonia.id}>{colonia.name}</option>
						)
					})
					}
				</select>
			</label>
		}
	</div>)
}

const Documento = ({doc, idCompany, guardar, persona})=>{
	const {token} = useToken();
    const [error, setError] = useState({});
    const [documento, setDocumento] = useState(doc);
	const input = useRef();

    const lbls = {
		3 : "Rechazado",
		4 : "Aprobado",
		6 : "En Revisión",
	}
	const clases = {
		3 : "Incorrecto",
		4 : "Correcto",
		6 : "Revisando",
	}
    const imgs = {
		3 : Incorrecto,
        4 : Correcto,
        6 : Revisando,
    }

    const selecciona = ()=>{
        input.current.click();
    }
    const cambiaEstado = ()=>{
		if(input.current.files[0].size/(1024*1024) > 25){
			setError({error:true, descripcion: "El documento no debe ser mayor a 25MB"});
			input.current.value = "";
		}
        if(input.current.files[0]){
        	setError({});
        }
		procesar();
    }
	const procesar = async ()=>{
		if(!idCompany){
			const guarda = await guardar();
			console.log(guarda)
			if(!guarda)
				return false;
		}
		const data = new FormData();
		data.append('file', input.current.files[0]);
		data.append('idCompany', idCompany);
		data.append('idDocument', doc.idDocument);
		console.log(data);
		fetch(baseUrl+"Document/UploadC", {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Authorization': `Bearer ${token}`,
			},
			body: data
		}).then((response) => {/*console.log(response.text());*/return response.json()})
		.then((json) => {
			console.log(json);
			if(json?.success){
				documento.idStatusDocument = 6;
				doc.idStatusDocument = 6;
				setDocumento({...documento})
			}
		})
		.catch((error) => {
			console.log(error);
		});
	}
	function dragstart(e) {
        e.preventDefault();
        e.stopPropagation();
    }
    function drag(e) {
        e.preventDefault();
        e.stopPropagation();
    }
    function dragover(e) {
        e.currentTarget.classList.add("hov");
        e.preventDefault();
        e.stopPropagation();
    }
    function dragenter(e) {
        e.currentTarget.classList.add("hov");
        e.preventDefault();
        e.stopPropagation();
    }
    function dragend(e) {
        e.currentTarget.classList.remove("hov");
        e.preventDefault();
        e.stopPropagation();
    }
    function dragleave(e) {
        e.currentTarget.classList.remove("hov");
        e.preventDefault();
        e.stopPropagation();
    }
    function drop(e) {
        e.currentTarget.classList.remove("hov");
        e.preventDefault();
        e.stopPropagation();
        input.current.files = e.dataTransfer.files;
		const event = new Event('change', { bubbles: true });
		event.simulated = true;
		input.current.dispatchEvent(event);
    }
    return(
        <div onClick={selecciona}
			className={!documento?.idStatusDocument ? "subir" : clases[documento?.idStatusDocument] || 'subir'}
			onDragStart = {!documento.idStatusDocument || documento.idStatusDocument == 3 ? dragstart : null}
			onDrag = {!documento.idStatusDocument || documento.idStatusDocument == 3 ? drag : null}
			onDragEnd = {!documento.idStatusDocument || documento.idStatusDocument == 3 ? dragend : null}
			onDragOver = {!documento.idStatusDocument || documento.idStatusDocument == 3 ? dragover : null}
			onDragEnter = {!documento.idStatusDocument || documento.idStatusDocument == 3 ? dragenter : null}
			onDragLeave = {!documento.idStatusDocument || documento.idStatusDocument == 3 ? dragleave : null}
			onDrop = {!documento.idStatusDocument || documento.idStatusDocument == 3 ? drop : null}
			>
			{doc?.imagen &&
				<div className="imgDoc">
					<img src={doc.imagen}/>
				</div>
			}
			<div>
				<h3>{doc?.name}</h3>
				{error.error &&
					<div className="error">{error.descripcion}</div>
				}
				<div className="arrastra">
					{(!documento?.url) && (!documento?.idStatusDocument || documento?.idStatusDocument == 3) &&
						<div className='lblSel'>Selecciona o arrastra el archivo</div>
					}
					<div className="opcDocumento">
						{documento?.url && documento?.idStatusDocument != 3 &&
							<>
								<button type="button" onClick={(e)=>{
									e.preventDefault();
									e.stopPropagation();
									window.open(documento?.url, "_blank");
								}}>
									<img src={Descargar}/>
								</button>
							</>
						}
					</div>
					{documento?.note &&
						<div>
							<div><b>Motivos del rechazo:</b></div>
							<pre>
								{documento?.note}
							</pre>
						</div>
					}
					<input type='file' ref={input} accept='application/pdf' onChange={cambiaEstado}/>
				</div>
				<div className="descEstado">{lbls[documento?.idStatusDocument]}</div>
				<div className={'estado'}>
					{documento?.idStatusDocument &&
						<>
							<div className='infoEstado'>{lbls[documento?.idStatusDocument]}</div>
							<img src={imgs[documento?.idStatusDocument]}/>
						</>
					}
				</div>
			</div>
        </div>
    )
}

const Foto = ({foto, setFoto})=>{
	const {token} = useToken();
    const [error, setError] = useState({});
	const input = useRef();

	const selecciona = ()=>{
        input.current.click();
    }
    const cambiaEstado = (e)=>{
		if(input.current.files[0].size/(1024*1024) > 25){
			setError({error:true, descripcion: "El documento no debe ser mayor a 25MB"});
			input.current.value = "";
			return false;
		}
        if(input.current.files[0]){
			setError({});
        }
		const aux = [];
		const reader = new FileReader();
		reader.readAsDataURL(e.target.files[0]);
		reader.onload = () => {
			setFoto({url: String(reader.result), file: e.target.files[0]});
			console.log({url: String(reader.result), file: e.target.files[0]});
		};
    }

	function dragstart(e) {
        e.preventDefault();
        e.stopPropagation();
    }
    function drag(e) {
        e.preventDefault();
        e.stopPropagation();
    }
    function dragover(e) {
        e.currentTarget.classList.add("hov");
        e.preventDefault();
        e.stopPropagation();
    }
    function dragenter(e) {
        e.currentTarget.classList.add("hov");
        e.preventDefault();
        e.stopPropagation();
    }
    function dragend(e) {
        e.currentTarget.classList.remove("hov");
        e.preventDefault();
        e.stopPropagation();
    }
    function dragleave(e) {
        e.currentTarget.classList.remove("hov");
        e.preventDefault();
        e.stopPropagation();
    }
    function drop(e) {
        e.currentTarget.classList.remove("hov");
        e.preventDefault();
        e.stopPropagation();
        input.current.files = e.dataTransfer.files;
		const event = new Event('change', { bubbles: true });
		event.simulated = true;
		input.current.dispatchEvent(event);
    }
	function eliminar(){
		setFoto({});
	}
	console.log(foto);
    return(
		<>
			{!foto.url &&
				<div onClick={selecciona} className={'subir fotos perfil'}
					onDragStart = {dragstart}
					onDrag = {drag}
					onDragEnd = {dragend}
					onDragOver = {dragover}
					onDragEnter = {dragenter}
					onDragLeave = {dragleave}
					onDrop = {drop}
					>
					{error.error &&
						<div className="error">{error.descripcion}</div>
					}
					<div className="arrastra">
						<div className='lblSel'>Selecciona o arrastra el archivo</div>
						<input type='file' ref={input} accept='image/*' onChange={cambiaEstado} multiple={true}/>
					</div>
				</div>
			}
			{foto?.url &&
				<div className="ver perfil" onClick={selecciona}
					onDragStart = {dragstart}
					onDrag = {drag}
					onDragEnd = {dragend}
					onDragOver = {dragover}
					onDragEnter = {dragenter}
					onDragLeave = {dragleave}
					onDrop = {drop}
					>
					<div className="cambiarFoto">
						<div>Cambiar Foto</div>
					</div>
					<img src={foto?.url}/>
					<button className="eliminarFoto" onClick={(e)=>{
						e.preventDefault();
						e.stopPropagation();
						eliminar()
					}}>
						<img src={Quitar}/>
					</button>
					<input type='file' ref={input} accept='image/*' onChange={cambiaEstado} multiple={true}/>
				</div>
			}
		</>
    )
}

export default Persona;