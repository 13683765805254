import React from "react";
import { baseUrl } from '../../config.js'

import Cerrar from '../../img/Cerrar.png'
import './Modal.css';

const Modal = ({children, className, titulo, setModal})=>{
    const cierraModal = (e)=>{
        if(e.target === e.currentTarget){
            setModal(false);
        }
    }
    return(
        <div className={"modal" + (className ? " "+className : "")} onClick={cierraModal}>
            <div>
                <div className="opcModal">
                    <button type="button" onClick={()=>{
                        setModal(false);
                    }}>
                        <img src={Cerrar}/>
                    </button>
                    {titulo &&
                        <h3>{titulo}</h3>
                    }
                </div>
                {children}
            </div>
        </div>
    )
}

export default Modal;