import React, { useState, useRef, useEffect} from "react";
import { baseUrl } from '../../config.js'
import {Amplify} from 'aws-amplify';
import { resetPassword, confirmResetPassword, signInWithRedirect, updateMFAPreference, fetchUserAttributes, updateUserAttributes, confirmUserAttribute, updatePassword, signUp, signIn, confirmSignUp, confirmSignIn, autoSignIn, resendSignUpCode } from 'aws-amplify/auth';

import './Login.css';

import Google from '../../img/Google.png';
import Facebook from '../../img/Facebook.png';
import Apple from '../../img/Apple.png';
import Correo from '../../img/Correo.png';
import Telefono from '../../img/Telefono.png';
import Aprobar from "../../img/Aprobar.png";
import Cargando from "../../img/Cargando.gif";
import Ver from '../../img/ver.png';
import Ocultar from '../../img/ocultar.png';

import {config} from '../../aws-exports.js';
Amplify.configure(config);  

const Login = ({ setModalLogin, user, setUser, pagina })=>{
    const [paso, setPaso] = useState(0);
    const [step, setStep] = useState('');
    const [info, setInfo] = useState({});
    const [conEmail, setConEmail] = useState(true);
    const [conPassword, setConPassword] = useState(false);
    const [username, setUsername] = useState('');
    const [mostrar, setMostrar] = useState(false);
    const [cargando, setCargando] = useState(false);
    const [error, setError] = useState();
    const [reenviar, setReenviar] = useState(null);
    const [time, setTime] = useState(null);
    const [tiempo, setTiempo] = useState(0);
    const [medio, setMedio] = useState('');
    const [pass, setPass] = useState('');

    const codigoRef = useRef(null);
    const telefonoRef = useRef(null);
    const nombreRef = useRef(null);
    const apellidoRef = useRef(null);
    const correoRef = useRef(null);
    const fechaRef = useRef(null);
    const contraseñaRef = useRef(null);
    const confirmaRef = useRef(null);
    const terminosRef = useRef(null);
    const privacidadRef = useRef(null);

    const longitudMinima = new RegExp('.{8,}');
    const contieneNumero = new RegExp('\\d');
    const contieneMinuscula = new RegExp('[a-z]');
    const contieneMayuscula = new RegExp('[A-Z]');
    const contieneCaracterEspecial = new RegExp('[^a-zA-Z\\d\\s:]');
    const contador = 60;

    useEffect(()=>{
        console.log(user);
        if(Object.keys(user) != 0){
            if(!user?.old){
                const data = {
                    "name": user?.given_name,
                    "lastname": user?.family_name,
                    "password": contraseñaRef.current.value,
                    "cellphone": conEmail ? telefonoRef.current.value : username,
                    "uuid": user?.sub,
                    "email": user?.email,
                    "birthdate": user?.birthdate,
                }
                
                fetch(baseUrl+"api/alta/Usuario", {
                    method: "post",
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    body: JSON.stringify(data),
                })
                .then(response=>response.json())
                .then(json=>{
                    console.log(json);
                })
                .catch(error=>{
                    console.error(error);
                })
            }
            setModalLogin(false);
        }
    }, [user])
    
    useEffect(()=>{
        if(paso == 1){
            const aux = new Date();
            if(time){
                if(tiempo > 0){
                    setTimeout(() => {
                        setTiempo(Math.ceil(contador-1 - (aux.getTime() - time.getTime())/1000));
                    }, 1000);
                }
                else{
                    setReenviar(true);
                    setTime(null);
                }
            }
            else{
                setTime(aux);
                setTiempo(contador);
            }
        }
    }, [paso, tiempo])
    
    async function handleSignUp({ user="", password="", email="", phone_number="" }) {
        if(cargando){
            return false;
        }
        setCargando(true);
        if(conEmail){
            email = correoRef.current.value;
            user = email;
            password = email+"aA1.";
        }
        else{
            phone_number = "+52"+telefonoRef.current.value;
            user = phone_number;
            password = phone_number+"aA1.";
        }
        if(conPassword){
            password = contraseñaRef.current.value;
        }
        setUsername(user);
        try {
            const result = await signUp({
                username:user, 
                password,
                options: {
                userAttributes: {
                    email,
                    phone_number // E.164 number convention
                },
                // optional
                autoSignIn: {enabled:true} // or SignInOptions e.g { authFlowType: "USER_SRP_AUTH" }
                }
            });
            console.log(result);
            setPaso(1);
            setStep("CONFIRM_SIGN_UP");
        } catch (error) {
            console.log('error signing up:', error);
            try{
                const result = await signIn({username:user, password});
                console.log(result);
                setStep(result.nextStep.signInStep);
                if(!result.isSignedIn){
                    if(result.nextStep.signInStep == "CONFIRM_SIGN_UP"){
                        const result = await resendSignUpCode({username:user});
                        console.log(result);
                        setError("");
                        setPaso(1);
                    }
                    else if(result.nextStep.signInStep == "CONFIRM_SIGN_IN_WITH_SMS_CODE"){
                        setError("");
                        setPaso(1);
                        setMedio({medio : result.nextStep.codeDeliveryDetails.deliveryMedium, destino : result.nextStep.codeDeliveryDetails.destination})
                    }
                }
                else{
                    const result2 = await fetchUserAttributes();
                    if(!result2?.given_name){
                        setPaso(2);
                    }
                    else{
                        result2.old = true;
                        setUser(result2);
                    }
                }
                setCargando(false);
            }
            catch(error){
                console.log(error.name)
                setConPassword(true);
                setCargando(false);
            }
        }
    }
    async function resendCode(){
        const result = await resendSignUpCode({username});
        console.log(result);
    }
    async function handleSignUpConfirmation() {
        const confirmationCode = codigoRef.current.value;
        if(codigoRef.current.value == ''){
            setError("Ingresa el código enviado.")
            return false;
        }
        if(cargando){
            return false;
        }
        setCargando(true);
        console.log("confirmando");
        const result = await confirmSignUp({
            username,
            confirmationCode
        });
        const result1 = await autoSignIn();
        const result2 = await fetchUserAttributes();
        if(result1.phone_number){
            const result3 = await updateMFAPreference({ sms: 'PREFERRED' });
        }
        setCargando(false);
        setPaso(2);
    }
    async function handleSignInConfirmation() {
        if(cargando){
            return false;
        }
        setCargando(true);
        const challengeResponse = codigoRef.current.value;
        try {
            const result = await confirmSignIn({
                challengeResponse
            });
            const result1 = await fetchUserAttributes();
            if(!result1.email || !result1.phone_number){
                setPaso(2);
                setInfo({phone_number:result1.phone_number, email:result1.email})
            }
            else{
                const result2 = await fetchUserAttributes();
                result2.old = true;
                setUser(result2);
            }
            setCargando(false);
        } catch (error) {

            if(error.name == "EmptyChallengeResponse"){
                setError("Debes ingresar el código enviado.")
            }
            else if(error.name == "CodeMismatchException"){
                setError("El código es incorrecto o ha expirado.")
            }
            else if(error.name == "ExpiredCodeException"){
                setError("El código expiró. Intentalo de nuevo.")
            }
            else if(error.name == "NotAuthorizedException"){
                setError("La sesión expiró. Intentalo de nuevo.")
                setPaso(0);
            }
            setCargando(false);
        }
    }

    async function completarSignUp(){
        if(cargando){
            return false;
        }
        if(!(
                nombreRef.current.reportValidity() &&
                correoRef.current.reportValidity() &&
                telefonoRef.current.reportValidity() &&
                contraseñaRef.current.reportValidity() &&
                confirmaRef.current.reportValidity() &&
                terminosRef.current.reportValidity() &&
                privacidadRef.current.reportValidity()
            )
        ){
            if(contraseñaRef.current.value != confirmaRef.current.value){
                confirmaRef.current.classList.add("invalido");
                confirmaRef.current.setCustomValidity("Las contraseñas deben coincidir");
                confirmaRef.current.reportValidity();
                setTimeout(()=>{
                    confirmaRef.current.classList.remove("invalido");
                    confirmaRef.current.setCustomValidity("");
                }, 3000)
                return false;
            }
            return false;
        }
        setCargando(true);
        try {
            const resultP = await updatePassword({
                oldPassword: username+"aA1.",
                newPassword: contraseñaRef.current.value,
            });

            if(resultP.nextStep.signInStep == "CONFIRM_SIGN_IN_WITH_SMS_CODE"){
                setStep("CONFIRM_SIGN_IN_WITH_SMS_CODE");
                setMedio({medio : result.nextStep.codeDeliveryDetails.deliveryMedium, destino : result.nextStep.codeDeliveryDetails.destination})
                setError("");
                setPaso(1);
            }

            const actualizar = {
                given_name: nombreRef.current.value,
                family_name: apellidoRef.current.value,
                birthdate: fechaRef.current.value,
            };
            const result = await updateUserAttributes({
                userAttributes: actualizar,
            });
            if(result.email && !result.email.isUpdated && result.email.nextStep.updateAttributeStep == "CONFIRM_ATTRIBUTE_WITH_CODE"){
                setStep("CONFIRM_ATTRIBUTE_WITH_CODE");
                setMedio({medio : result.nextStep.codeDeliveryDetails.deliveryMedium, destino : result.nextStep.codeDeliveryDetails.destination})
                setError("");
                setPaso(1);
            }
            else{
                const result2 = await fetchUserAttributes();
                setUser(result2);
            }
            setCargando(false);
        } catch (error) {
            console.log(error);
            setCargando(false);
        }
        setInfo({...info,
            nombre: nombreRef.current.value,
            correo: correoRef.current.value,
            telefono: telefonoRef.current.value,
            contraseña: contraseñaRef.current.value,
        })
    }
    async function handleConfirmUserAttribute() {
        const userAttributeKey = "email";
        const confirmationCode = codigoRef.current.value;
        try {
          const result = await confirmUserAttribute({ userAttributeKey, confirmationCode });
          
          const result2 = await fetchUserAttributes();
          result2.old = true;
          
          const result3 = await updateMFAPreference({ sms: 'PREFERRED' });

          setUser(result2);
          
        } catch (error) {
          console.log(error);
        }
    }
    async function handleSignInWithProvider(provider){
        try{
            localStorage.setItem('redirectUrl', window.location.href);
            const result = await signInWithRedirect(provider);
            console.log(result);
        }
        catch(error){
            console.log(error);
        }
    }

    async function handleResetPassword() {
        if(cargando)
            return false;
        setCargando(true);
        try {
            const result = await resetPassword({ username });
            setMedio({medio: result.nextStep.codeDeliveryDetails.deliveryMedium, destino: result.nextStep.codeDeliveryDetails.destination})
            setError("");
            setPaso(4);
            setCargando(false)
        } catch (error) {
            console.log(error);
            setCargando(false)
            if(error.name == "EmptyResetPasswordUsername"){
                setError("Debes ingresar un correo registrado.")
            }
        }
    }
    async function handleConfirmResetPassword(){
        if(cargando)
            return false;
        if(!(
            contraseñaRef.current.reportValidity() &&
            confirmaRef.current.reportValidity()
        )
        ){
            if(contraseñaRef.current.value != confirmaRef.current.value){
                confirmaRef.current.classList.add("invalido");
                confirmaRef.current.setCustomValidity("Las contraseñas deben coincidir");
                confirmaRef.current.reportValidity();
                setTimeout(()=>{
                    confirmaRef.current.classList.remove("invalido");
                    confirmaRef.current.setCustomValidity("");
                }, 3000)
                return false;
            }
            return false;
        }
        setCargando(true);
        const confirmationCode = codigoRef.current.value;
        const newPassword = contraseñaRef.current.value;
        
        try {
            const result = await confirmResetPassword({ username, confirmationCode, newPassword });
            setError("")
            setPaso(5);
            setCargando(false)
        } catch (error) {
            console.log(error);
            setCargando(false)
            if(error.name == "EmptyConfirmResetPasswordConfirmationCode"){
                setError("Debes ingresar el código enviado.")
            }
            else if(error.name == "CodeMismatchException"){
                setError("El código es incorrecto o ha expirado.")
            }
            else if(error.name == "ExpiredCodeException"){
                setError("El código expiró. Intentalo de nuevo.")
            }
            else if(error.name == "NotAuthorizedException"){
                setError("La sesión expiró. Intentalo de nuevo.")
                setPaso(0);
            }
        }
    }

    const validaInput = (e)=>{
        e.target.className = e.target.checkValidity() ? 'valido' : 'invalido';
    }

    return(
        <div className={'registro'+(pagina ? " solo" : "")}>
            <h2>¡Bienvenido a PalletCloud!</h2>
            <div>
                {paso == 0 &&
                    <form onSubmit={(e)=>{
                        e.preventDefault();
                        handleSignUp(e);
                    }}>
                        <div className='conEmail'>
                            {!conEmail &&
                                <>
                                    <label>
                                        <input type='tel' ref={telefonoRef} placeholder='' required onChange={validaInput}/>
                                        <span>Teléfono</span>
                                    </label>
                                </>
                            }
                            {conEmail &&
                                <>
                                    <label>
                                        <input type='email' ref={correoRef} placeholder='' required onChange={validaInput}/>
                                        <span>Correo</span>
                                    </label>
                                </>
                            }
                            {conPassword &&
                                <>
                                    <label>
                                        <input type='password' ref={contraseñaRef} placeholder='' required onChange={validaInput}/>
                                        <span>Contraseña</span>
                                    </label>
                                </>
                            }
                            <div>
                                <button className={'btn'+(cargando ? " cargando" : "")} onClick={handleSignUp}>
                                    {cargando &&
                                        <>
                                            <div className="imgCargando">
                                                <img src={Cargando}/>
                                            </div>
                                            <div>Iniciando...</div>
                                        </>
                                    }
                                    {!cargando &&
                                        <div>Continuar</div>
                                    }
                                </button>
                            </div>
                        </div>
                        {error &&
                            <div className="error">{error}</div>
                        }
                        <hr/>
                        <h2>O ingresa con</h2>
                        <div className='sociales'>
                            <button type='button' onClick={()=>{
                                handleSignInWithProvider({provider: "Google"})}
                                }>
                                <img src={Google}/>
                                Google
                            </button>
                            <button type='button' onClick={()=>{
                                handleSignInWithProvider({provider: "Facebook"})}
                                }>
                                <img src={Facebook}/>
                                Facebook
                            </button>
                            {false &&
                                <button type='button' onClick={()=>{
                                    setConEmail(!conEmail);
                                }}>
                                    {conEmail &&
                                    <>
                                        <img src={Telefono}/>
                                        Teléfono
                                    </>
                                    }
                                    {!conEmail &&
                                    <>
                                        <img src={Correo}/>
                                        Correo
                                    </>
                                    }
                                </button>
                            }
                        </div>
                        <div className="forgot" onClick={()=>{
                            setPaso(3)}
                            }>
                            Olvidé mi contraseña
                        </div>
                    </form>
                }
                {paso == 1 &&
                    <form onSubmit={(e)=>{
                        e.preventDefault();
                        if(step == "CONFIRM_SIGN_UP")
                            handleSignUpConfirmation()
                        else if(step == "CONFIRM_ATTRIBUTE_WITH_CODE")
                            handleConfirmUserAttribute()
                        else
                            handleSignInConfirmation()
                    }}>
                        <div className='confirmaDatos'>
                            {medio?.medio &&
                                <div>
                                    Ingresa el código enviado por {medio?.medio} a {medio?.destino}.
                                </div>
                            }
                            <label>
                                <input type='number' ref={codigoRef} placeholder='' onChange={validaInput} minLength={6} required/>
                                <span>Código</span>
                            </label>
                            {error &&
                                <div className="error">{error}</div>
                            }
                            <div>
                                <button type='button' className={'btn'+(cargando ? " cargando" : "")} onClick={step == "CONFIRM_SIGN_UP" ? handleSignUpConfirmation : step == "CONFIRM_ATTRIBUTE_WITH_CODE" ? handleConfirmUserAttribute : handleSignInConfirmation}>
                                    {cargando &&
                                        <>
                                            <div className="imgCargando">
                                                <img src={Cargando}/>
                                            </div>
                                            <div>Iniciando...</div>
                                        </>
                                    }
                                    {!cargando &&
                                        <div>Confirmar</div>
                                    }
                                </button>
                            </div>
                            <div>
                                <button className={"reenviar"+(!reenviar ? " disabled" : "")} onClick={reenviar ? resendCode : null}>Reenviar Código</button>
                                {!reenviar &&
                                    <div className="espera">
                                        <div><b>¿No has recibido el Código?</b></div>
                                        <div>Espera <b>{tiempo} segundos</b> para volver a enviar un código.</div>
                                    </div>
                                }
                            </div>
                        </div>
                    </form>
                }
                {paso == 2 &&
                    <form onSubmit={(e)=>{
                        e.preventDefault();
                        completarSignUp(e);
                    }}>
                        <div className='confirmaDatos'>
                            <label>
                                <input type='tel' ref={telefonoRef} placeholder='' defaultValue={conEmail ? info?.telefono : username}  onChange={validaInput} minLength={1} required disabled = {!conEmail}/>
                                <span>Teléfono</span>
                            </label>
                            <label>
                                <input type='email' ref={correoRef} placeholder='' defaultValue={conEmail ? username : info?.telefono}  onChange={validaInput} required disabled={conEmail}/>
                                <span>Correo</span>
                            </label>
                            <label>
                                <input type='text' ref={nombreRef} placeholder='' defaultValue={info?.nombre}  onChange={validaInput} minLength={1} required/>
                                <span>Nombre(s)</span>
                            </label>
                            <label>
                                <input type='text' ref={apellidoRef} placeholder='' defaultValue={info?.apellido}  onChange={validaInput} minLength={1} required/>
                                <span>Apellido(s)</span>
                            </label>
                            <label>
                                <input type='date' ref={fechaRef} placeholder='' defaultValue={info?.birthdate}  onChange={validaInput} required/>
                                <span>Fecha de Nacimiento</span>
                            </label>
                            <label className="lblPass">
                                <input type={mostrar ? 'text' : 'password'} 
                                    ref={contraseñaRef}
                                    placeholder='' 
                                    minLength={1} 
                                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s:]).{8,}$" 
                                    onChange={(e)=>{
                                        setPass(e.target.value)
                                        validaInput(e)
                                    }}
                                    required/>
                                <span>Contraseña</span>
                                <div className="reqPass">
                                    <div><b>La contraseña debe incluir</b></div>
                                    <div>
                                        <span className="check">
                                            {longitudMinima.test(pass) &&
                                                <img src={Aprobar}/>
                                            }
                                        </span>
                                        <span>Al menos <b>8 caracteres</b></span>
                                    </div>
                                    <div>
                                        <span className="check">
                                            {contieneNumero.test(pass) &&
                                                <img src={Aprobar}/>
                                            }
                                        </span>
                                        <span>Al menos <b>1 número</b> </span>
                                    </div>
                                    <div>
                                        <span className="check">
                                            {contieneMinuscula.test(pass) &&
                                                <img src={Aprobar}/>
                                            }
                                        </span>
                                        <span>Al menos una <b>letra minúscula</b></span>
                                    </div>
                                    <div>
                                        <span className="check">
                                            {contieneMayuscula.test(pass) &&
                                                <img src={Aprobar}/>
                                            }
                                        </span>
                                        <span>Al menos una <b>letra mayúscula</b></span>
                                    </div>
                                    <div>
                                        <span className="check">
                                            {contieneCaracterEspecial.test(pass) &&
                                                <img src={Aprobar}/>
                                            }
                                        </span>
                                        <span>Al menos <b>1 carácter especial</b> del siguiente conjunto.</span>
                                    </div>
                                    <div>{`^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ \` + =`}</div>
                                </div>
                                <button className="mostrarPass" tabIndex={-1} type="button" onClick={(e)=>{
                                    setMostrar(!mostrar);
                                }}>
                                    <img src={mostrar ? Ocultar : Ver}/>
                                </button>
                            </label>
                            <label>
                                <input type='password' ref={confirmaRef} placeholder='' minLength={1} required/>
                                <span>Confirmar Contraseña</span>
                            </label>
                            <div className='tyc'>
                                <label>
                                    <input type='checkbox' ref={terminosRef} defaultChecked={false} required/>
                                    <span>He leído y acepto los <a>Términos y Condiciones</a>.</span>
                                </label>
                                <label>
                                    <input type='checkbox' ref={privacidadRef} defaultChecked={false} required/>
                                    <span>He leído y acepto la <a>Política de Privacidad</a>.</span>
                                </label>
                            </div>
                            <div>
                                <button className={'btn'+(cargando ? " cargando" : "")} onClick={completarSignUp}>
                                    {cargando &&
                                        <>
                                            <div className="imgCargando">
                                                <img src={Cargando}/>
                                            </div>
                                            <div>Iniciando...</div>
                                        </>
                                    }
                                    {!cargando &&
                                        <div>Continuar</div>
                                    }
                                </button>
                            </div>
                        </div>
                    </form>
                }
                {paso == 3 &&
                    <form onSubmit={(e)=>{
                        e.preventDefault();
                        handleResetPassword(e);
                    }}>
                        <div className='conEmail'>
                            <h3>Reestablecer contraseña</h3>
                            <label>
                                <input type='text' onChange={(e)=>{setUsername(e.target.value)}} placeholder='' required/>
                                <span>Correo o Teléfono</span>
                            </label>
                            {error &&
                                <div className="error">{error}</div>
                            }
                            <div>
                                <button className={'btn'+(cargando ? " cargando" : "")} onClick={handleResetPassword}>
                                    {cargando &&
                                        <>
                                            <div className="imgCargando">
                                                <img src={Cargando}/>
                                            </div>
                                            <div>Validando...</div>
                                        </>
                                    }
                                    {!cargando &&
                                        <div>Continuar</div>
                                    }
                                </button>
                            </div>
                        </div>
                    </form>
                }
                {paso == 4 &&
                    <form onSubmit={(e)=>{
                        e.preventDefault();
                        handleConfirmResetPassword(e);
                    }}>
                        <div className='confirmaDatos'>
                            <h3>Reestablecer contraseña</h3>
                            {medio?.medio &&
                                <div>
                                    Ingresa el código enviado por {medio?.medio} a {medio?.destino}.
                                </div>
                            }
                            <label className="lblPass">
                                <input type={mostrar ? 'text' : 'password'} 
                                    ref={contraseñaRef}
                                    placeholder='' 
                                    minLength={1} 
                                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s:]).{8,}$" 
                                    onChange={(e)=>{
                                        setPass(e.target.value)
                                        validaInput(e)
                                    }}
                                    required/>
                                <span>Contraseña</span>
                                <div className="reqPass">
                                    <div><b>La contraseña debe incluir</b></div>
                                    <div>
                                        <span className="check">
                                            {longitudMinima.test(pass) &&
                                                <img src={Aprobar}/>
                                            }
                                        </span>
                                        <span>Al menos <b>8 caracteres</b></span>
                                    </div>
                                    <div>
                                        <span className="check">
                                            {contieneNumero.test(pass) &&
                                                <img src={Aprobar}/>
                                            }
                                        </span>
                                        <span>Al menos <b>1 número</b> </span>
                                    </div>
                                    <div>
                                        <span className="check">
                                            {contieneMinuscula.test(pass) &&
                                                <img src={Aprobar}/>
                                            }
                                        </span>
                                        <span>Al menos una <b>letra minúscula</b></span>
                                    </div>
                                    <div>
                                        <span className="check">
                                            {contieneMayuscula.test(pass) &&
                                                <img src={Aprobar}/>
                                            }
                                        </span>
                                        <span>Al menos una <b>letra mayúscula</b></span>
                                    </div>
                                    <div>
                                        <span className="check">
                                            {contieneCaracterEspecial.test(pass) &&
                                                <img src={Aprobar}/>
                                            }
                                        </span>
                                        <span>Al menos <b>1 carácter especial</b> del siguiente conjunto.</span>
                                    </div>
                                    <div>{`^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ \` + =`}</div>
                                </div>
                                <button className="mostrarPass" tabIndex={-1} type="button" onClick={(e)=>{
                                    setMostrar(!mostrar);
                                }}>
                                    <img src={mostrar ? Ocultar : Ver}/>
                                </button>
                            </label>
                            <label>
                                <input type='password' ref={confirmaRef} placeholder='' minLength={1} required/>
                                <span>Confirmar Contraseña</span>
                            </label>
                            <label>
                                <input type='number' ref={codigoRef} placeholder='' onChange={validaInput} minLength={6} required/>
                                <span>Código</span>
                            </label>
                            {error &&
                                <div className="error">{error}</div>
                            }
                            <div>
                                <button type='button' className='btn' onClick={handleConfirmResetPassword}>Confirmar</button>
                            </div>
                        </div>
                    </form>
                }
                {paso == 5 &&
                    <div>
                        <h2>La contraseña fue cambiada.</h2>
                        <div>
                            <button type='button' className='btn' onClick={()=>{
                                setPaso(0);
                            }}>Aceptar</button>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default Login;