import React, { useEffect, useRef, useState } from "react";
import { baseUrl } from '../../config.js'
import { Link, useNavigate, useParams } from 'react-router-dom'
import useToken from '../../useToken';

import './Host.css';

import Agregar from '../../img/Agregar.png';
import defaultAlmacen from '../../img/defaultAlmacen.png';

const Host = ({selectAlmacen})=>{
	
	const {token} = useToken();
	const {idCompany} = useParams();
	const navigate = useNavigate();
	const [persona, setPersona] = useState({});
	const [almacenes, setAlamcenes] = useState([]);
	const [cantidades, setCantidades] = useState({});
	const [filtros, setFiltros] = useState({});

	useEffect(()=>{
		if(token)
            getData();
	}, [token])

	const getData = async ()=>{
		console.log(idCompany)
		if(idCompany){
			const result = await fetch(baseUrl+"Company/"+idCompany,{
				headers:{
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					'Authorization': `Bearer ${token}`,
				}
			})
			.then(response=>response.json())
			.then(json=>{
				console.log(json);
				return json;
			})
			.catch(error=>{
				console.error(error);
			})
			setPersona(result.data || []);
		}

		const result1 = await fetch(baseUrl+(idCompany ? 'Company/'+idCompany+'/Stores/' : 'Stores/User/'),{
			headers:{
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			}
		})
		.then(response=>response.json())
		.then(json=>{
			console.log(json);
			return json;
		})
		.catch(error=>{
			console.error(error);
		})
		if(result1?.success){
			setAlamcenes(result1.data || []);
		}
	}

	useEffect(()=>{
		const aux = {
			activos : 0,
			proceso: 0,
		}
		almacenes?.forEach(almacen=>{
			if(almacen.approved)
				aux["activos"]++;
			else
				aux["proceso"]++;
		})

		setCantidades(aux)
	}, [almacenes])

	if(selectAlmacen)
		return(
	
			<div className="personas">
				<h2>Selecciona el Almacén</h2>
				<div className="hostAlmacenes">
					{almacenes.map(almacen=>{
						return(
							<Link to={'/host/gestion/publicaciones/'+almacen.idStore+'/nueva'} key={almacen.idStore}>
								<div className="imgAlmacen">
									{almacen?.Photos.length!=0 && almacen?.Photos[0] &&
										<img src={almacen?.Photos[0].url}/>
									}
									{almacen?.Photos.length === 0 &&
										<img src={defaultAlmacen}/>
									}
								</div>
								<div className="nombreAlmacen">{almacen.name}</div>
							</Link>
						)
					})
					}
				</div>
			</div>
		)
	else
		return(
			<div className="personas">
				<div className="hostAlmacenes">
					{idCompany && persona.name &&
						<div className="pertenece">
							<h2>Almacenes de <b>{persona.name}</b></h2>
						</div>
					}
					<div className="tipos pagos">
						<button
							className={filtros?.status == undefined ? 'sel' : ''}
							onClick={()=>{
								setFiltros({...filtros, status: undefined});
							}}
						>
							<span>Todos</span>
							<h2>{almacenes?.length || 0}</h2>
						</button>
						<button
							className={filtros?.status == 'activos' ? 'sel' : ''}
							onClick={()=>{
								setFiltros({...filtros, status: 'activos'});
							}}
							>
							<span>Activos</span>
							<h2>{cantidades?.activos || 0}</h2>
						</button>
						<button
							className={filtros?.status == 'proceso' ? 'sel' : ''}
							onClick={()=>{
								setFiltros({...filtros, status: 'proceso'});
							}}
							>
							<span>En Proceso</span>
							<h2>{cantidades?.proceso || 0}</h2>
						</button>
					</div>
					<div className="divAgregar">
						<Link className="agregar btn black" to={idCompany ? 'nuevo' : '/host/gestion/almacenes/nuevo'}>
							<img src={Agregar}/>
							<span>Agregar Almacén</span>
						</Link>
					</div>
					{almacenes.map(almacen=>{
						if(!filtros.status || (filtros.status == 'activos' && almacen.approved) || (filtros.status == 'proceso' && !almacen.approved))
						return(
							<Link to={'/host/gestion/almacenes/'+almacen.idStore} key={almacen.idStore}>
								<div className="imgAlmacen">
									{almacen?.Photos.length!=0 && almacen?.Photos[0] &&
										<img src={almacen?.Photos[0].url}/>
									}
									{almacen?.Photos.length === 0 &&
										<img src={defaultAlmacen}/>
									}
								</div>
								<div className="nombreAlmacen">{almacen.name}</div>
							</Link>
						)
					})
					}
				</div>
			</div>
		);
}

export default Host;