import React, { useState, useRef, useEffect, useContext } from "react";
import { baseUrl } from '../../config.js'
import './Reservados.css';

import useToken from "../../useToken.js";
import { formatoFecha, formatoFechaCorta, formatoMoneda, formatoHora, Meses, estatus, imgEstatus } from '../../almacen.js';

import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";

import Cerrar from '../../img/Cerrar.png';
import FlechaGuia from '../../img/Guia.png';

const Conds = {
    "0": "Exactamente",
    "1": "Entre",
    "2": "Más de",
    "3": "Menos de",
}
const CondsFecha = {
    "0": "",
    "1": "",
    "2": "A partir del",
    "3": "Antes del",
}

const DashEspacios = ({solicitudes})=>{

    const location = useLocation();

    return(
        <div className="dashEspacios">
            <div className="movimientos">
                <div className="etiqueta">
                    <div>
                        <h3>Mis Reservaciones</h3>
                        <Movimientos solicitudes={false}/>
                    </div>
                </div>
            </div>

        </div>
    )
}

const Movimientos = ({solicitudes})=>{

    const { token } = useToken();
    const navigate = useNavigate();
    const [tiempo, setTiempo] = useState('Month');
    const [data, setData] = useState([]);
    const [totales, setTotales] = useState({});
    const [filtros, setFiltros] = useState({status:'Total', requests: solicitudes});
    const [showFilter, setShowFilter] = useState('');
    const [fecha, setFecha] = useState(new Date())
    const [cargando, setCargando] = useState(false)
    const modFiltrar = useRef();

    useEffect(()=>{
        if(token){
            getData();
        }
    },[token, filtros])

    const handleClickOutside = (event) => {
        if (modFiltrar.current && !modFiltrar.current.contains(event.target)) {
            setShowFilter('');
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const getData = ()=>{
        setCargando(true);
        fetch(baseUrl+'MyBookings',{
            method: 'post',
            headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
            body: JSON.stringify(filtros)
        })
        .then(response=>response.json())
		.then(json=>{
            setData(json?.data || [])
            setTotales(json?.total || {});
            setCargando(false);
		})
		.catch(error=>{
			console.error(error);
            setCargando(false);
		})
    }

    const generaIntervalo = (json) => {
        if(json.intervalo > 1){
            return "En los últimos " + json.intervalo + " " + json.tiempo;
        }
        const singular = {
            "Días" : "Día",
            "Meses" : "Mes",
            "Años" : "Año",
        }
        return "En el último " + singular[json.tiempo];

    }

    return(
        <>
            
            {!solicitudes &&
            <>
                <div className="tipos pagos">
                    <button
                        className={filtros?.status == 'Total' ? 'sel' : ''}
                        onClick={()=>{
                            setFiltros({...filtros, status: 'Total'});
                        }}
                    >
                        <span>Todas</span>
                        <h2>{totales?.Total || 0}</h2>
                    </button>
                    <button
                        className={filtros?.status == 'Ongoing' ? 'sel' : ''}
                        onClick={()=>{
                            setFiltros({...filtros, status: 'Ongoing'});
                        }}
                    >
                        <span>En curso</span>
                        <h2>{totales?.Ongoing || 0}</h2>
                    </button>
                    <button
                        className={filtros?.status == 'Upcoming' ? 'sel' : ''}
                        onClick={()=>{
                            setFiltros({...filtros, status: 'Upcoming'});
                        }}
                    >
                        <span>Próximas</span>
                        <h2>{totales?.Upcoming || 0}</h2>
                    </button>
                    <button
                        className={filtros?.status == 'Ended' ? 'sel' : ''}
                        onClick={()=>{
                            setFiltros({...filtros, status: 'Ended'});
                        }}
                    >
                        <span>Terminadas</span>
                        <h2>{totales?.Ended || 0}</h2>
                    </button>
                    <button
                        className={filtros?.status == 'Waiting' ? 'sel' : ''}
                        onClick={()=>{
                            setFiltros({...filtros, status: 'Waiting'});
                        }}
                    >
                        <span>Esperando Aprobación</span>
                        <h2>{totales?.Waiting || 0}</h2>
                    </button>
                    <button
                        className={filtros?.status == 'Approved' ? 'sel' : ''}
                        onClick={()=>{
                            setFiltros({...filtros, status: 'Approved'});
                        }}
                    >
                        <span>Aprobadas para Pago</span>
                        <h2>{totales?.Approved || 0}</h2>
                    </button>
                    <button
                        className={filtros?.status == 'Canceled' ? 'sel' : ''}
                        onClick={()=>{
                            setFiltros({...filtros, status: 'Canceled'});
                        }}
                    >
                        <span>Canceladas</span>
                        <h2>{totales?.Canceled || 0}</h2>
                    </button>
                </div>
            </>
            }
            <div className="filtrosPago">
                <span>
                    <button onClick={()=>{
                        setShowFilter('Importe')
                    }}>
                        {filtros?.price?.cond !== undefined &&
                            <button className="borrar" onClick={(e)=>{
                                e.preventDefault();
                                e.stopPropagation();
                                setFiltros({...filtros, price:undefined})
                            }}>
                                <img src={Cerrar}/>
                            </button>
                        }
                        <span>Importe</span>
                        {filtros?.price?.cond !== undefined && filtros.price.cond != "1" &&
                            <span className="lblFiltro">{Conds[filtros.price.cond]+" $"+formatoMoneda(filtros.price.values[0]*1)}</span>
                        }
                        {filtros?.price?.cond !== undefined && filtros.price.cond == "1" &&
                            <span className="lblFiltro">{"$"+formatoMoneda(filtros.price.values[0]*1)+" a $"+formatoMoneda(filtros.price.values[1]*1)}</span>
                        }
                    </button>
                    {showFilter == 'Importe' &&
                        <FiltrarImporte setShowFilter={setShowFilter} filtros={filtros} setFiltros={setFiltros} modFiltrar={modFiltrar}/>
                    }
                </span>
                <span>
                    <button onClick={()=>{
                        setShowFilter('Estatus')
                    }}>
                        {filtros?.statuses &&
                            <button className="borrar" onClick={(e)=>{
                                e.preventDefault();
                                e.stopPropagation();
                                setFiltros({...filtros, statuses:undefined})
                            }}>
                                <img src={Cerrar}/>
                            </button>
                        }
                        <span>Estatus</span>
                        {filtros?.statuses &&
                            <span className="lblFiltro">
                                {filtros?.statuses?.map(x=>{
                                    return estatus[x];
                                }).join(", ")}
                            </span>
                        }
                    </button>
                    {showFilter == 'Estatus' &&
                        <FiltrarEstatus setShowFilter={setShowFilter} filtros={filtros} setFiltros={setFiltros} modFiltrar={modFiltrar}/>
                    }
                </span>
                <span>
                    <button onClick={()=>{
                        setShowFilter('FechaI')
                    }}>
                        {filtros?.startDates?.cond !== undefined &&
                            <button className="borrar" onClick={(e)=>{
                                e.preventDefault();
                                e.stopPropagation();
                                setFiltros({...filtros, startDates:undefined})
                            }}>
                                <img src={Cerrar}/>
                            </button>
                        }
                        <span>Fecha Inicio</span>
                        {filtros?.startDates?.cond !== undefined && filtros.startDates.cond != "1" && filtros.startDates.cond < 4 &&
                            <span className="lblFiltro">{CondsFecha[filtros.startDates.cond]+" "+formatoFechaCorta(new Date(filtros.startDates.values[0].split("-")))}</span>
                        }
                        {filtros?.startDates?.cond !== undefined && filtros.startDates.cond == "1" &&
                            <span className="lblFiltro">{formatoFechaCorta(new Date(filtros.startDates.values[0].split("-")))+" al "+formatoFechaCorta(new Date(filtros.startDates.values[1].split("-")))}</span>
                        }
                        {filtros?.startDates?.cond !== undefined && filtros.startDates.cond == "4" &&
                            <span className="lblFiltro">{generaIntervalo(filtros.startDates)}</span>
                        }
                        {filtros?.startDates?.cond !== undefined && filtros.startDates.cond == "5" &&
                            <span className="lblFiltro">{generaIntervalo(filtros.startDates)}</span>
                        }
                    </button>
                    {showFilter == 'FechaI' &&
                        <FiltrarFecha setShowFilter={setShowFilter} showFilter={showFilter} filtrosDates={filtros?.startDates} setFiltros={setFiltros} modFiltrar={modFiltrar}/>
                    }
                </span>
                <span>
                    <button onClick={()=>{
                        setShowFilter('FechaF')
                    }}>
                        {filtros?.endDates?.cond !== undefined &&
                            <button className="borrar" onClick={(e)=>{
                                e.preventDefault();
                                e.stopPropagation();
                                setFiltros({...filtros, endDates:undefined})
                            }}>
                                <img src={Cerrar}/>
                            </button>
                        }
                        <span>Fecha Final</span>
                        {filtros?.endDates?.cond !== undefined && filtros?.endDates?.cond != "1" && filtros?.startDates?.cond < 4 &&
                            <span className="lblFiltro">{CondsFecha[filtros.endDates.cond]+" "+formatoFechaCorta(new Date(filtros.endDates.values[0].split("-")))}</span>
                        }
                        {filtros?.endDates?.cond !== undefined && filtros.endDates.cond == "1" &&
                            <span className="lblFiltro">{formatoFechaCorta(new Date(filtros.endDates.values[0].split("-")))+" al "+formatoFechaCorta(new Date(filtros.endDates.values[1].split("-")))}</span>
                        }
                        {filtros?.endDates?.cond !== undefined && filtros.endDates.cond == "4" &&
                            <span className="lblFiltro">{generaIntervalo(filtros.endDates)}</span>
                        }
                        {filtros?.endDates?.cond !== undefined && filtros.endDates.cond == "5" &&
                            <span className="lblFiltro">{generaIntervalo(filtros.endDates)}</span>
                        }
                    </button>
                    {showFilter == 'FechaF' &&
                        <FiltrarFecha setShowFilter={setShowFilter} showFilter={showFilter} filtrosDates={filtros?.endDates} setFiltros={setFiltros} modFiltrar={modFiltrar}/>
                    }
                </span>
            </div>
            <table className={"tabla pagos"+(cargando ? " cargandoTabla" : "")}>
            <tbody>
                {data?.length !== 0 &&
                    <tr className='header'>
                        <th></th>
                        <th>Importe (MXN)</th>
                        <th>Estatus</th>
                        <th>Almacén</th>
                        <th>Ubicación</th>
                        <th>Espacios</th>
                        <th>Inicio</th>
                        <th>Fin</th>
                        <th>Fecha</th>
                    </tr>
                }
                {data?.length === 0 &&
                    <tr>
                        <td>No hay movimientos recientes</td>
                    </tr>
                }
                {data?.map((fila, i)=>{
                    let auxFecha = formatoFechaCorta(new Date(fila?.mainDate?.split("T")[0].split("-")));
                    if(auxFecha == formatoFechaCorta(new Date())){
                        auxFecha = "Hoy";
                    }
                    const hora = formatoHora(new Date(fila.mainDate));
                    if(fila.transaction>0)
                    return(
                        <tr key={i} onClick={()=>{
                            navigate('/reservaciones/'+fila.idBooking);
                        }}>
                            <td>{fila?.url &&
                                <div className="imgInfo">
                                    <img src={fila.url}/>
                                </div>
                            }</td>
                            <td>${formatoMoneda(fila.price*1)}</td>
                            <td>
                                <span className={fila.status}>
                                    <span>{estatus[fila.status]}</span>
                                    <img src={imgEstatus[fila.status]}/>
                                </span>
                            </td>
                            <td>{fila.name}</td>
                            <td>
								<Link onClick={(e)=>{
									e.preventDefault();
									e.stopPropagation();
									window.open(`https://maps.google.com/?q=${fila.lat},${fila.lng}`, '_blank')
								}} to={`https://maps.google.com/?q=${fila.lat},${fila.lng}`}>
									{`${fila?.street} ${fila?.number}, ${fila?.Colony}, ${fila?.State}`}
								</Link>
                            </td>
                            <td className="mas">{fila.transaction}</td>
                            <td>{formatoFechaCorta(new Date(fila.startDate.split("T")[0].split("-")))}</td>
                            <td>{formatoFechaCorta(new Date(fila.endDate.split("T")[0].split("-")))}</td>
                            <td>
                                <span>{auxFecha} {hora}</span>
                            </td>
                        </tr>
                    )
                })
                }
            </tbody>
            </table>
        </>
    )
}

const FiltrarImporte = ({setShowFilter, showFilter, filtros, setFiltros, modFiltrar})=>{
    const [tipo, setTipo] = useState(filtros?.price?.cond || 0);
    const value1 = useRef();
    const value2 = useRef();

    useEffect(()=>{
        value1.current.value = filtros?.price?.values[0];
        if(value2?.current)
            value2.current.value = filtros?.price?.values[1];
    }, [filtros])

    const aplicarFiltros = ()=>{
        if(!(
            value1?.current?.reportValidity() &&
            (!value2?.current || value2?.current?.reportValidity())
        )){
            return false;
        }

        setFiltros(filtros=> {
            return {...filtros, price: {
                cond: tipo,
                values: [value1.current.value, value2?.current?.value]
            }}
        })

        setShowFilter(false);
    }
    return(
        <div className="modFiltrar" ref={modFiltrar}>
            <h3>Filtrar por Importe</h3>
            <div>
                <select value={tipo} onChange={(e)=>{
                    setTipo(e.target.value)
                }}>
                    <option value='0'>Igual a</option>
                    <option value='1'>Entre </option>
                    <option value='2'>Mayor que</option>
                    <option value='3'>Menor que</option>
                </select>
            </div>
            <div className="defineRango">
                <label className="rango">
                    <img src={FlechaGuia}/>
                    <input type="number" ref={value1} required/>
                </label>
                {tipo == '1' &&
                    <>
                        <span className="guion"> — </span>
                        <label className="rango">
                            <input type="number" ref={value2} required/>
                        </label>
                    </>
                }
            </div>
            <div>
                <button className="btn" onClick={aplicarFiltros}>Aplicar</button>
            </div>
        </div>
    )
}

const FiltrarEstatus = ({setShowFilter, filtros, setFiltros, modFiltrar})=>{
    
    const [selected, setSelected] = useState(filtros?.statuses || []);
    
    const aplicarFiltros = ()=>{
        if(selected.length === 0){
            setFiltros(filtros=> {
                return {...filtros, statuses: undefined}
            })
        }
        else{
            setFiltros(filtros=> {
                return {...filtros, statuses: selected}
            })
        }

        setShowFilter(false);
    }

    return(
        <div className="modFiltrar" ref={modFiltrar}>
            <h3>Filtrar por Estatus</h3>
            <div className="lista">
                {Object.keys(estatus).map(x=>{
                    console.log(selected, x);
                    return(
                        <label key={x}>
                            <input type="checkbox" checked={selected.includes(x)} onChange={(e)=>{
                                if(selected.includes(x)){
                                    selected.splice(selected.indexOf(x), 1)
                                    setSelected([...selected])
                                }
                                else{
                                    selected.push(x)
                                    setSelected([...selected])
                                }
                            }}/>
                            <span>{estatus[x]}</span>
                        </label>
                    )
                })
                }
            </div>
            <div>
                <button className="btn" onClick={aplicarFiltros}>Aplicar</button>
            </div>
        </div>
    )
}
const FiltrarFecha = ({setShowFilter, showFilter, filtrosDates, setFiltros, modFiltrar})=>{
    console.log(filtrosDates)
    const [tipo, setTipo] = useState(filtrosDates?.cond || 4);
    const value1 = useRef();
    const value2 = useRef();
    const select = useRef();

    useEffect(()=>{
        if(filtrosDates?.cond < 4){
            value1.current.value = filtrosDates?.values[0];
            if(value2?.current)
                value2.current.value = filtrosDates?.values[1];
        }
        else{
            value1.current.value = 10;
        }
    }, [filtrosDates])

    const aplicarFiltros = ()=>{
        if(!(
            value1?.current?.reportValidity() &&
            (!value2?.current || value2?.current?.reportValidity())
        )){
            return false;
        }
        if(tipo < 4){
            setFiltros(filtros=> {
                if(showFilter == "FechaI")
                    return {...filtros, startDates: {
                    cond: tipo,
                    values: [value1.current.value, value2?.current?.value]
                }}

                return {...filtros, endDates: {
                    cond: tipo,
                    values: [value1.current.value, value2?.current?.value]
                }}
            })
        }
        else{
            const aux = [];
            
            const hoy = new Date();
            const auxDate = new Date();
            const intervalo = value1.current.value*1 || 1;

            if(tipo == 4){
                if(select.current.value == 'Días'){
                    auxDate.setDate(auxDate.getDate() - intervalo);
                }
                else if(select.current.value == 'Meses'){
                    auxDate.setMonth(auxDate.getMonth() - intervalo);
                }
                else if(select.current.value == 'Meses'){
                    auxDate.setFullYear(auxDate.getFullYear() - intervalo);
                }
                aux.push(formatoFecha(auxDate))
                aux.push(formatoFecha(hoy))
            }
            else{
                if(select.current.value == 'Días'){
                    auxDate.setDate(auxDate.getDate() + intervalo);
                }
                else if(select.current.value == 'Meses'){
                    auxDate.setMonth(auxDate.getMonth() + intervalo);
                }
                else if(select.current.value == 'Años'){
                    auxDate.setFullYear(auxDate.getFullYear() + intervalo);
                }
                aux.push(formatoFecha(hoy))
                aux.push(formatoFecha(auxDate))
            }
            console.log(aux);
            setFiltros(filtros=> {
                if(showFilter == "FechaI")
                    return {...filtros, startDates: {
                    cond: tipo,
                    values: aux,
                    tiempo: select.current.value,
                    intervalo
                }}

                return {...filtros, endDates: {
                    cond: tipo,
                    values: aux,
                    tiempo: select.current.value,
                    intervalo
                }}
            })
        }

        setShowFilter(false);
    }
    return(
        <div className="modFiltrar" ref={modFiltrar}>
            <h3>Filtrar por Fecha</h3>
            <div>
                <select value={tipo} onChange={(e)=>{
                    setTipo(e.target.value)
                }}>
                    <option value='4'>En los últimos</option>
                    <option value='5'>En los próximos</option>
                    <option value='0'>Igual a</option>
                    <option value='1'>Entre </option>
                    <option value='2'>Despues de</option>
                    <option value='3'>Antes de</option>
                </select>
            </div>
            <div className="defineRango">
                {tipo <= 3 &&
                    <>
                        <label className="rango">
                            <img src={FlechaGuia}/>
                            <input type="date" ref={value1} required/>
                        </label>
                        {tipo == '1' &&
                            <>
                                <span className="guion"> — </span>
                                <label className="rango">
                                    <input type="date" ref={value2} required/>
                                </label>
                            </>
                        }
                    </>
                }
                {tipo > 3 &&
                    <label className="rango">
                        <img src={FlechaGuia}/>
                        <input type="number" ref={value1} min={1} required/>
                        <select ref={select}>
                            <option>Días</option>
                            <option>Meses</option>
                            <option>Años</option>
                        </select>
                    </label>
                }
            </div>
            <div>
                <button className="btn" onClick={aplicarFiltros}>Aplicar</button>
            </div>
        </div>
    )
}

export default DashEspacios;